import React, { useState, useEffect } from "react";

import Header from "../Common/Header";
import Footer from "../Common/Footer";
import DataService from "../services/data.service";
import "../CSS/AllVideo.css";
import { ToastContainer, toast } from "react-toastify";
import NewsLetter from "../Common/NewsLetter";
import video1 from "../Videos/VID-20220709-WA0002.mp4";
import video2 from "../Videos/VID-20220709-WA0046.mp4";
import video3 from "../Videos/VID-20220711-WA0002.mp4";
import video4 from "../Videos/VID-20220711-WA0003.mp4";
import video5 from "../Videos/VID-20220711-WA0004.mp4";
import video6 from "../Videos/VID-20220711-WA0006.mp4";
import video7 from "../Videos/VID-20220711-WA0007.mp4";
import video8 from "../Videos/VID-20220711-WA0008.mp4";
import video9 from "../Videos/VID-20220711-WA0012.mp4";
import video10 from "../Videos/VID-20220711-WA0020.mp4";
import video11 from "../Videos/VID-20220711-WA0024.mp4";
import video12 from "../Videos/VID-20220711-WA0025.mp4";
import video13 from "../Videos/VID-20220711-WA0029.mp4";
import video14 from "../Videos/VID-20220712-WA0030.mp4";
import video15 from "../Videos/VID-20220718-WA0000.mp4";
import video16 from "../Videos/VID-20220717-WA0012.mp4";
import video17 from "../Videos/VID-20220722-WA0043.mp4";
import video18 from "../Videos/VID-20220717-WA0113.mp4";
import video19 from "../Videos/VID-20220717-WA0130.mp4";
import video20 from "../Videos/VID-20220717-WA0131.mp4";

function AllVideo() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "All";
  }, []);

  return (
    <>
      <Header />
      <div className="video-top-section">
        <div className="container">
          <div className="video-main-sec-flex">
            <div className="video-single-sec">
              <video controls className="all-videos-w">
                <source src={video1} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="video-single-sec">
              <video controls className="all-videos-w">
                <source src={video2} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="video-single-sec">
              <video controls className="all-videos-w">
                <source src={video3} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="video-single-sec">
              <video controls className="all-videos-w">
                <source src={video4} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="video-single-sec">
              <video controls className="all-videos-w">
                <source src={video5} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="video-single-sec">
              <video controls className="all-videos-w">
                <source src={video6} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="video-single-sec">
              <video controls className="all-videos-w">
                <source src={video7} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="video-single-sec">
              <video controls className="all-videos-w">
                <source src={video8} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="video-single-sec">
              <video controls className="all-videos-w">
                <source src={video9} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="video-single-sec">
              <video controls className="all-videos-w">
                <source src={video10} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="video-single-sec">
              <video controls className="all-videos-w">
                <source src={video11} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="video-single-sec">
              <video controls className="all-videos-w">
                <source src={video12} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="video-single-sec">
              <video controls className="all-videos-w">
                <source src={video13} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="video-single-sec">
              <video controls className="all-videos-w">
                <source src={video14} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="video-single-sec">
              <video controls className="all-videos-w">
                <source src={video15} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="video-single-sec">
              <video controls className="all-videos-w">
                <source src={video16} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="video-single-sec">
              <video controls className="all-videos-w">
                <source src={video17} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="video-single-sec">
              <video controls className="all-videos-w">
                <source src={video18} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="video-single-sec">
              <video controls className="all-videos-w">
                <source src={video19} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className="video-single-sec">
              <video controls className="all-videos-w">
                <source src={video20} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </div>

      <NewsLetter />
      <Footer />
    </>
  );
}

export default AllVideo;
