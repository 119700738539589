import React from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import "../CSS/FanzatNews.css";
function FanzatNews() {
  return (
    <>
      <Header />
      <div className="fanzat-main-section">
        <div className="container">
          <div className="fanzat-sub-section">
            <div className="fanzat-sub-heading">
              <h2 className="fanzat-heading1">Fanzat News</h2>
            </div>
            <div className="fanzat-main-btn">
              <div className="fanzat-inner-btn">
                <a
                  href="https://fanzat-news-super-admin-panel.vercel.app/"
                  target="_blank"
                >
                  <button className="main-btn">Super Admin </button>
                </a>
              </div>
              <div className="fanzat-inner-btn">
                <a href="https://news-admin-panel.vercel.app/" target="_blank">
                  <button className="main-btn"> Admin </button>
                </a>
              </div>
              <div className="fanzat-inner-btn">
                <a
                  href="https://fanzat-news-editor-dashboard.vercel.app/"
                  target="_blank"
                >
                  <button className="main-btn">Editor </button>
                </a>
              </div>
            </div>
            <div className="fanzat-sub-heading">
              <h2 className="fanzat-heading2"> Fanzat Blog</h2>
            </div>
            <div className="fanzat-main-btn">
              <div className="fanzat-inner-btn">
                <button className="main-btn">Super Admin </button>
              </div>
              <div className="fanzat-inner-btn">
                <button className="main-btn"> Admin </button>
              </div>
              <div className="fanzat-inner-btn">
                <button className="main-btn">Editor </button>
              </div>
            </div>
            <div className="fanzat-sub-heading">
              <h2 className="fanzat-heading3">Fanzat Help</h2>
            </div>
            <div className="fanzat-main-btn">
              <div className="fanzat-inner-btn">
                <button className="main-btn">Super Admin </button>
              </div>
              <div className="fanzat-inner-btn">
                <button className="main-btn"> Admin </button>
              </div>
              <div className="fanzat-inner-btn">
                <button className="main-btn">Editor </button>
              </div>
            </div>
            <div className="fanzat-sub-heading">
              <h2 className="fanzat-heading4">Fanzat Ad Portal</h2>
            </div>
            <div className="fanzat-main-btn">
              <div className="fanzat-inner-btn">
                <button className="main-btn">Super Admin </button>
              </div>
              <div className="fanzat-inner-btn">
                <button className="main-btn"> Admin </button>
              </div>
              <div className="fanzat-inner-btn">
                <button className="main-btn">Editor </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default FanzatNews;
