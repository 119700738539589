import React, { useState, useEffect } from "react";
import "../CSS/PrivacyPolicy.css";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import { ToastContainer, toast } from "react-toastify";
import DataService from "../services/data.service";
import NewsLetter from "../Common/NewsLetter";
function PrivacyPolicy() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Privacy Policy";
    getData();
  }, []);
  const getData = async () => {
    await DataService.getPrivacyPolicy().then((data) => {
      setData(data?.data?.data[0]);
    });
  };

  return (
    <>
      <Header />
      <div className="banner-sec-Privacy">
        <div className="banner-P-tittle">
          <h1>{data?.title?.english}</h1>
        </div>
      </div>
      <div className="top-sec-privacy">
        <div className="container">
          <div className="main-content-privacy">
            <p
              className="post-content-para"
              dangerouslySetInnerHTML={{
                __html: data?.description?.english,
              }}
            ></p>
          </div>
        </div>
      </div>
      <NewsLetter />
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
