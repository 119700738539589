import React from "react";
import "../CSS/FooterAr.css";
import Logo from "../images/fanzat_logo.png";
import { useNavigate } from "react-router-dom";
// import ThreedLogo from "../images/fanzat_3dLogo.png";
import ThreedLogo from "../images/Logo1.png";

import { Link } from "react-router-dom";
// import { Link } from "react-router-dom";

const FooterAr = () => {
  const navigate = useNavigate();
  return (
    // <section className="footer_sec">
    //   <div className="container">
    //     <div className="footer_topBar1">
    //       <div className="footer_topBar1L1">
    //         <h4>Follow Us</h4>
    //       </div>
    //       <div className="footer_topBar1R1">
    //         <i class="fab fa-linkedin-in"></i>
    //         <i class="fab fa-twitter"></i>
    //         <i class="fab fa-instagram"></i>
    //         <i class="fab fa-facebook-f"></i>
    //       </div>
    //     </div>
    //     <hr />
    //     <div className="footer_bottomBar">
    //       <div className="footer_bottomL">
    //         <img
    //           src={Logo}
    //           alt=""
    //           style={{ cursor: "pointer" }}
    //           onClick={() => navigate("/")}
    //         />
    //       </div>
    //       <div className="footer_bottomM">
    //         <ul>
    //           <li>Blogar Store</li>
    //           <li>Advertise with Us</li>
    //           <li>AdChoices</li>
    //           <li>Privacy Policy</li>
    //           <li>Contact Us</li>
    //         </ul>
    //       </div>
    //       <div className="footer_bottomR">
    //         <p>All Rights Reserved © 2023</p>
    //       </div>
    //     </div>
    //   </div>
    // </section>
    <section className="main_footer1">
      <div className="container">
        <div className="footer_flex1">
          <div className="footer_logoSec1">
            <Link to={"/ar"}>
              <img src={ThreedLogo} alt="" />
            </Link>
            <div className="footer_social1">
              <i class="fab fa-facebook-f"></i>
              <i class="fab fa-twitter"></i>
              <i class="fab fa-instagram"></i>
              <i class="fab fa-youtube"></i>
              <i class="fab fa-snapchat"></i>
              <i class="fab fa-linkedin-in"></i>
              <i class="fab fa-pinterest"></i>
            </div>
            <p>كل الحقوق محفوظة © 2024</p>
          </div>
          <div className="footer_advertise1">
            <ul>
              {/* <Link to="/"> */}
                {/* <li>
                  <i class="fas fa-circle"></i>أعلن معنا
                </li> */}
              {/* </Link> */}
              <Link to={"/ar/privacy-policy"}>
                {" "}
                <li>
                  <i class="fas fa-circle"></i>سياسة الخصوصية
                </li>
              </Link>
              <Link to={"/ar/terms-of-use"}>
                {" "}
                <li>
                  <i class="fas fa-circle"></i>شروط الاستخدام
                </li>
              </Link>
              {/* <Link to={"/ar/contact-us"}> */}
              <a href="https://nnsm3.com/company/contact">
                <li>
                  <i class="fas fa-circle"></i>اتصل بنا
                </li>
              </a>

              {/* </Link> */}
            </ul>
          </div>
          <div className="footer_brandsOne1">
            <h4>مساعدة فانزات</h4>
            <h4>أخبار فانزات</h4>
            <h4>مدونة فانزات</h4>
          </div>
          <div className="footer_brandsTwo1">
            <h4>فاني - الذكاء الاصطناعي</h4>
            <h4>سوق فانزات</h4>
            <h4>فينيو فانزات</h4>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FooterAr;
