import React from 'react'
import Header from '../Common/Header'
import Footer from '../Common/Footer'
import error from '../images/20602754_6333074.jpg'

function ErrorPage() {
  return (
   <>
   <Header />
   <div className='container'>
   <div className='banner-image-404' >
    <img src={error} style={{width:"100%", height:"600px", objectFit:"cover"}}/>
   </div>
   </div>
   <Footer />
   </>
  )
}

export default ErrorPage
