import React, { useState, useEffect } from "react";

import DataService from "../services/data.service";
import "../CSS/PostAr.css";
import ikj from "../images/ikj.jpg";
import { ToastContainer, toast } from "react-toastify";
import HeaderAr from "../Common/HeaderAr";
import NewsLetterAr from "../Common/NewsLetterAr";
import FooterAr from "../Common/FooterAr";
import { Link } from "react-router-dom";

function PostsAr() {
  const [news, setNews] = useState("");
  const [fnews, setFNews] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [TotalPostsCount, setTotalPostsCount] = useState("");
  const postsPerPage = 20;
  const [masterCategory, setMasterCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [selectedButton, setSelectedButton] = useState(null);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getAllLNews = async () => {
      await DataService.getAllNewsAr().then((data) => {
        setNews(data?.data?.data?.posts);
        setTotalPostsCount(data?.data?.data?.totalPostsCount);
      });
    };
    getAllLNews();
    getAllGNews(currentPage);
    document.title = "All Posts";

    window.scrollTo(0, 0);
  }, []);

  const getAllGNews = async (newPage) => {
    setLoading(true);
    await DataService.getAllPNewsAr(newPage).then((data) => {
      setFNews(data?.data?.data?.posts);
      setLoading(false);
    });
  };

  const totalPages = Math.ceil(TotalPostsCount / postsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    getAllGNews(newPage);
  };

  const visiblePageButtons = 5;

  const renderPageButtons = () => {
    const pageButtons = [];
    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 ||
        (i >= currentPage - Math.floor(visiblePageButtons / 2) &&
          i <= currentPage + Math.floor(visiblePageButtons / 2))
      ) {
        pageButtons.push(
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={currentPage === i ? "active-btn" : ""}
          >
            {i}
          </button>
        );
      }
    }
    return pageButtons;
  };

  const getAllCategory = async () => {
    await DataService.getAllCategory(1).then((data) => {
      setMasterCategory(data?.data?.categories);
    });
    await DataService.getAllCategory(2).then((data) => {
      setSubCategory(data?.data?.categories);
    });
  };
  useEffect(() => {
    getAllCategory();
  }, []);

  // const handleButtonClick = (id) => {
  //   setSelectedButton(id);
  // };

  return (
    <>
      <HeaderAr />
      <div className="main-banner-post1">
        <div className="banner-post-content1">
          <h1>جميع الأخبار </h1>
        </div>
      </div>

      <div className="section3-post-page1">
        <div className="container">
          {loading && (
            <div className="loader-spin">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          )}
          <div className="related-post-bottom1">
            <h2>جميع الأخبار </h2>
            <div className="related-post-Bbox1">
              {fnews && fnews.length > 0 ? (
                fnews
                  // .filter((item) => item.status === "publish")
                  .filter(
                    (item) => item?.status === "publish"
                    // item?.featured_media?.source_url
                  )
                  .map((item, index) => {
                    const postDate = new Date(item?.date);
                    const formattedDate = `${postDate.toLocaleDateString()} ${postDate.toLocaleTimeString()}`;
                    return (
                      <>
                        <div className="related-post-Bcolumn1 related-post-Bcolumn131">
                          {/* <a href={"/single-blog-post/" + item._id}> */}
                          <Link to={"/ar/single-blog-post/" + item._id}>
                            <div className="Bcolumn-img">
                              {/* <img src={item?.featured_media?.source_url} /> */}
                              <img
                                src={
                                  "https://fanzat-news.onrender.com" +
                                  item?.featured_media?.source_url
                                }
                                className="product-img"
                                alt="image"
                              />
                            </div>
                            <div className="all-post-cat1egory1">
                              {item?.categories
                                .filter((category) => category.type === "1")
                                .map((category) => (
                                  <h6>{category.name?.arabic}</h6>
                                ))}
                            </div>
                            <div className="Bcolumn-content1">
                              <h4>
                                {item?.title?.arabic
                                  ?.split(" ")
                                  ?.slice(0, 6)
                                  .join(" ")}
                              </h4>
                              <p
                                className="para-arabic-comntent"
                                dangerouslySetInnerHTML={{
                                  __html: item?.content?.arabic
                                    ?.split(" ")
                                    .slice(0, 12)
                                    .join(" "),
                                }}
                              ></p>
                              {/* <div className="Bcolumn-btn">
                                <a href={"/single-blog-post/" + item._id}>
                                  <button>Read More</button>
                                </a>
                              </div> */}
                            </div>
                            {/* </a> */}
                          </Link>
                        </div>
                      </>
                    );
                  })
              ) : (
                <p>لا توجد أخبار حاليا </p>
              )}

              {/* <div className="main-btn1">
              {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index + 1}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </button>
              ))}
              </div> */}
            </div>

            <div className="show-more-posts-btn1">
              {/* {Array.from({ length: totalPages }, (_, index) => (
                <button
                  key={index + 1}
                  onClick={() => handlePageChange(index + 1)}
                  className={currentPage === index + 1 ? "active-btn" : ""}
                >
                  {index + 1}
                </button>
              ))} */}
              {currentPage > 1 && (
                <button onClick={() => handlePageChange(currentPage - 1)}>
                  {"< السابق "}
                </button>
              )}
              {renderPageButtons()}
              {currentPage < totalPages && (
                <button onClick={() => handlePageChange(currentPage + 1)}>
                  {"التالي  >"}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="post-section41 sub-category-bottom1">
        <div className="container">
          <h2>جميع الأقسام </h2>
          <div className="post-sec4-allcategory1">
            <div className="sec4-allCat-btn1">
              {subCategory && subCategory.length > 0 ? (
                subCategory.map((item, index) => {
                  return (
                    <>
                      {/* <a href={`/post-by-category/${item.id}`}> */}
                      <Link to={`/ar/post-by-category/${item.id}`}>
                        {/* <button
                          key={item.id}
                          onClick={() => handleButtonClick(item.id)}
                          style={{
                            backgroundColor:
                              selectedButton === item.id
                                ? "#00c3ce"
                                : "",
                          }}
                        > */}
                        <button>{item?.name?.arabic}</button>
                        {/* </a> */}
                      </Link>
                    </>
                  );
                })
              ) : (
                <p>لا توجد أقسام حاليا </p>
              )}
            </div>
          </div>
        </div>
      </div>
      <NewsLetterAr />
      <FooterAr />
    </>
  );
}

export default PostsAr;
