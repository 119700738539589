import React, { useState, useEffect } from "react";
import DataService from "../services/data.service";
import "../CSS/Posts.css";
import ikj from "../images/ikj.jpg";
import NewsLetterFr from "../Common/NewsLetterFr";
import FooterFr from "../Common/FooterFr";
import HeaderFr from "../Common/HeaderFr";

function PostsFr() {
  // const [news, setNews] = useState("");

  // const getAllNews = async () => {
  //   await DataService.getAllNews().then((data) => {
  //     setNews(data?.data?.data);
  //     // setNewsL1(data?.data?.data?.[0]);
  //   });
  // };
  // useEffect(() => {
  //   document.title = "All Post";
  //   window.scrollTo(0, 0);
  //   getAllNews();
  // }, []);
  const [news, setNews] = useState("");
  const [fnews, setFNews] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [TotalPostsCount, setTotalPostsCount] = useState("");
  const postsPerPage = 100; // Number of posts per page
  const [masterCategory, setMasterCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getAllLNews = async () => {
      setLoading(true);
      await DataService.getAllNewsFr().then((data) => {
        setNews(data?.data?.data?.posts);
        setTotalPostsCount(data?.data?.data?.totalPostsCount);
        setLoading(false);
      });
    };
    getAllLNews();
    getAllGNews(currentPage);
    window.scrollTo(0, 0);
    document.title = "Tous les articles";
  }, []);

  const getAllGNews = async (newPage) => {
    setLoading(true);
    await DataService.getAllPNewsFr(newPage).then((data) => {
      setFNews(data?.data?.data?.posts);
      setLoading(false);
    });
  };

  const totalPages = Math.ceil(TotalPostsCount / postsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    getAllGNews(newPage);
  };

  const visiblePageButtons = 5;

  const renderPageButtons = () => {
    const pageButtons = [];
    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 ||
        (i >= currentPage - Math.floor(visiblePageButtons / 2) &&
          i <= currentPage + Math.floor(visiblePageButtons / 2))
      ) {
        pageButtons.push(
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={currentPage === i ? "active-btn" : ""}
          >
            {i}
          </button>
        );
      }
    }
    return pageButtons;
  };
  const getAllCategory = async () => {
    await DataService.getAllCategory(1).then((data) => {
      // console.log(data?.data?.categories)
      setMasterCategory(data?.data?.categories);
    });
    await DataService.getAllCategory(2).then((data) => {
      // console.log(data?.data?.categories)
      setSubCategory(data?.data?.categories);
    });
  };
  useEffect(() => {
    getAllCategory();
  }, []);

  return (
    <>
     <HeaderFr/>
      <div className="main-banner-post">
        <div className="banner-post-content">
          <h1>Tous les articles</h1>
        </div>
      </div>
      {/* <div className="featured-post-box">
        <div className="container">
          <div className="featured-post-main-h2">
            <h2>More Featured Posts</h2>
          </div>
          <div className="featured-main-post-box">
            <div className="featured-main-sub-box">
              <div className="featured-subpost-column">
                <div className="left-featured-subpost">
                  <div className="LFS-content">
                    <h6>Lorem</h6>
                  </div>
                  <div className="LFS-heading">
                    <h4>Lorem Ipsum is simply dummy text of the printing</h4>
                  </div>
                  <div className="LFS-meta-post">
                    <div className="LFS-post-img">
                      <img src={ikj} />
                    </div>
                    <div className="LFS-meta-post-content">
                      <h6>Lorem</h6>
                      <div className="LFS-meta-post-date">
                        <p>Lorem Ipsum</p>
                      </div>
                      <div className="LFS-meta-post-date">
                        <p>Lorem Ipsum</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="right-featured-subpost">
                  <img src={ikj} />
                </div>
              </div>
            </div>
            <div className="featured-main-sub-box">
              <div className="featured-subpost-column">
                <div className="left-featured-subpost">
                  <div className="LFS-content">
                    <h6>Lorem</h6>
                  </div>
                  <div className="LFS-heading">
                    <h4>Lorem Ipsum is simply dummy text of the printing</h4>
                  </div>
                  <div className="LFS-meta-post">
                    <div className="LFS-post-img">
                      <img src={ikj} />
                    </div>
                    <div className="LFS-meta-post-content">
                      <h6>Lorem</h6>
                      <div className="LFS-meta-post-date">
                        <p>Lorem Ipsum</p>
                      </div>
                      <div className="LFS-meta-post-date">
                        <p>Lorem Ipsum</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="right-featured-subpost">
                  <img src={ikj} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className="section-2post">
        <div className="container">
          <div className="all-post-main">
            <div className="all-post-img">
              <img src={ikj}/>
            </div>
            <div className="all-post-content">
              <h3>Lorem Ipsum is simply</h3>
              <p>Lorem Ipsum is simply1651 51</p>
           
            <div className="all-post-para">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s,
            </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="section3-post-page">
        <div className="container">
          {loading && (
            <div className="loader-spin">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          )}
          <div className="related-post-bottom">
            <h2>Tous les articles</h2>
            <div className="related-post-Bbox">
              {fnews && fnews.length > 0 ? (
                fnews
                  .filter((item) => item.status === "publish")
                  .map((item, index) => {
                    const postDate = new Date(item.post_date);
                    const formattedDate = `${postDate.toLocaleDateString()} ${postDate.toLocaleTimeString()}`;
                    return (
                      <>
                        <div className="related-post-Bcolumn related-post-bcolumn3">
                          <a href={"/fr/single-blog-post/" + item._id}>
                            <div className="Bcolumn-img">
                              <img
                                src={
                                  "https://fanzat-news.onrender.com" +
                                  item?.featured_media?.source_url
                                }
                                className="product-img"
                                alt="image"
                              />
                            </div>
                            <div className="all-post-category1">
                              {item?.categories
                                .filter((category) => category.type === "1")
                                .map((category) => (
                                  <h6>{category.name?.french}</h6>
                                ))}
                            </div>
                            <div className="Bcolumn-content">
                              {/* <h4> {item?.post_title?.english}</h4> */}
                              <p>
                                {item?.title?.french
                                  .split(" ")
                                  .slice(0, 12)
                                  .join(" ")}
                              </p>
                              {/* <div className="Bcolumn-btn">
                              <a href={"/single-blog-post/" + item._id}>
                                {" "}
                                <button>Read More</button>{" "}
                              </a>
                            </div> */}
                            </div>
                          </a>
                        </div>
                      </>
                    );
                  })
              ) : (
                <p>Aucune actualité trouvée</p>
              )}
            </div>
            {/* <div className="view-more-blogs" style={{textAlign:'center', marginTop: '20px'}}>
                <button class="main-btn" disabled={dataB && dataB.length===count ? true : false} onClick={handleViewMore}>
                <button className="main-btn">Voir plus</button>
              </div> */}
            <div className="show-more-posts-btn">
              {currentPage > 1 && (
                <button onClick={() => handlePageChange(currentPage - 1)}>
                  {"< Précédent"}
                </button>
              )}
              {renderPageButtons()}
              {currentPage < totalPages && (
                <button onClick={() => handlePageChange(currentPage + 1)}>
                  {"Suivant >"}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="post-section4">
        <div className="container">
          <h2>Toutes les catégories</h2>
          <div className="post-sec4-allcategory">
            <div className="sec4-allCat-btn">
              {subCategory && subCategory.length > 0 ? (
                subCategory.map((item, index) => {
                  if (item?.name?.french !== "") {
                    return (
                      <>
                        <a href={`/fr/post-by-category/${item.id}`}>
                          <button> {item?.name?.french}</button>
                        </a>
                      </>
                    );
                  } else {
                    return null;
                  }
                })
              ) : (
                <p>Not Category Found</p>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* <div className="related-post-Bcolumn related-post-bcolumn3">
                <div className="Bcolumn-img">
                  <img src="https://news.fanzat.com/wp-content/uploads/2019/12/NNSM3.jpg" />
                </div>
                <div className="all-post-category1">
                  <h6>Lorem</h6>
                </div>
                <div className="Bcolumn-content">
                  <h4>Lorem ipsum</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor
                  </p>
                  <div className="Bcolumn-btn">
                    <button>Read More</button>
                  </div>
                </div>
              </div>
              <div className="related-post-Bcolumn related-post-bcolumn3">
                <div className="Bcolumn-img">
                  <img src="https://news.fanzat.com/wp-content/uploads/2019/12/NNSM3.jpg" />
                </div>
                <div className="all-post-category1">
                  <h6>Lorem</h6>
                </div>
                <div className="Bcolumn-content">
                  <h4>Lorem ipsum</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor
                  </p>
                  <div className="Bcolumn-btn">
                    <button>Read More</button>
                  </div>
                </div>
              </div>
              <div className="related-post-Bcolumn related-post-bcolumn3">
                <div className="Bcolumn-img">
                  <img src="https://news.fanzat.com/wp-content/uploads/2019/12/NNSM3.jpg" />
                </div>
                <div className="all-post-category1">
                  <h6>Lorem</h6>
                </div>
                <div className="Bcolumn-content">
                  <h4>Lorem ipsum</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor
                  </p>
                  <div className="Bcolumn-btn">
                    <button>Read More</button>
                  </div>
                </div>
              </div>
              <div className="related-post-Bcolumn related-post-bcolumn3">
                <div className="Bcolumn-img">
                  <img src="https://news.fanzat.com/wp-content/uploads/2019/12/NNSM3.jpg" />
                </div>
                <div className="all-post-category1">
                  <h6>Lorem</h6>
                </div>
                <div className="Bcolumn-content">
                  <h4>Lorem ipsum</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor
                  </p>
                  <div className="Bcolumn-btn">
                    <button>Read More</button>
                  </div>
                </div>
              </div>
              <div className="related-post-Bcolumn related-post-bcolumn3">
                <div className="Bcolumn-img">
                  <img src="https://news.fanzat.com/wp-content/uploads/2019/12/NNSM3.jpg" />
                </div>
                <div className="all-post-category1">
                  <h6>Lorem</h6>
                </div>
                <div className="Bcolumn-content">
                  <h4>Lorem ipsum</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor
                  </p>
                  <div className="Bcolumn-btn">
                    <button>Read More</button>
                  </div>
                </div>
              </div>
              <div className="related-post-Bcolumn related-post-bcolumn3">
                <div className="Bcolumn-img">
                  <img src="https://news.fanzat.com/wp-content/uploads/2019/12/NNSM3.jpg" />
                </div>
                <div className="all-post-category1">
                  <h6>Lorem</h6>
                </div>
                <div className="Bcolumn-content">
                  <h4>Lorem ipsum</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor
                  </p>
                  <div className="Bcolumn-btn">
                    <button>Read More</button>
                  </div>
                </div>
              </div> */}
     <NewsLetterFr/>
     <FooterFr/>
    </>
  );
}

export default PostsFr;
