import React, { useState, useEffect } from "react";
import "../CSS/AboutUs.css";

import { ToastContainer, toast } from "react-toastify";
import DataService from "../services/data.service";
import FooterFr from "../Common/FooterFr";
import NewsLetterFr from "../Common/NewsLetterFr";
import HeaderFr from "../Common/HeaderFr";

function AboutUsFr() {
  const [data, setData] = useState("");

  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "About Us";
    getData();
  }, []);
  const getData = async () => {
    await DataService.getAboutUsFr().then((data) => {
      setData(data?.data?.data[0]);
    });
  };

  return (
    <>
      <HeaderFr />
      <div className="sec-banner-about">
        <div className="main-tittle-banner">
          <h1>{data?.title?.french}</h1>
        </div>
      </div>
      <div className="top-sec-about">
        <div className="container">
          <div className="main-column-about">
            <div className="left-side-Cabout">
              <div className="Lcontent-about">
                <p
                  className="post-content-para"
                  dangerouslySetInnerHTML={{
                    __html: data?.description?.french,
                  }}
                ></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NewsLetterFr />
      <FooterFr />
    </>
  );
}

export default AboutUsFr;
