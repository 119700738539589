import React, { useState, useEffect } from "react";
import "../CSS/TermsOfUseAr.css";
import { ToastContainer, toast } from "react-toastify";
import DataService from "../services/data.service";
import HeaderAr from "../Common/HeaderAr";
import NewsLetterAr from "../Common/NewsLetterAr";
import FooterAr from "../Common/FooterAr";

function TermsOfUseAr() {
  const [data, setData] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Terms of Use";
    getData();
  }, []);
  const getData = async () => {
    await DataService.getTermsUsedAr().then((data) => {
      setData(data?.data?.data[0]);
    });
  };

  return (
    <>
      <HeaderAr />
      <div className="banner-sec-Terms1">
        <div className="banner-T-tittle1">
          <h1>{data?.title?.arabic}</h1>
        </div>
      </div>
      <div className="top-sec-privacy1">
        <div className="container">
          <div className="main-content-privacy1">
            <p
              className="post-content-para1"
              dangerouslySetInnerHTML={{
                __html: data?.description?.arabic,
              }}
            ></p>
          </div>
        </div>
      </div>
      <NewsLetterAr />
      <FooterAr />
    </>
  );
}

export default TermsOfUseAr;
