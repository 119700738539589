import React, { useState, useEffect } from "react";
import "../CSS/AboutUsAr.css";
import { ToastContainer, toast } from "react-toastify";
import DataService from "../services/data.service";
import HeaderAr from "../Common/HeaderAr";
import NewsLetterAr from "../Common/NewsLetterAr";
import FooterAr from "../Common/FooterAr";

function AboutUsAr() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "About Us";
    getData();
  }, []);
  const getData = async () => {
    await DataService.getAboutUsAr().then((data) => {
      setData(data?.data?.data[0]);
    });
  };
  return (
    <>
      <HeaderAr />
      <div className="sec-banner-about1">
        <div className="main-tittle-banner1">
          <h1>{data?.title?.arabic}</h1>
        </div>
      </div>
      <div className="top-sec-about1">
        <div className="container">
          <div className="main-column-about1">
            <div className="left-side-Cabout1">
              <div className="Lcontent-about1">
                <p
                  className="post-content-para1"
                  dangerouslySetInnerHTML={{
                    __html: data?.description?.arabic,
                  }}
                ></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NewsLetterAr />
      <FooterAr />
    </>
  );
}

export default AboutUsAr;
