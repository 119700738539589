import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";

import DataService from "../services/data.service";
import "../CSS/NewsLetterAr.css";


function NewsLetterAr() {
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email.trim()) {
      return;
    }
    const data = {};
    data.email = email;

    DataService.addEmail(data).then(
      () => {
        toast.success("Email submit successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setEmail("");
      },

      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        toast.error(resMessage, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    );
  };
  return (
    <>
      <section className="newsletter_sec1">
        <div className="container">
          <div className="newsletter_flex1">
            <div className="newsletter_flex1L1">
              <img
                src="https://news.fanzat.com/wp-content/themes/nnsm3-news/images/news-lady.png"
                alt=""
              />
            </div>
            <div className="newsletter_flex1R1">
              <h2>الاشتراك في النشرة الإخبارية</h2>
              <p>ستتلقى أحدث الأخبار والتحديثات من مؤثريك المفضلين</p>
              <div class="mb-3 bottom-form1">
                <input
                  type="email"
                  class="form-control"
                  // id="floatingInput"
                  value={email}
                  placeholder=" عنوان البريد الإلكتروني"
                  onChange={(e) => setEmail(e.target.value)}
                />
                {/* <label for="floatingInput">عنوان البريد الإلكتروني</label> */}
              </div>
              <button onClick={handleSubmit}>إرسال</button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default NewsLetterAr;
