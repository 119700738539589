import React, { useState, useEffect } from "react";

import DataService from "../services/data.service";
import { useNavigate, useParams } from "react-router-dom";
import "../CSS/Posts.css";
import ikj from "../images/ikj.jpg";
import { ToastContainer, toast } from "react-toastify";
import NewsLetterFr from "../Common/NewsLetterFr";
import FooterFr from "../Common/FooterFr";
import HeaderFr from "../Common/HeaderFr";

function RelatedPostsFr() {
  const params = useParams();

  const [currentPage, setCurrentPage] = useState(1);
  const [TotalPostsCount, setTotalPostsCount] = useState("");
  const postsPerPage = 20;
  const [masterCategory, setMasterCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [newsC, setNewsC] = useState("");
  const [data, setData] = useState();
  const [postdate, setPostDate] = useState();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [fnews, setFNews] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    document.title = "Related Posts";
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    const getRelatedPosts = async () => {
      await DataService.getACategoryByIdFr(params.id).then(
        (data) => {
          setNewsC(data?.data?.data?.posts);
          setTotalPostsCount(data?.data?.data?.totalCategoryPostsCount);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.error) ||
            error.error ||
            error.toString();
          setLoading(false);

          setMessage(resMessage);
          // toast.error(resMessage, {
          //   position: toast.POSITION.TOP_RIGHT,
          // });
        }
      );
    };
    getRelatedPosts();
    getAllGNews(currentPage);
    window.scrollTo(0, 0);
  }, []);

  const getAllGNews = async (newPage) => {
    setLoading(true);
    await DataService.getRelatedPostFr(params.id, newPage).then(
      (data) => {
        setFNews(data?.data?.data?.posts);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.error) ||
          error.error ||
          error.toString();
        setLoading(false);

        setMessage(resMessage);
        // toast.error(resMessage, {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
      }
    );
  };
  // const getRelatedPosts = async () => {
  //   await DataService.getACategoryById(params.id).then((data) => {
  //     setNewsC(data?.data?.data?.posts);
  //   });
  // };

  // const singleblog = async () => {
  //   await DataService.getSingleBlog(params.id).then((data) => {
  //     setData(data.data.data);
  //     setPostDate(data?.data?.data?.post_date);
  //     const categoryId = data?.data?.data?.categories[1]?.id;
  //     if (categoryId) {
  //       getRelatedPosts(categoryId);
  //     }
  //   });
  // };
  const getAllCategory = async () => {
    await DataService.getAllCategory(1).then((data) => {
      setMasterCategory(data?.data?.categories);
    });
    await DataService.getAllCategory(2).then((data) => {
      setSubCategory(data?.data?.categories);
    });
  };
  useEffect(() => {
    getAllCategory();
  }, []);

  const totalPages = Math.ceil(TotalPostsCount / postsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    getAllGNews(newPage);
  };

  const visiblePageButtons = 5;

  const renderPageButtons = () => {
    const pageButtons = [];
    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 ||
        (i >= currentPage - Math.floor(visiblePageButtons / 2) &&
          i <= currentPage + Math.floor(visiblePageButtons / 2))
      ) {
        pageButtons.push(
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={currentPage === i ? "active-btn" : ""}
          >
            {i}
          </button>
        );
      }
    }
    return pageButtons;
  };

  return (
    <>
     <HeaderFr/>
      <div className="main-banner-post">
        <div className="banner-post-content">
          <h1>Publicación relacionada</h1>
        </div>
      </div>

      <div className="section3-post-page">
        {loading && (
          <div className="loader-spin">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        )}
        <div className="container">
          <div className="related-post-bottom post-by-starTag-sec">
            <h2>Todas las noticias</h2>
            <div className="related-post-Bbox">
              {fnews && fnews.length > 0 ? (
                fnews
                  .filter(
                    (item) => item.status === "publish"
                    // item.status === "publish" &&
                    // item?._id !== data?._id
                  )
                  .map((item, index) => {
                    const postDate = new Date(item.date);
                    const formattedDate = `${postDate.toLocaleDateString()} ${postDate.toLocaleTimeString()}`;
                    return (
                      <>
                        <div className="related-post-Bcolumn post-star-tag-sec">
                          <a href={"/fr/single-blog-post/" + item._id}>
                            <div className="Bcolumn-img">
                              <img
                                src={
                                  "https://fanzat-news.onrender.com" +
                                  item?.featured_media?.source_url
                                }
                                className="product-img"
                                alt="image"
                              />
                            </div>
                            <div className="Bcolumn-content">
                              <h4>
                                {" "}
                                {item?.title?.espanol
                                  ?.split(" ")
                                  .slice(0, 8)
                                  .join(" ")}
                              </h4>

                              <p
                                dangerouslySetInnerHTML={{
                                  __html: item.content?.espanol
                                    ?.split(" ")
                                    .slice(0, 12)
                                    .join(" "),
                                }}
                              ></p>
                            </div>
                          </a>
                        </div>
                      </>
                    );
                  })
              ) : (
                <p>No se encontraron noticias</p>
              )}
            </div>

            <div className="show-more-posts-btn">
              {currentPage > 1 && (
                <button onClick={() => handlePageChange(currentPage - 1)}>
                  {"< previous"}
                </button>
              )}
              {renderPageButtons()}
              {currentPage < totalPages && (
                <button onClick={() => handlePageChange(currentPage + 1)}>
                  {"Next >"}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <NewsLetterFr/>
      <FooterFr/>
    </>
  );
}

export default RelatedPostsFr;
