import React, { useEffect, useState } from "react";

import "../CSS/StarPage.css";
import DataService from "../services/data.service";
import FooterEs from "../Common/FooterEs";
import NewsLetterEs from "../Common/NewsLetterEs";
import HeaderEs from "../Common/HeaderEs";
import { Link } from "react-router-dom";


function StarPageEs() {
  const [starTag, setStarTag] = useState("");
  const [dataT, setDataT] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const postsPerPage = 20;
  const [TotalTagsCount, setTotalTagsCount] = useState("");

  // const getAllStarTag = async () => {
  //   await DataService.getAllStarTags().then((data) => {
  //     setStarTag(data?.data?.data);
  //   });
  // };
  // useEffect(() => {
  //   document.title = "Star Tags";
  //   getAllStarTag();
  // }, []);

  useEffect(() => {
    document.title = "Star Tags";
    const getAllStarTag = async () => {
      await DataService.getAllStarTagsEs().then(
        (data) => {
          setStarTag(data?.data?.data);
          setTotalTagsCount(data?.data?.totalStarTags);
          console.log(data?.data.totalStarTags);
          window.scrollTo(0, 0);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
          setMessage(resMessage);
        }
      );
    };

    getAllStarTag();
    getAllStarTags(currentPage);
  }, []);

  const getAllStarTags = async (newPage) => {
    setLoading(true);
    await DataService.getAllStarTagEs(newPage).then(
      (data) => {
        setDataT(data?.data?.data);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);
        setMessage(resMessage);
      }
    );
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    getAllStarTags(newPage);
  };

  const totalPages = Math.ceil(TotalTagsCount / postsPerPage);

  const visiblePageButtons = 5;

  const renderPageButtons = () => {
    const pageButtons = [];
    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 ||
        (i >= currentPage - Math.floor(visiblePageButtons / 2) &&
          i <= currentPage + Math.floor(visiblePageButtons / 2))
      ) {
        pageButtons.push(
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={currentPage === i ? "active-btn" : ""}
          >
            {i}
          </button>
        );
      }
    }
    return pageButtons;
  };

  return (
    <>
      <HeaderEs />

      <div className="top-sec-star">
        <div className="container">
          {loading && (
            <div className="loader-spin">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          )}
          <div className="star-top-navbar">
            <h2>Todas las etiquetas de estrella</h2>

            <div className="main-startag-page">
              {starTag && starTag.length > 0 ? (
                starTag?.map((item) => {
                  return (
                    <>
                      <div className="star-tag-page">
                        <div className="startag-img-page">
                          <img
                            src={
                              "https://fanzat-news.onrender.com" +
                              item?.image?.url
                            }
                            className="product-img"
                            alt="image"
                          />
                        </div>
                        <Link to={`/es/posts-by-star-tags/${item.TermID}`}>
                          <h4> {item?.starTags?.espanol}</h4>
                        </Link>
                      </div>
                    </>
                  );
                })
              ) : (
                <p>No se encontró ninguna categoría</p>
              )}
            </div>
            <div className="show-more-posts-btn">
              {currentPage > 1 && (
                <button onClick={() => handlePageChange(currentPage - 1)}>
                  {"< Anterior"}
                </button>
              )}
              {renderPageButtons()}
              {currentPage < totalPages && (
                <button onClick={() => handlePageChange(currentPage + 1)}>
                  {"Siguiente >"}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <NewsLetterEs />
      <FooterEs />
    </>
  );
}

export default StarPageEs;
