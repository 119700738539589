import React,{useEffect} from "react";
import "../CSS/SiteMap.css";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import { Link } from "react-router-dom";

function SiteMap() {
  useEffect(()=>{
    document.title ="Site Map";
    window.scroll(0,0);
      })
  return (
    <>
      <Header />
      <div className="Sm-main-sec">
        <div className="container">
          <div className="main-siteM-tittle">
            <h2>Site Map</h2>
          </div>
          <div className="Sm-main-column">
            <div className="Sm-list-link1">
              <h4 className="Sm-list-heading">Pages</h4>
              <ul>
                <Link to="/">
                  <li>Home</li>
                </Link>
                <Link to="/contact-us">
                  <li>Conatct Us</li>
                </Link>
                <Link to="/terms-of-use">
                  <li>Terms of Use</li>
                </Link>
                <Link to="/privacy-policy">
                  <li>Privacy Policy</li>
                </Link>
              </ul>
            </div>
            <div className="Sm-list-link2">
              <h4 className="Sm-list-heading">Posts</h4>
            
              <h6 className="Sm-list-link-hedaing">Galaxy</h6>
                <div className="sub-post-galaxy">
                  <ul>
                    <Link to="/single-blog-post">
                      <li>Fanzat Campaigns</li>
                    </Link>
                    <Link to="/single-blog-post">
                      <li>Fanzat Contests</li>
                    </Link>
                    <Link to="/single-blog-post">
                      <li>Fanzat Events</li>
                    </Link>
                    <Link to="/single-blog-post">
                      <li>Fanzat media Coverage</li>
                    </Link>
                    <Link to="/single-blog-post">
                      <li>Fanzat News</li>
                    </Link>
                  </ul>
                </div>
                <h6 className="Sm-list-link-hedaing">Orbit</h6>
                <div className="sub-post-galaxy">
                  <ul>
                    <Link to="/single-blog-post">
                      <li>by Company’s</li>
                    </Link>
                    <Link to="/single-blog-post">
                      <li>Company’s Campaigns</li>
                    </Link>
                    <Link to="/single-blog-post">
                      <li>Company’s Events</li>
                    </Link>
                    <Link to="/single-blog-post">
                      <li>Company’s Interviews</li>
                    </Link>
                    <Link to="/single-blog-post">
                      <li>Company’s News</li>
                    </Link>
                  </ul>
                </div>
               <h6 className="Sm-list-link-hedaing">Stars</h6>
                <div className="sub-post-galaxy">
                  <ul>
                    <Link to="/star-page">
                      <li>by Stars’</li>
                    </Link>
                    <Link to="/star-page">
                      <li>Stars’ Campaigns</li>
                    </Link>
                    <Link to="/star-page">
                      <li>Stars’ Events</li>
                    </Link>
                    <Link to="/star-page">
                      <li>Stars’ Interviews</li>
                    </Link>
                    <Link to="/star-page">
                      <li>Stars’ News</li>
                    </Link>
                  </ul>
                </div>
           
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default SiteMap;
