import React, { useState, useEffect } from "react";

import { ToastContainer, toast } from "react-toastify";
import DataService from "../services/data.service";
import "../CSS/NewsLetter.css";

function NewsLetterFr() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email.trim()) {
      return;
    }
    const data = {};
    data.email = email;

    DataService.addEmail(data).then(
      () => {
        toast.success("Email submit successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setEmail("");
      },

      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        toast.error(resMessage, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    );
  };
  return (
    <>


      <section className="newsletter_sec">
        <div className="container">
          <div className="newsletter_flex">
            <div className="newsletter_flexL">
              <img
                src="https://news.fanzat.com/wp-content/themes/nnsm3-news/images/news-lady.png"
                alt=""
              />
            </div>
            <div className="newsletter_flexR">
              <h2>S'abonner to Newsletter</h2>
              <p>
                Vous recevrez les dernières nouvelles et mises à jour de vos
                influenceurs préférés
              </p>
              <div class="form-floating mb-3">
                <input
                  type="email"
                  class="form-control"
                  id="floatingInput"
                  placeholder="name@example.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <label for="floatingInput">Adresse e-mail</label>
              </div>
              <button onClick={handleSubmit}>Soumettre</button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default NewsLetterFr;
