import React, { useEffect, useState } from "react";

import "../CSS/StarPage.css";
import DataService from "../services/data.service";
import { ToastContainer, toast } from "react-toastify";
import NewsLetterAr from "../Common/NewsLetterAr";
import FooterAr from "../Common/FooterAr";
import HeaderAr from "../Common/HeaderAr";

function MostUsedTagAr() {
  const [tag, setTag] = useState("");
  const [dataT, setDataT] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const postsPerPage = 20;
  const [TotalTagsCount, setTotalTagsCount] = useState("");

  useEffect(() => {
    document.title = "All Tags";
    const getAllTags = async () => {
      await DataService.getAllTagAr().then((data) => {
        setTag(data?.data?.data?.tags);
        setTotalTagsCount(data?.data?.data?.totalItems);
        window.scrollTo(0, 0);
      });
    };

    getAllTags();
    getAllMTags(currentPage);
  }, []);

  const getAllMTags = async (newPage) => {
    setLoading(true);
    await DataService.getAllTagsAr(newPage).then((data) => {
      setDataT(data?.data?.data?.tags);
      setLoading(false);
    });
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    getAllMTags(newPage);
  };

  const totalPages = Math.ceil(TotalTagsCount / postsPerPage);

  const visiblePageButtons = 5;

  const renderPageButtons = () => {
    const pageButtons = [];
    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 ||
        (i >= currentPage - Math.floor(visiblePageButtons / 2) &&
          i <= currentPage + Math.floor(visiblePageButtons / 2))
      ) {
        pageButtons.push(
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={currentPage === i ? "active-btn" : ""}
          >
            {i}
          </button>
        );
      }
    }
    return pageButtons;
  };
  return (
    <>
      <HeaderAr />
      <div className="top-sec-star1">
        <div className="container">
          {loading && (
            <div className="loader-spin">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          )}
          <div className="star-top-navbar1">
            <h2>جميع النجوم </h2>

            <div className="main-startag-page">
              {dataT && dataT.length > 0 ? (
                dataT?.map((item) => {
                  return (
                    <>
                      <div className="star-tag-page most-used-tag">
                        <div className="startag-img-page1">
                          <img
                            src={
                              "https://fanzat-news.onrender.com" +
                              item?.image?.url
                            }
                            alt="image"
                          />
                        </div>
                        <h4> {item?.tags?.arabic}</h4>
                      </div>
                    </>
                  );
                })
              ) : (
                <p>لا توجد اشارات لنجوم حاليا </p>
              )}
            </div>
            <div className="show-more-posts-btn1">
              {currentPage > 1 && (
                <button onClick={() => handlePageChange(currentPage - 1)}>
                  {"< السابق "}
                </button>
              )}
              {renderPageButtons()}
              {currentPage < totalPages && (
                <button onClick={() => handlePageChange(currentPage + 1)}>
                  {"التالي  >"}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <NewsLetterAr />
      <FooterAr />
    </>
  );
}

export default MostUsedTagAr;
