import React, { useState, useEffect } from "react";
import "../CSS/AboutUs.css";

import { ToastContainer, toast } from "react-toastify";
import DataService from "../services/data.service";
import NewsLetterEs from "../Common/NewsLetterEs";
import FooterEs from "../Common/FooterEs";
import HeaderEs from "../Common/HeaderEs";

function AboutUsEs() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "About Us";
    getData();
  }, []);
  const getData = async () => {
    await DataService.getAboutUsEs().then((data) => {
      setData(data?.data?.data[0]);
    });
  };
  return (
    <>
      <HeaderEs />
      <div className="sec-banner-about">
        <div className="main-tittle-banner">
          <h1>{data?.title?.espanol}</h1>
        </div>
      </div>
      <div className="top-sec-about">
        <div className="container">
          <div className="main-column-about">
            <div className="left-side-Cabout">
              <div className="Lcontent-about">
                <p
                  className="post-content-para"
                  dangerouslySetInnerHTML={{
                    __html: data?.description?.espanol,
                  }}
                ></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NewsLetterEs />
      <FooterEs />
    </>
  );
}

export default AboutUsEs;
