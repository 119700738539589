import React, { useState, useEffect } from "react";
import "../CSS/SingleBlogPost.css";
import Slider from "react-slick";
import hj from "../images/ikj.jpg";
import teams from "../images/img-teams.png";
import Info2 from "../images/info-2.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import DataService from "../services/data.service";
import moment from "moment";
import { Link } from "react-router-dom";
import SharePost from "../Common/SharePost";
import NewsLetterFr from "../Common/NewsLetterFr";
import FooterFr from "../Common/FooterFr";
import HeaderFr from "../Common/HeaderFr";

function SingleBlogPostFr() {
  const params = useParams();
  const [count, setCount] = useState(1);
  const [data, setData] = useState();
  const [dataB, setDataB] = useState();
  const [loading, setLoading] = useState(false);
  const [postdate, setPostDate] = useState();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [reply, setReply] = useState("");
  const [Anews, setANews] = useState("");
  const [newsC, setNewsC] = useState("");
  const [relatedId, setRelatedId] = useState("");
  const [showPreview, setShowPreview] = useState(false);
  const [totalCount, setTotalCount] = useState("");
  // const [category, setCategory] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [message, setMessage] = useState("");

  // const [subcategory, setBData] = useState([]);

  const [masterCategory, setMasterCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);

  const handleViewMore = () => {
    setCount((prevCount) => prevCount + 1);
  };
  const handleLessView = () => {
    setCount((prevCount) => prevCount - 1);
  };

  const getAllCategory = async () => {
    await DataService.getAllCategory(1).then((data) => {
      setMasterCategory(data?.data?.categories);
    });
    await DataService.getAllCategory(2).then((data) => {
      setSubCategory(data?.data?.categories);
    });
  };

  useEffect(() => {
    document.title = "Single Post";
    window.scrollTo(0, 0);
    getAllCategory();
  }, []);

  const singleblog = async () => {
    await DataService.getSingleBlogFr(params.id).then(
      (data) => {
        setData(data.data.data);
        setPostDate(data?.data?.data?.post_date);
        setLoading(false);
        // getRelatedPosts(data?.data?.data?.categories?.id);
        setCategoryId(data?.data?.data?.categories[1]?.id);

        const categoryId = data?.data?.data?.categories[1]?.id;
        if (categoryId) {
          getRelatedPosts(categoryId);
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
        // toast.error(resMessage, {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
      }
    );
  };
  useEffect(() => {
    singleblog();
    getAllPopularNewsNews();
  }, []);

  const getAllPopularNewsNews = async () => {
    await DataService.getAllPopularNewsFr().then(
      (data) => {
        setANews(data?.data?.data);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
        // toast.error(resMessage, {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
      }
    );
  };
  const getRelatedPosts = async (categoryId) => {
    await DataService.getACategoryByIdFr(categoryId).then(
      (data) => {
        setNewsC(data?.data?.data);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
        // toast.error(resMessage, {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
      }
    );
  };

  const handleSoumettre = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {};
    data.comment_author = name;
    data.comment_author_email = email;
    data.comment_author_url = website;
    data.comment_content = reply;
    data.comment_post_ID = params.id;

    DataService.AddComment(data).then(
      () => {
        toast.success("Comment Posted successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setName("");
        setEmail("");
        setWebsite("");
        setReply("");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
        // setLoading(false);
      },

      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.error) ||
          error.message ||
          error.toString();
        setLoading(false);
        toast.error(resMessage, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    );
  };

  const allComments = async () => {
    await DataService.getComments(params.id).then((data) => {
      setDataB(data?.data.filter((item) => item.comment_status === "approved"));
      setTotalCount(data.data?.length);
      setLoading(false);
    });
  };
  useEffect(() => {
    allComments();
  }, []);

  const formattedDate = postdate ? moment(postdate).format("MMM DD YYYY") : "";

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <HeaderFr />
      <section className="singlePost-topSec">
        <ToastContainer></ToastContainer>

        <div className="container">
          <div className="topsec-maincolumn">
            <div className="main-column-L">
              <div className="columnL-top-banner">
                <div className="post-cat">
                  {/* <a href="#"> */}{" "}
                  {data?.categories
                    .filter((category) => category.type === "1")
                    .map((category) => (
                      <h6>{category.name?.french}</h6>
                    ))}
                  {/* </a> */}
                </div>
                <div className="post-main-heading">
                  <h1>{data?.title?.french}</h1>
                </div>

                <div className="post-meta-wrap">
                  {/* <div className="post-author-avtar">
                    <img
                      src={
                        "https://fanzat-news.onrender.com/" +
                        data?.user_ID?.images[0]?.path
                      }
                      // src={data?.images?.path ? data?.image?.path : "../assets/img/profile-img.png"}
                      className="product-img"
                      alt="image"
                    />
                    <div className="post-author-content">
                      <h6>{data?.user_ID?.user_nicename}</h6>
                      <ul>
                        <li>{formattedDate}</li>
                        <li>300K Views</li>
                      </ul>
                    </div>
                  </div> */}

                  <div className="social-icon-post">
                    <SharePost
                      title={data?.title?.french}
                      url={`https://fanzat-news-arabic.vercel.app/single-blog-post/${params.id}`}
                    />
                  </div>
                </div>
              </div>
              <div className="singlepost-details">
                {/* <div className=" banner-image-slider">
                  <Slider {...settings}>
                    <div>
                      <div className="banner-sub-slider">
                        <div className="banner-sub-over"></div>
                        <img
                          src="https://news.fanzat.com/wp-content/uploads/2019/12/NNSM3.jpg"
                          alt=""
                        />
                      </div>
                    </div>
                  </Slider>
                </div> */}
                <div className="singlepost-content">
                  <div className="single-post-image">
                    <img
                      src={
                        "https://fanzat-news.onrender.com" +
                        data?.featured_media[0]?.source_url
                      }
                      className="product-img"
                      alt="image"
                    />
                  </div>

                  <div className="sub-featured-images">
                    {data?.featured_media.length > 1 &&
                      data.featured_media.slice(1).map((item, index) => (
                        <div className="sub-images-single-post" key={index + 1}>
                          <img
                            src={
                              "https://fanzat-blogs-api.onrender.com" +
                              item.source_url
                            }
                            alt={"Image " + (index + 1)}
                          />
                        </div>
                      ))}
                  </div>
                  <p
                    className="post-content-para"
                    dangerouslySetInnerHTML={{
                      __html: data?.content?.french
                        ? data?.content?.french
                        : "",
                    }}
                  ></p>
                  <div className="preview-reviews-heading">
                    <h3>Avis préliminaires</h3>
                  </div>
                  {dataB && dataB.length > 0 ? (
                    dataB.slice(0, count).map((item, index) => (
                      <div key={index} className="post-Labout-author">
                        <div className="Labout-details">
                          <div className="L-author-data">
                            <h5>{item?.comment_author}</h5>
                            <p>{item?.comment_content}</p>
                            {/* <div className="L-author-social-link">
                            </div> */}
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>Aucun commentaire approuvé disponible.</p>
                  )}
                  <div className="comments-btn-main">
                    {dataB?.length > 1 && count !== totalCount ? (
                      // {dataB?.length > 1 && dataB?.length < totalCount && (
                      <div className="comments-more">
                        <button
                          className="main-btn"
                          // disabled={dataB && dataB.length === count}
                          onClick={handleViewMore}
                        >
                          Voir plus
                        </button>
                      </div>
                    ) : (
                      ""
                    )}

                    {count !== 1 ? (
                      <div className="comments-more less-more-btn">
                        <button className="main-btn" onClick={handleLessView}>
                          Précédent
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  {/* {dataB && dataB.length > 0 ? (
                dataB.slice(0, 4).map((item, index) => {
                    <div className="post-Labout-author">
                    <div className="Labout-details">
                      <div className="Lauthor-img">
                  <img src={hj} alt="" />
                </div>
                      <div className="L-author-data">
                        <h5>{item?.comment_author}</h5>

                        <p>{item?.comment_content}</p>
                        <div className="L-author-social-link">
                    <ul>
                      <li>
                        <a href="">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a href="">
                          <i className="fas fa-link"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                      </div>
                    </div>
                  </div>

                    
                  })
                  ) : (
                    <p>No comments Found</p>
                  )} */}

                  {/* {dataB && dataB.length > 0
                    ? dataB.slice(0, 4).map((item, i) => (
                        <div className="post-Labout-author">
                          <div className="Labout-details">
                         
                            <div className="L-author-data">
                              <h5>{item?.comment_author}</h5>

                              <p>{item?.comment_content}</p>
                              <div className="L-author-social-link">
                         
                        </div>
                            </div>
                          </div>
                        </div>
                      ))
                    : null} */}

                  <div className="single-post-form">
                    <div className="post-form-heading">
                      <h4>Poster un commentaire</h4>
                    </div>
                    <form>
                      <div className="form-flex">
                        <div class="form-floating mb-3">
                          <input
                            type="text"
                            class="form-control"
                            id="floatingInput"
                            placeholder="Votre nom"
                            onChange={(e) => setName(e.target.value)}
                          />
                          <label for="floatingInput">Votre nom</label>
                        </div>
                        <div class="form-floating mb-3">
                          <input
                            type="email"
                            class="form-control"
                            id="floatingInput"
                            placeholder="name@example.com"
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          <label for="floatingInput">Adresse e-mail</label>
                        </div>
                        <div class="form-floating mb-3">
                          <input
                            type="text"
                            class="form-control"
                            id="floatingText"
                            placeholder="Text"
                            onChange={(e) => setWebsite(e.target.value)}
                          />
                          <label for="floatingText">Votre site web</label>
                        </div>
                      </div>
                      <div className="comment-box-form">
                        <div class="form-floating">
                          <textarea
                            class="form-control"
                            placeholder="Laisser un commentaire here"
                            id="floatingTextarea"
                            onChange={(e) => setReply(e.target.value)}
                          ></textarea>
                          <label for="floatingTextarea">
                            Laisser une réponse
                          </label>
                        </div>
                      </div>
                      <div className="post-form-btn">
                        <button onClick={handleSoumettre}>
                          Laisser un commentaire
                        </button>
                      </div>
                    </form>
                  </div>
                  {/* {data && data.length > 0
            ? data.map((item, i) => ( */}

                  {/* <div className="post-comment-area">
                    <div className="post-comment-column">
                      <div className="post-comment-img">
                        <img src={hj} alt="" />
                      </div>
                      <div className="post-comment-content">
                        <p className="comment-author-name">Lorem ipsum dolor</p>
                        <div className="post-comment-time">
                          <p className="comment-date-time">Lorem ipsum dolor</p>
                          <p className="comment-reply">
                            <i class="fas fa-circle"></i> Reply{" "}
                          </p>
                        </div>
                        <p className="post-comment-para">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim.
                        </p>
                      </div>
                    </div>
                  </div> */}

                  <div className="related-post-bottom">
                    <div className="related-post-top-bar">
                      <div className="related-post-top-heading">
                        <h3>Actualités connexes</h3>
                      </div>
                      {newsC && newsC.length > 1 && (
                        <div className="related-posts-btn">
                          <Link to={"/fr/related-posts/" + categoryId}>
                            <button className="trending-top-btnF">
                              Voir plus
                            </button>
                          </Link>
                        </div>
                      )}
                    </div>
                    <div className="related-post-Bbox">
                      {newsC && newsC.length > 0 ? (
                        newsC
                          .filter(
                            (item) =>
                              item.status === "publish" &&
                              item?._id !== data?._id
                          )
                          .slice(0, 2)
                          .map((item, index) => {
                            const postDate = new Date(item.date);
                            const formattedDate = `${postDate.toLocaleDateString()} ${postDate.toLocaleTimeString()}`;
                            return (
                              <>
                                <div className="related-post-Bcolumn">
                                  <a href={"/fr/single-blog-post/" + item._id}>
                                    <div className="Bcolumn-img">
                                      <img
                                        src={
                                          "https://fanzat-news.onrender.com" +
                                          item?.featured_media?.source_url
                                        }
                                        className="product-img"
                                        alt="image"
                                      />
                                    </div>
                                    <div className="Bcolumn-content">
                                      <h4>
                                        {" "}
                                        {item?.title?.french
                                          ?.split(" ")
                                          .slice(0, 8)
                                          .join(" ")}
                                      </h4>

                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: item.content?.french
                                            ?.split(" ")
                                            .slice(0, 12)
                                            .join(" "),
                                        }}
                                      ></p>
                                    </div>
                                  </a>
                                </div>
                              </>
                            );
                          })
                      ) : (
                        <p>Aucune actualité trouvée</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="main-column-R">
              <div className="sidebar-Rcolumn">
                {/* <div className="search-box">
                  <h5>Search</h5>
                  <div
                    class="search_bar post-search-bar"
                    style={{ marginTop: "15px" }}
                  >
                    <input type="search" placeholder="Search..." />
                  </div>
                </div> */}
                <div className="video-section-singlePost">
                  {data?.video?.source_url && (
                    <iframe
                      width="560"
                      height="315"
                      src={`https://fanzat-blogs-api.onrender.com${data.video.source_url}`}
                      title="Embedded Video"
                      frameBorder="0"
                      allowFullScreen
                    ></iframe>
                  )}
                </div>

                <div className="popular-post">
                  <h5>Actualités populaires</h5>
                  <div className="post-medium-blogs">
                    {Anews && Anews.length > 0 ? (
                      Anews.filter((item) => item.status === "publish")
                        .slice(0, 4)
                        .map((item, index) => {
                          const postDate = new Date(item.date);
                          const formattedDate = `${postDate.toLocaleDateString()} ${postDate.toLocaleTimeString()}`;
                          return (
                            <>
                              <a href={"/fr/single-blog-post/" + item._id}>
                                <div className="pop_postFlex">
                                  <div className="pop_postFlexL">
                                    <img
                                      src={
                                        "https://fanzat-news.onrender.com" +
                                        item?.featured_media?.source_url
                                      }
                                      className="product-img"
                                      alt="image"
                                    />
                                  </div>
                                  <div className="pop_postFlexR">
                                    <div className="post_titleSec">
                                      <h3 className="post_title">
                                        {item?.title?.french
                                          ?.split(" ")
                                          .slice(0, 12)
                                          .join(" ")}
                                      </h3>
                                    </div>
                                    <span>{formattedDate}</span>
                                  </div>
                                </div>
                              </a>
                            </>
                          );
                        })
                    ) : (
                      <p>Aucune actualité trouvée</p>
                    )}
                  </div>
                </div>
                <div className="post-newsletter">
                  <div post-newsletter-inner>
                    <h5>Ne manquez jamais une actualité!</h5>
                    <p>
                      "Inscrivez-vous gratuitement et soyez le
                      <br /> premier à être informé des mises à jour."
                    </p>
                    <div className="search-box newsletter-search">
                      <div
                        class="search_bar post-search-bar"
                        style={{ marginTop: "15px" }}
                      >
                        <input
                          type="search"
                          placeholder="Entrez votre e-mail"
                        />
                        <div className="newsletter-search-button">
                          <button className="main-btn">S'abonner</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div class="post-Rthumbnail">
                  <img
                    src="https://new.axilthemes.com/demo/react/blogar/images/post-single/ads-01.webp?imwidth=640"
                    className="post-Rthumbnail-img"
                  />
                </div> */}
                <div className="post-Rsocial-icon">
                  <h5>Restez en contact</h5>
                  <ul className="post-main-icons">
                    <li>
                      <a href="#">
                        <i class="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        {" "}
                        <i class="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i class="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        {" "}
                        <i class="fas fa-link"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                {/* <div className="post-Rcategory">
                  <h5>Category</h5>
                  <div className="post-Rsub-category">
                    {masterCategory && masterCategory.length > 0 ? (
                      masterCategory.map((item) => {
                        return (
                          <>
                            <button>{item?.name?.english}</button>

                            <div className="mega-menu-cate1 mega-menu-cate2">
                                <h4>{item?.name?.english}</h4>
                                <ul>
                                  {item._id === parentId ? (
                                    subCategory.map((subcategoryItem) => (
                                      <li key={subcategoryItem._id}>{subcategoryItem?.name?.english}</li>
                                    ))
                                  ) : (
                                    <li>No Sub Category</li>
                                  )}
                                </ul>
                              </div>
                          </>
                        );
                      })
                    ) : (
                      <p>Aucune catégorie trouvée</p>
                    )}
                  </div>
                </div> */}

                <div className="info-box-registation">
                  <div className="info-sub-boxR">
                    <h4>
                      "CÉLÈBRE?
                      <br />
                      INFLUENCEUR"
                    </h4>
                    <div className="info-image-box">
                      <img src={teams} />
                    </div>
                    <h5>
                      Commencez votre parcours avec Fanzat. Atteignez les
                      niveaux les plus élevés de portée.
                    </h5>
                    <div className="info-box-btn">
                      <a href="https://nnsm3.com/register/star">
                        <button> Inscrivez-vous en tant qu'Étoile</button>
                      </a>
                    </div>
                  </div>
                  <div className="info-sub-boxR">
                    <h4>AVEZ-VOUS UNE INFLUENCE RÉELLE ET UNIQUE ?</h4>
                    <div className="info-image-box">
                      <img
                        src="https://news.fanzat.com/wp-content/themes/nnsm3-news/images/info-2.png"
                        alt="image"
                      />
                    </div>
                    <h5>
                      Utilisez votre influence unique dans votre domaine pour
                      obtenir des résultats incroyables avec Fanzat.
                    </h5>
                    <div className="info-box-btn">
                      <a href="https://nnsm3.com/register/pulsar">
                        <button>Inscrivez-vous en tant que Pulsar</button>
                      </a>
                    </div>
                  </div>
                  <div className="info-sub-boxR">
                    <h4>"MARQUE? OU ENTERPRISE" </h4>
                    <div className="info-image-box">
                      <img
                        src="https://news.fanzat.com/wp-content/themes/nnsm3-news/images/info-3.png"
                        alt="image"
                      />
                    </div>
                    <h5>
                      Choisissez votre influenceur et suivez votre campagne
                      réussie sur une seule plateforme avec Fanzat.
                    </h5>
                    <div className="info-box-btn">
                      <a href="https://nnsm3.com/register/orbit">
                        <button> Inscrivez-vous en tant qu'Orbite</button>
                      </a>
                    </div>
                  </div>
                  <div className="info-sub-boxR">
                    <h4>
                      ÊTES-VOUS UN <br />
                      FAN?{" "}
                    </h4>
                    <div className="info-image-box">
                      <img
                        src="https://news.fanzat.com/wp-content/themes/nnsm3-news/images/info-3.png"
                        alt="image"
                      />
                    </div>
                    <h5>
                      Le Lorem Ipsum est le texte fictif standard de
                      l'industrie.
                    </h5>
                    <div className="info-box-btn">
                      <a href="">
                        <button>Inscrivez-vous en tant que Fan</button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <NewsLetterFr />
      <FooterFr />
    </>
  );
}

export default SingleBlogPostFr;
