import React, { useEffect, useState } from "react";
import Logo from "../images/fanzat_logo.png";
import Author from "../images/author.webp";
import "../CSS/Header.css";
import { useNavigate } from "react-router-dom";
import Icon from "../images/fanzat_icon.png";
import { Link } from "react-router-dom";
import DataService from "../services/data.service";
const HeaderFr = () => {
  const [selectedLanguage, setSelectedLanguage] = useState("");
  const navigate = useNavigate();
  const today = new Date().toLocaleDateString("en-US", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });
  const [masterCategory, setMasterCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [parentId, setParentId] = useState([]);
  const [starTag, setStarTag] = useState("");
  const [search, setSearch] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [message, setMessage] = useState("");

  const myStateData = {
    key1: search,
  };

  const getAllCategory = async () => {
    await DataService.getAllCategory(1).then((data) => {
      setMasterCategory(data?.data?.categories);
    });
    await DataService.getAllCategory(2).then((data) => {
      setSubCategory(data?.data?.categories);
    });
  };

  const getAllStarTag = async () => {
    await DataService.getAllStarTagsFr().then(
      (data) => {
        setStarTag(data?.data?.data);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
        // toast.error(resMessage, {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
      }
    );
  };

  useEffect(() => {
    document.title = "Category";
    window.scrollTo(0, 0);
    getAllCategory();
    getAllStarTag();
  }, []);

  const onChangeSearch = (event) => {
    const searchTerm = event.target.value;
    setSearch(searchTerm);
  };
  const handleChangeL = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    if (selectedValue === "En") {
      navigate("/");
    } else if (selectedValue === "Ar") {
      navigate("/ar");
    } else if (selectedValue === "Fr") {
      navigate("/fr");
    } else if (selectedValue === "Es") {
      navigate("/es");
    }
  };

  // const handleSubCategoryClick = (id) => {
  //   setSelectedSubCategory(id);
  // };

  return (
    <>
      <section className="main_topBar">
        <div className="container-two">
          <div className="topBar">
            <div className="topBarL">
              <Link to={"/fr"}>
                <img src={Icon} alt="" />
              </Link>
              <i class="fab fa-linkedin-in"></i>
              <i class="fab fa-twitter"></i>
              <i class="fab fa-instagram"></i>
              <i class="fab fa-facebook-f"></i>
              <i class="fab fa-youtube"></i>
              <i class="fab fa-snapchat-ghost"></i>
            </div>
            <div className="topBarM">
              <img
                src={Logo}
                alt=""
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/fr")}
              />
            </div>

            <div className="topBarR">
              <div className="innerLinks">
                <ul>
                  <a href="https://nnsm3.com/company/contact">
                    <li>Contact </li>
                  </a>
                  <Link to={"/fr/about-us"}>
                    <li>À propos </li>
                  </Link>
                  {/* <li>Publicité </li> */}
                </ul>
                {/* <button onClick={handleDirectionChange}>abc</button> */}
              </div>
              <div className="language_switcher">
                <select
                  class="form-select me-3 select-lan-box"
                  value={selectedOption}
                  onChange={handleChangeL}
                >
                  <option value="">Langue</option>
                  <option value="En">En</option>
                  <option value="Ar">Ar</option>
                  <option value="Fr">Fr</option>
                  <option value="Es">Es</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="main_headerBar">
        <div className="container-two">
          <div className="main_header">
            <div className="main_headerL">
              <ul>
                <Link to={"/fr"}>
                  <li>Accueil</li>
                </Link>
                <li>
                  Poster
                  <div className="mega_subMenu" style={{ left: "0px" }}>
                    <ul>
                      <li>Standard </li>
                      <li>Vidéo</li>
                      <li>Galerie</li>
                      <li>Audio</li>
                      <li>Citation</li>
                    </ul>
                  </div>
                </li>
                <li className="show-mega-menu">
                  Catégories
                  {/* <div className="mega_megaMenu">
                    <div className="mega_megaL">
                      <ul>
                        <li>Influencers - Stars</li>
                        <li>Brands - Orbits</li>
                        <li>Fanzat - Galaxy</li>
                      </ul>
                    </div>
                    <div className="mega_megaR">
                      <div className="mega_postFlex">
                        <div className="mega_postInner">
                          <img
                            src="https://new.axilthemes.com/demo/react/blogar/images/posts/thumbnail-08.webp"
                            alt=""
                          />
                          <span className="mega_cat">Research</span>
                          <h4>
                            The world’s most powerful woman, Oprah Winfrey.
                          </h4>
                        </div>
                        <div className="mega_postInner">
                          <img
                            src="https://new.axilthemes.com/demo/react/blogar/images/posts/post-column-02.webp"
                            alt=""
                          />
                          <span className="mega_cat">Research</span>
                          <h4>
                            The world’s most powerful woman, Oprah Winfrey.
                          </h4>
                        </div>
                        <div className="mega_postInner">
                          <img
                            src="https://new.axilthemes.com/demo/react/blogar/images/posts/thumbnail-02.webp"
                            alt=""
                          />
                          <span className="mega_cat">Research</span>
                          <h4>
                            The world’s most powerful woman, Oprah Winfrey.
                          </h4>
                        </div>
                        <div className="mega_postInner">
                          <img
                            src="https://new.axilthemes.com/demo/react/blogar/images/posts/post-seo-sm-04.webp"
                            alt=""
                          />
                          <span className="mega_cat">Research</span>
                          <h4>
                            The world’s most powerful woman, Oprah Winfrey.
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* {  item.parent == 18 ? 
                          (<h6>{item.name}</h6>) :""
                       } */}
                  <div className="mega-menu-category">
                    <div className="flex-mega-menu">
                      {masterCategory && masterCategory.length > 0 ? (
                        masterCategory.map((item) => {
                          return (
                            <>
                              <div className="mega-menu-cate1 mega-menu-cate2">
                                <h4>{item?.name?.french}</h4>

                                <ul>
                                  {subCategory.map((subcategoryItem) => {
                                    if (subcategoryItem.parent === item.id) {
                                      return (
                                        <a
                                          key={subcategoryItem.id}
                                          href={`/fr/post-by-category/${subcategoryItem.id}`}
                                        >
                                          <li>
                                            {subcategoryItem?.name?.french}
                                          </li>
                                        </a>
                                      );
                                    }
                                    return null;
                                  })}
                                </ul>
                              </div>
                            </>
                          );
                        })
                      ) : (
                        <p>Aucune catégorie trouvée</p>
                      )}
                    </div>
                  </div>
                </li>
                <li className="show-mega-menu">
                  Étoiles
                  <div className="mega-menu-category">
                    <div className="flex-mega-menu">
                      {/* <div className="mega-menu-cate1 mega-menu-cate2">
                        <h4>Galaxy</h4>

                        <ul>
                          {category && category.length > 0 ? (
                            category.map((item) => {
                              return (
                                <>
                                  {item.parent == 1 ? <li>{item.name}</li> : ""}
                                </>
                              );
                            })
                          ) : (
                            <p>Aucune catégorie trouvée</p>
                          )}
                        </ul>
                      </div>
                      <div className="mega-menu-cate1 mega-menu-cate2">
                        <h4>Orbit</h4>
                        <ul>
                          {category && category.length > 0 ? (
                            category.map((item) => {
                              return (
                                <>
                                  {item.parent == 18 ? (
                                    <li>{item.name}</li>
                                  ) : (
                                    ""
                                  )}
                                </>
                              );
                            })
                          ) : (
                            <p>Aucune catégorie trouvée</p>
                          )}
                        </ul>
                      </div>
                      <div className="mega-menu-cate1">
                        <h4>STARS</h4>
                        <ul>
                          {category && category.length > 0 ? (
                            category.map((item) => {
                              return (
                                <>
                                  {item.parent == 100 ? (
                                    <li>{item.name}</li>
                                  ) : (
                                    ""
                                  )}
                                </>
                              );
                            })
                          ) : (
                            <p>Aucune catégorie trouvée</p>
                          )}
                        </ul>
                      </div> */}
                      {starTag && starTag.length > 0 ? (
                        starTag?.slice(0, 15).map((item) => {
                          return (
                            <>
                              <div className="star-main-submenu">
                                <div className="star-submenu-1">
                                  <img
                                    src={
                                      "https://fanzat-news.onrender.com" +
                                      item?.image?.url
                                    }
                                    // src={data?.images?.path ? data?.image?.path : "../assets/img/profile-img.png"}
                                    className="product-img"
                                    alt="image"
                                  />
                                  <h4> {item?.starTags?.french}</h4>
                                </div>
                              </div>
                            </>
                          );
                        })
                      ) : (
                        <p>Aucune étiquette étoile trouvée</p>
                      )}
                    </div>
                    {starTag && starTag.length > 0 && (
                      <div className="show-more-star-tag">
                        <Link to={"/fr/star-tags"}>
                          <i class="fas fa-hand-point-right"></i> Afficher plus
                        </Link>
                      </div>
                    )}

                    {/* <div className="star-main-submenu">
                        <div className="star-submenu-1">
                          <img
                            src="https://new.axilthemes.com/demo/react/blogar-rtl/images/posts/category/seo.webp"
                            alt=""
                          />
                          <p>Name</p>
                        </div>
                        <div className="star-submenu-1">
                          <img
                            src="https://new.axilthemes.com/demo/react/blogar-rtl/images/posts/category/seo.webp"
                            alt=""
                          />
                          <p>Name</p>
                        </div>
                        <div className="star-submenu-1">
                          <img
                            src="https://new.axilthemes.com/demo/react/blogar-rtl/images/posts/category/seo.webp"
                            alt=""
                          />
                          <p>Name</p>
                        </div>
                        <div className="star-submenu-1">
                          <img
                            src="https://new.axilthemes.com/demo/react/blogar-rtl/images/posts/category/seo.webp"
                            alt=""
                          />
                          <p>Name</p>
                        </div>
                      </div>
                      <div className="star-main-submenu star-main-submenu2">
                        <div className="star-submenu-1">
                          <img
                            src="https://new.axilthemes.com/demo/react/blogar-rtl/images/posts/category/seo.webp"
                            alt=""
                          />
                          <p>Name</p>
                        </div>
                        <div className="star-submenu-1">
                          <img
                            src="https://new.axilthemes.com/demo/react/blogar-rtl/images/posts/category/seo.webp"
                            alt=""
                          />
                          <p>Name</p>
                        </div>
                        <div className="star-submenu-1">
                          <img
                            src="https://new.axilthemes.com/demo/react/blogar-rtl/images/posts/category/seo.webp"
                            alt=""
                          />
                          <p>Name</p>
                        </div>
                        <div className="star-submenu-1">
                          <img
                            src="https://new.axilthemes.com/demo/react/blogar-rtl/images/posts/category/seo.webp"
                            alt=""
                          />
                          <p>Name</p>
                        </div>
                      </div> */}
                  </div>
                </li>

                {/* <li>Rapports </li>
                <li>Événements </li> */}

                {/* <li>
                  Posts
                  <div className="mega_subMenu" style={{ left: "-120px" }}>
                    <ul>
                      <li>Standard</li>
                      <li>Video</li>
                      <li>Gallery</li>
                      <li>Audio</li>
                      <li>Quote</li>
                    </ul>
                  </div>
                </li> */}
                {/* <li>Home</li> */}
              </ul>
            </div>
            <div className="main_headerR">
              <div className="search_bar">
                <input
                  type="search"
                  placeholder="Recherche..."
                  onChange={onChangeSearch}
                  style={{ textAlign: "left" }}
                />
              </div>
              {search && (
                <Link
                  className="search_Soumettre"
                  to={`/fr/search-post?param1=${myStateData.key1}`}
                >
                  Recherche <i className="fas fa-search"></i>
                </Link>
              )}
              <div className="account_ico">
                <i class="fas fa-bell"></i>
                <i class="fas fa-bookmark"></i>
                <img src={Author} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeaderFr;
