import React, { useState, useEffect } from "react";
import DataService from "../services/data.service";

import Header from "../Common/Header";
import Footer from "../Common/Footer";
import "../CSS/Posts.css";
import ikj from "../images/ikj.jpg";
import { ToastContainer, toast } from "react-toastify";
import NewsLetter from "../Common/NewsLetter";

function Posts() {
  // const [news, setNews] = useState("");

  // const getAllNews = async () => {
  //   await DataService.getAllNews().then((data) => {
  //     setNews(data?.data?.data);
  //     // setNewsL1(data?.data?.data?.[0]);
  //   });
  // };
  // useEffect(() => {
  //   document.title = "All Post";
  //   window.scrollTo(0, 0);
  //   getAllNews();
  // }, []);
  const [news, setNews] = useState("");
  const [fnews, setFNews] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [TotalPostsCount, setTotalPostsCount] = useState("");
  const postsPerPage = 100; // Number of posts per page
  const [masterCategory, setMasterCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getAllLNews = async () => {
      await DataService.getAllNews().then((data) => {
        setNews(data?.data?.data?.posts);
        setTotalPostsCount(data?.data?.data?.totalPostsCount);
      });
    };
    getAllLNews();
    getAllGNews(currentPage);
    document.title = "All Posts";
    window.scrollTo(0, 0);
  }, []);

  const getAllGNews = async (newPage) => {
    setLoading(true);
    await DataService.getAllPNews(newPage).then((data) => {
      setFNews(data?.data?.data?.posts);
      setLoading(false);
    });
  };

  const totalPages = Math.ceil(TotalPostsCount / postsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    getAllGNews(newPage);
  };

  const visiblePageButtons = 5;

  const renderPageButtons = () => {
    const pageButtons = [];
    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 ||
        (i >= currentPage - Math.floor(visiblePageButtons / 2) &&
          i <= currentPage + Math.floor(visiblePageButtons / 2))
      ) {
        pageButtons.push(
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={currentPage === i ? "active-btn" : ""}
          >
            {i}
          </button>
        );
      }
    }
    return pageButtons;
  };
  const getAllCategory = async () => {
    await DataService.getAllCategory(1).then((data) => {
      // console.log(data?.data?.categories)
      setMasterCategory(data?.data?.categories);
    });
    await DataService.getAllCategory(2).then((data) => {
      // console.log(data?.data?.categories)
      setSubCategory(data?.data?.categories);
    });
  };
  useEffect(() => {
    getAllCategory();
  }, []);


  return (
    <>
      <Header />
      <div className="main-banner-post">
        <div className="banner-post-content">
          <h1>All Posts</h1>
        </div>
      </div>

      <div className="section3-post-page">
        <div className="container">
          {loading && (
            <div className="loader-spin">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          )}
          <div className="related-post-bottom">
            <h2>All Posts</h2>
            <div className="related-post-Bbox">
              {fnews && fnews.length > 0 ? (
                fnews
                  .filter((item) => item.status === "publish")
                  .map((item, index) => {
                    const postDate = new Date(item.post_date);
                    const formattedDate = `${postDate.toLocaleDateString()} ${postDate.toLocaleTimeString()}`;
                    return (
                      <>
                        <div className="related-post-Bcolumn related-post-bcolumn3">
                          <a href={"/single-blog-post/" + item._id}>
                            <div className="Bcolumn-img">
                              <img
                                src={
                                  "https://fanzat-news.onrender.com" +
                                  item?.featured_media?.source_url
                                }
                                className="product-img"
                                alt="image"
                              />
                            </div>
                            <div className="all-post-category1">
                              {item?.categories
                                .filter((category) => category.type === "1")
                                .map((category) => (
                                  <h6>{category.name?.english}</h6>
                                ))}
                            </div>
                            <div className="Bcolumn-content">
                              {/* <h4> {item?.post_title?.english}</h4> */}
                              <p>
                                {item?.title?.english
                                  .split(" ")
                                  .slice(0, 12)
                                  .join(" ")}
                              </p>
                              {/* <div className="Bcolumn-btn">
                              <a href={"/single-blog-post/" + item._id}>
                                {" "}
                                <button>Read More</button>{" "}
                              </a>
                            </div> */}
                            </div>
                          </a>
                        </div>
                      </>
                    );
                  })
              ) : (
                <p>No Blogs Found</p>
              )}
            </div>
            {/* <div className="view-more-blogs" style={{textAlign:'center', marginTop: '20px'}}>
                <button class="main-btn" disabled={dataB && dataB.length===count ? true : false} onClick={handleViewMore}>
                <button className="main-btn">View More</button>
              </div> */}
            <div className="show-more-posts-btn">
              {currentPage > 1 && (
                <button onClick={() => handlePageChange(currentPage - 1)}>
                  {"< Previous"}
                </button>
              )}
              {renderPageButtons()}
              {currentPage < totalPages && (
                <button onClick={() => handlePageChange(currentPage + 1)}>
                  {"Next >"}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="post-section4">
        <div className="container">
          <h2>All Category</h2>
          <div className="post-sec4-allcategory">
            <div className="sec4-allCat-btn">
              {subCategory && subCategory.length > 0 ? (
                subCategory.map((item, index) => {
                  if (item?.name?.english !== "") {
                    return (
                      <>
                        <a href={`/post-by-category/${item.id}`}>
                          <button> {item?.name?.english}</button>
                        </a>
                      </>
                    );
                  } else {
                    return null;
                  }
                })
              ) : (
                <p>Not Category Found</p>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* <div className="related-post-Bcolumn related-post-bcolumn3">
                <div className="Bcolumn-img">
                  <img src="https://news.fanzat.com/wp-content/uploads/2019/12/NNSM3.jpg" />
                </div>
                <div className="all-post-category1">
                  <h6>Lorem</h6>
                </div>
                <div className="Bcolumn-content">
                  <h4>Lorem ipsum</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor
                  </p>
                  <div className="Bcolumn-btn">
                    <button>Read More</button>
                  </div>
                </div>
              </div>
              <div className="related-post-Bcolumn related-post-bcolumn3">
                <div className="Bcolumn-img">
                  <img src="https://news.fanzat.com/wp-content/uploads/2019/12/NNSM3.jpg" />
                </div>
                <div className="all-post-category1">
                  <h6>Lorem</h6>
                </div>
                <div className="Bcolumn-content">
                  <h4>Lorem ipsum</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor
                  </p>
                  <div className="Bcolumn-btn">
                    <button>Read More</button>
                  </div>
                </div>
              </div>
              <div className="related-post-Bcolumn related-post-bcolumn3">
                <div className="Bcolumn-img">
                  <img src="https://news.fanzat.com/wp-content/uploads/2019/12/NNSM3.jpg" />
                </div>
                <div className="all-post-category1">
                  <h6>Lorem</h6>
                </div>
                <div className="Bcolumn-content">
                  <h4>Lorem ipsum</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor
                  </p>
                  <div className="Bcolumn-btn">
                    <button>Read More</button>
                  </div>
                </div>
              </div>
              <div className="related-post-Bcolumn related-post-bcolumn3">
                <div className="Bcolumn-img">
                  <img src="https://news.fanzat.com/wp-content/uploads/2019/12/NNSM3.jpg" />
                </div>
                <div className="all-post-category1">
                  <h6>Lorem</h6>
                </div>
                <div className="Bcolumn-content">
                  <h4>Lorem ipsum</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor
                  </p>
                  <div className="Bcolumn-btn">
                    <button>Read More</button>
                  </div>
                </div>
              </div>
              <div className="related-post-Bcolumn related-post-bcolumn3">
                <div className="Bcolumn-img">
                  <img src="https://news.fanzat.com/wp-content/uploads/2019/12/NNSM3.jpg" />
                </div>
                <div className="all-post-category1">
                  <h6>Lorem</h6>
                </div>
                <div className="Bcolumn-content">
                  <h4>Lorem ipsum</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor
                  </p>
                  <div className="Bcolumn-btn">
                    <button>Read More</button>
                  </div>
                </div>
              </div>
              <div className="related-post-Bcolumn related-post-bcolumn3">
                <div className="Bcolumn-img">
                  <img src="https://news.fanzat.com/wp-content/uploads/2019/12/NNSM3.jpg" />
                </div>
                <div className="all-post-category1">
                  <h6>Lorem</h6>
                </div>
                <div className="Bcolumn-content">
                  <h4>Lorem ipsum</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor
                  </p>
                  <div className="Bcolumn-btn">
                    <button>Read More</button>
                  </div>
                </div>
              </div> */}
      <NewsLetter />

      <Footer />
    </>
  );
}

export default Posts;
