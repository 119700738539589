import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import DataService from "../services/data.service";
import NewsLetterEs from "../Common/NewsLetterEs";
import FooterEs from "../Common/FooterEs";
import HeaderEs from "../Common/HeaderEs";

function PostsByStarEs() {
  const params = useParams();

  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(false);
  const [email, setEmail] = useState("");


  useEffect(() => {
    document.title = "Post By Star Tag";
    window.scrollTo(0, 0);
    getPostStartags();
  }, []);

  const getPostStartags = async () => {
    setLoading(true);
    await DataService.getPostStarIdEs(params.id).then(
      (data) => {
        setData(data?.data?.data);
        setLoading(false);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);

        setMessage(resMessage);
        // toast.error(resMessage, {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
      }
    );
  };

  return (
    <>
      <HeaderEs />
      <div className="section3-post-page">
        <div className="container">
          {loading && (
            <div className="loader-spin">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          )}
          <div className="related-post-bottom post-by-starTag-sec">
            <h2>All Post</h2>

            {/* <div className="error-msg-starpost">
              <h2>{message}</h2>
            </div> */}

            <div className="related-post-Bbox">
              {data && data.length > 0 ? (
                data
                  .filter(
                    (item) => item.status === "publish"
                    // item.status === "publish" &&
                    // item?._id !== data?._id
                  )
                  .map((item, index) => {
                    const postDate = new Date(item.date);
                    const formattedDate = `${postDate.toLocaleDateString()} ${postDate.toLocaleTimeString()}`;
                    return (
                      <>
                        <div className="related-post-Bcolumn post-star-tag-sec">
                          <a href={"/es/single-blog-post/" + item._id}>
                            <div className="Bcolumn-img">
                              <img
                                src={
                                  "https://fanzat-news.onrender.com" +
                                  item?.featured_media?.source_url
                                }
                                className="product-img"
                                alt="image"
                              />
                            </div>
                            <div className="Bcolumn-content">
                              <h4>
                                {" "}
                                {item?.title?.english
                                  ?.split(" ")
                                  .slice(0, 8)
                                  .join(" ")}
                              </h4>

                              <p
                                dangerouslySetInnerHTML={{
                                  __html: item.content?.english
                                    ?.split(" ")
                                    .slice(0, 12)
                                    .join(" "),
                                }}
                              ></p>
                            </div>
                          </a>
                        </div>
                      </>
                    );
                  })
              ) : (
                <p>Not Post Found</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <NewsLetterEs />
      <FooterEs />
    </>
  );
}

export default PostsByStarEs;
