import React, { useState, useEffect } from "react";
import "../CSS/TermsOfUse.css";

import DataService from "../services/data.service";
import NewsLetterFr from "../Common/NewsLetterFr";
import FooterFr from "../Common/FooterFr";
import HeaderFr from "../Common/HeaderFr";

function TermsOfUseFr() {
  const [data, setData] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Terms of Use";
    getData();
  }, []);
  const getData = async () => {
    await DataService.getTermsUsedFr().then((data) => {
      setData(data?.data?.data[0]);
    });
  };
  return (
    <>
      <HeaderFr/>
      <div className="banner-sec-Terms">
        <div className="banner-T-tittle">
          <h1>{data?.title?.french}</h1>
        </div>
      </div>
      <div className="top-sec-privacy">
        <div className="container">
          <div className="main-content-privacy">
            <p
              className="post-content-para"
              dangerouslySetInnerHTML={{
                __html: data?.description?.french,
              }}
            ></p>
          </div>
        </div>
      </div>
  <NewsLetterFr/>
  <FooterFr/>
    </>
  );
}

export default TermsOfUseFr;
