import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import DataService from "../services/data.service";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import { Link } from "react-router-dom";
import SharePost from "../Common/SharePost";
import { ToastContainer, toast } from "react-toastify";
import NewsLetter from "../Common/NewsLetter";

const PostByCategory = () => {
  const params = useParams();
  const [news, setNewsC] = useState([]);
  const [category, setCategory] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [TotalPostsCount, setTotalPostsCount] = useState("");
  const postsPerPage = 20;
  const [fnews, setFNews] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    document.title = "Post By Category";
    window.scrollTo(0, 0);
    getBCategoryBy();
  }, []);
  const getBCategoryBy = async (e) => {
    await DataService.getCategoryBy(params.id).then((data) => {
      setCategoryName(data?.data?.data?.name?.english);
    });
  };

  useEffect(() => {
    const getCategoryBy = async (e) => {
      await DataService.getACategoryById(params.id).then(
        (data) => {
          setNewsC(data?.data?.data?.posts);
          setTotalPostsCount(data?.data?.data?.totalCategoryPostsCount);
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.error) ||
            error.error ||
            error.toString();
          setLoading(false);
          setMessage(resMessage);
          // toast.error(resMessage, {
          //   position: toast.POSITION.TOP_RIGHT,
          // });
        }
      );
    };
    getCategoryBy();
    getACategoryBy(currentPage);
  }, []);

  const getACategoryBy = async (newPage) => {
    setLoading(true);

    await DataService.getRelatedPost(params.id, newPage).then(
      (data) => {
        setFNews(data?.data?.data?.posts);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.error) ||
          error.error ||
          error.toString();
        setLoading(false);
        setMessage(resMessage);
        // toast.error(resMessage, {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
      }
    );
  };

  const totalPages = Math.ceil(TotalPostsCount / postsPerPage);
  const visiblePageButtons = 5;

  const renderPageButtons = () => {
    const pageButtons = [];
    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 ||
        (i >= currentPage - Math.floor(visiblePageButtons / 2) &&
          i <= currentPage + Math.floor(visiblePageButtons / 2))
      ) {
        pageButtons.push(
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={currentPage === i ? "active-btn" : ""}
          >
            {i}
          </button>
        );
      }
    }
    return pageButtons;
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    getACategoryBy(newPage);
  };

  return (
    <>
      <Header />
      <div className="main-banner-post">
        <div className="banner-post-content">
          <h1 style={{ fontSize: "18px" }}>All Posts of / {categoryName}</h1>
        </div>
      </div>
      <div className="container">
        <div className="heading_text">
          <h2 style={{ fontSize: "16px", marginTop: "20px" }}>
            Sub Catgegory / {categoryName}
          </h2>
          <div className="error-msg-starpost">
            <h2>{message}</h2>
          </div>
        </div>
        <div className="posts_wFlex" style={{ marginTop: "20px" }}>
          <div className="posts_wFlexL">
            {fnews && fnews.length > 0 ? (
              fnews
                .filter((item) => item?.status === "publish")

                .map((item, index) => {
                  const postDate = new Date(item.date);
                  const formattedDate = `${postDate.toLocaleDateString()} ${postDate.toLocaleTimeString()}`;
                  return (
                    <>
                      <a href={"/single-blog-post/" + item._id}>
                        <div className="main_postSec">
                          <div className="main_postFlex">
                            <div className="main_postContent">
                              <a class="hover-flip-item-wrapper" href="/">
                                <span class="hover-flip-item">
                                  {/* <span data-text="SEO"> {item?.category[0]?.name?.english}</span> */}
                                  {/* {item?.category[0]?.name?.english} */}
                                </span>
                              </a>
                              <div className="post_titleSec">
                                <h3 className="post_title">
                                  {item?.title?.english
                                    ?.split(" ")
                                    .slice(0, 10)
                                    .join(" ")}
                                </h3>
                              </div>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: item?.content?.english
                                    ?.split(" ")
                                    .slice(0, 12)
                                    .join(" "),
                                }}
                              ></p>
                              <div className="main_postMeta">
                                <div className="main_postMetaR">
                                  <SharePost
                                    title={item?.title?.arabic}
                                    url={`https://fanzat-news-arabic.vercel.app/single-blog-post/${item?._id}`}
                                  />
                                </div>
                                <div className="main_postMetaL">
                                  {/* <h6>{item?.user_ID?.user_nicename}</h6> */}
                                  <span>{formattedDate} </span>
                                </div>
                              </div>
                            </div>
                            <div className="main_postThumb">
                              <img
                                src={
                                  "https://fanzat-news.onrender.com" +
                                  item?.featured_media?.source_url
                                }
                                className="product-img"
                                alt="image"
                              />
                            </div>
                          </div>
                        </div>
                      </a>
                    </>
                  );
                })
            ) : (
              <p>No Blogs Found</p>
            )}
            <div className="show-more-posts-btn">
              {currentPage > 1 && (
                <button onClick={() => handlePageChange(currentPage - 1)}>
                  {"< Previous"}
                </button>
              )}
              {renderPageButtons()}
              {currentPage < totalPages && (
                <button onClick={() => handlePageChange(currentPage + 1)}>
                  {"Next >"}
                </button>
              )}
            </div>
          </div>
          <div className="posts_wFlexR">
            {/* <div className="popular_onSite">
                <h5>Popular on Blogar</h5>
                <hr />
                <div className="popular_innerSec">
                  {news && news.length > 0 ? (
                    news.filter((item) => item.post_status === "publish")
                     .map((item, index) => {
                        const postDate = new Date(item.post_date);
                        const formattedDate = `${postDate.toLocaleDateString()} ${postDate.toLocaleTimeString()}`;
                        return (
                          <>
                            <Link to={"/single-blog-post/" + item._id}>
                              <div className="pop_postFlex">
                                <div className="pop_postFlexL">
                                  <img
                                    src={
                                      "https://fanzat-news.onrender.com/" +
                                      item?.images[0]?.path
                                    }
                                    // src={data?.images?.path ? data?.image?.path : "../assets/img/profile-img.png"}
                                    className="product-img"
                                    alt="Featured Image"
                                  />
                                </div>
                                <div className="pop_postFlexR">
                                  <div className="post_titleSec">
                                    <h3 className="post_title">
                                      {item?.post_title?.english}
                                    </h3>
                                  </div>
                                  <span>{formattedDate}</span>
                                </div>
                              </div>
                            </Link>
                          </>
                        );
                      })
                  ) : (
                    <p>No Blogs Found</p>
                  )}
                
                </div>
              </div> */}
            <div className="social_links">
              <h5>Stay In Touch</h5>
              <hr />
              <i class="fab fa-linkedin-in"></i>
              <i class="fab fa-slack"></i>
              <i class="fab fa-twitter"></i>
              <i class="fab fa-instagram"></i>
              <i class="fab fa-facebook-f"></i>
            </div>

            <div className="fanzat_stars">
              <h5>Fanzat Stars</h5>
              <hr />
              <iframe src="https://nnsm3.com/" title="fanzat stars"></iframe>
            </div>
          </div>
        </div>
      </div>
      <NewsLetter />

      <Footer />
    </>
  );
};

export default PostByCategory;
