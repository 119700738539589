import React, { useState, useEffect } from "react";

import "../CSS/HomeAr.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import DataService from "../services/data.service";
import Group from "../images/group-cheerl.png";
import SharePost from "../Common/SharePost";
import { useNavigate, useParams } from "react-router-dom";
// import background from "../images/backgroung-img.jpeg";
import { ToastContainer, toast } from "react-toastify";
import video1 from "../Videos/VID-20220709-WA0002.mp4";
import video2 from "../Videos/VID-20220709-WA0046.mp4";
import video3 from "../Videos/VID-20220711-WA0002.mp4";
import video4 from "../Videos/VID-20220711-WA0003.mp4";
import video5 from "../Videos/VID-20220711-WA0004.mp4";
import video6 from "../Videos/VID-20220711-WA0006.mp4";
import HeaderAr from "../Common/HeaderAr";
import NewsLetterAr from "../Common/NewsLetterAr";
import FooterAr from "../Common/FooterAr";

const HomeAr = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const params = useParams();

  const [news, setNews] = useState("");
  const [newsR, setNewsR] = useState("");
  const [Anews, setANews] = useState("");
  const [mpNews, setMPNews] = useState("");
  const [mpNews1, setMPNews1] = useState("");
  const [starTag, setStarTag] = useState("");
  const [tag, setTag] = useState("");
  const [mostPTag, setMostPTag] = useState("");
  const [latestPost, setLatestPost] = useState("");
  const [masterCategory, setMasterCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [parentId, setParentId] = useState([]);
  const [Rmaster, setRmaster] = useState([]);
  const [activeButton, setActiveButton] = useState(null);
  const [starData, setStarData] = useState("");
  const [starId, setStarId] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const getAllCategory = async () => {
    await DataService.getAllCategory(1).then((data) => {
      setMasterCategory(data?.data?.categories);
    });
    await DataService.getAllCategory(2).then((data) => {
      setSubCategory(data?.data?.categories);
    });
    // await DataService.getAllCategory(2).then((data) => {
    //   // console.log(data?.data?.categories)
    //   setSubCategory(data?.data?.categories);
    //   const firstCategory = data.data.categories;
    //   if (firstCategory) {
    //     // console.log(firstCategory.parentId)
    //     setParentId(firstCategory.parentId);
    //   }
    // });
  };
  useEffect(() => {
    document.title = "News";
    window.scrollTo(0, 0);
    getAllCategory();
  }, []);

  const getAllNews = async () => {
    setLoading(true);
    await DataService.getAllNewsAr().then((data) => {
      setNews(data?.data?.data?.posts);
      setNewsR(data?.data?.data?.posts);
      setLoading(false);
    });
  };
  const getAllPopularNewsNews = async () => {
    await DataService.getAllPopularNewsAr().then((data) => {
      setANews(data?.data?.data);
      setMPNews(data?.data?.data);
      setMPNews1(data?.data?.data);
    });
  };

  // const getAllLatestPosts = async () => {
  //   await DataService.getAllLatestPost().then((data) => {
  //     setLatestPost(data?.data?.data);
  //   });
  // };
  const getAllStarTag = async () => {
    await DataService.getAllStarTagsAr().then((data) => {
      setStarTag(data?.data?.data);
      // setStarId(data?.data?.data?.TermID);
    });
  };

  useEffect(() => {
    getAllNews();
    getAllPopularNewsNews();
    getAllStarTag();
    getAllTags();
    getAllMostTags();
    // getPostStartags();
    // getAllLatestPosts();
  }, []);

  useEffect(() => {
    if (masterCategory && masterCategory.length > 0) {
      getRelatedmasterCats(masterCategory[0]?.id);
    }
  }, [masterCategory]);

  const getRelatedmasterCats = async (itemId) => {
    await DataService.getRelatedmasterCatAr(itemId).then((data) => {
      setRmaster(data?.data?.data);
    });
  };

  // const hndleFun = (itemId) => {
  //   // console.log(`Handling action for item with id: ${itemId}`);
  // };
  const getAllTags = async () => {
    await DataService.getAllTagAr().then((data) => {
      setTag(data?.data?.data?.tags);
    });
  };
  const getAllMostTags = async () => {
    await DataService.getAllMostTag().then((data) => {
      setMostPTag(data?.data?.data);
    });
  };

  useEffect(() => {
    if (masterCategory.length > 0) {
      setActiveButton(masterCategory[0].id);
    }
  }, [masterCategory]);

  const handleButtonClick = (id) => {
    setActiveButton(id);
    getRelatedmasterCats(id);
  };

  return (
    <>
      <HeaderAr />
      <section className="top_sec1">
        <div className="container">
          <div className="container">
            {loading && (
              <div className="loader-spin">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            )}
            <div className="top_flex1">
              <div className="top_flex1R1">
                {newsR && newsR?.length > 0 ? (
                  newsR
                    .filter((item) => item?.status === "publish")
                    .slice(6, 7)
                    .map((item, index) => {
                      const postDate = new Date(item?.date);
                      const formattedDate = `${postDate.toLocaleDateString()} ${postDate.toLocaleTimeString()}`;
                      return (
                        <>
                          <Link to={"/ar/single-blog-post/" + item._id}>
                            <div className="top_mainP1">
                              <div className="top_mainThumb1">
                                <img
                                  src={
                                    "https://fanzat-news.onrender.com" +
                                    item?.featured_media?.source_url
                                  }
                                  className="product-img"
                                  alt="image"
                                />
                                {/* <img src={item?.featured_media?.source_url} /> */}
                              </div>
                              <div className="top_mainP1D1">
                                <span className="top_mainP1D1Cat1">
                                  {" "}
                                  {item?.category?.name?.arabic}
                                </span>
                                <div className="post_title1Sec11">
                                  <h3 className="post_title1">
                                    {item?.title?.arabic
                                      ?.split(" ")
                                      .slice(0, 15)
                                      .join(" ")}
                                  </h3>
                                </div>
                                <div className="main_postMeta1 ">
                                  <div className="main_postMeta1R1 main-postmeta111">
                                    <SharePost
                                      title={item?.title?.arabic}
                                      url={`https://fanzat-news-arabic.vercel.app/single-blog-post/${item?._id}`}
                                    />
                                    {/* <SharePost
                                      url={`https://fanzat-news-arabic.vercel.app/single-blog-post/${params.id}`}
                                      title={item?.title?.arabic}
                                      imageUrl={
                                        "https://fanzat-news.onrender.com" +
                                        item?.featured_media?.source_url
                                      }
                                      description={item?.content?.arabic}
                                    /> */}
                                  </div>
                                  {/* <div className="main_postMeta1L1">
                                <div>
                                  <img
                                    src={
                                      "https://fanzat-news.onrender.com/" +
                                      item?.user_ID?.images?.path
                                    }
                                    // src={data?.images?.path ? data?.image?.path : "../assets/img/profile-img.png"}
                                    className="product-img"
                                    alt="image"
                                  />
                                </div>
                                <div>
                                  <h6>{item?.author}</h6>
                                  <span>{formattedDate}</span>
                                </div>
                              </div> */}
                                </div>
                              </div>
                            </div>
                          </Link>
                        </>
                      );
                    })
                ) : (
                  <p>لا توجد أخبار حاليا </p>
                )}
              </div>
              <div className="top_flex1L1">
                {news && news.length > 0 ? (
                  news
                    .filter((item) => item?.status === "publish")
                    .slice(0, 5)
                    .map((item, index) => {
                      const postDate = new Date(item.date);
                      const formattedDate = `${postDate.toLocaleDateString()} ${postDate.toLocaleTimeString()}`;
                      return (
                        <>
                          <Link to={"/ar/single-blog-post/" + item._id}>
                            <div key={index} className="top_mainP1ost">
                              <div className="top_mainP1ostR1">
                                <img
                                  src={
                                    "https://fanzat-news.onrender.com" +
                                    item?.featured_media?.source_url
                                  }
                                  className="product-img"
                                  alt="image"
                                />
                                {/* <img src={item?.featured_media?.source_url} /> */}
                              </div>
                              <div className="top_mainP1ostL11">
                                <span className="top_mainP1ostCat">
                                  {item?.category?.name?.arabic}
                                </span>
                                <div className="post_title1Sec11">
                                  <h3 className="post_title1">
                                    {/* {item?.post_title1?.arabic} */}
                                    {/* {item?.title?.rendered} */}
                                    {item?.title?.arabic
                                      ?.split(" ")
                                      .slice(0, 10)
                                      .join(" ")}
                                  </h3>
                                  <p>{formattedDate}</p>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </>
                      );
                    })
                ) : (
                  <p>لا توجد أخبار حاليا </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="banner_sec">
        <div className="container">
          <div className="banner_flex">
            <div className="bannerL">
              <div className="slide_inner">
                <img
                  src="https://new.axilthemes.com/demo/react/blogar/images/posts/thumbnail-08.webp"
                  alt=""
                />
                <div className="slide_descSec">
                  <span className="category_sec">Celebrity News</span>
                  <div className="post_title1Sec11">
                    <h3 className="post_title1">
                      The world’s most powerful woman, Oprah Winfrey, has been
                      named the highest-paid celebrity in the world.
                    </h3>
                  </div>
                  <div className="meta_flex">
                    <span>
                      <i class="far fa-calendar-alt"></i> 09 Nov. 2023
                    </span>{" "}
                    |{" "}
                    <span>
                      <i class="far fa-eye"></i> 167
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="bannerM">
              <div className="slide_inner top_slideInner">
                <img
                  src="https://new.axilthemes.com/demo/react/blogar/images/posts/thumbnail-05.webp"
                  alt=""
                />
                <div className="slide_descSec">
                  <span className="category_sec">Corporate News</span>
                  <div className="post_title1Sec11">
                    <h3 className="post_title1">
                      The world’s most powerful woman, Oprah Winfre.
                    </h3>
                  </div>
                  <div className="meta_flex">
                    <span>
                      <i class="far fa-calendar-alt"></i> 09 Nov. 2023
                    </span>{" "}
                    |{" "}
                    <span>
                      <i class="far fa-eye"></i> 167
                    </span>
                  </div>
                </div>
              </div>
              <div className="slide_inner top_slideInner">
                <img
                  src="https://new.axilthemes.com/demo/react/blogar/images/posts/post-tech-10.webp"
                  alt=""
                />
                <div className="slide_descSec">
                  <span className="category_sec">Corporate News</span>
                  <div className="post_title1Sec11">
                    <h3 className="post_title1">
                      The world’s most powerful woman, Oprah Winfre.
                    </h3>
                  </div>
                  <div className="meta_flex">
                    <span>
                      <i class="far fa-calendar-alt"></i> 09 Nov. 2023
                    </span>{" "}
                    |{" "}
                    <span>
                      <i class="far fa-eye"></i> 167
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="ad_sec1">
        <div className="container">
          <div className="ad_mainSec1">
            <img
              src="https://new.axilthemes.com/demo/react/blogar/images/add-banner/banner-03.webp"
              alt=""
            />
          </div>
        </div>
      </section>
      <section className="register_sec1">
        <div className="container">
          <div className="register_flex1">
            <div className="register_inner1">
              <h5>هل أنت مؤثر مشهور؟</h5>
              <img
                src="https://news.fanzat.com/wp-content/themes/nnsm3-news/images/img-teams.png"
                alt=""
              />
              <p>ابدأ رحلتك في ننسمع وحقق أعلى مستويات الوصول</p>
              <a href="https://nnsm3.com/register/star">
                <button className="btn-fix-bottom1">سجل كنجم</button>
              </a>
            </div>
            <div className="register_inner1">
              <h5>هل لديك تأثيراً حقيقياً ومميزاً؟</h5>
              <img
                src="https://news.fanzat.com/wp-content/themes/nnsm3-news/images/info-2.png"
                alt=""
              />
              <p>
                استخدام نفوذك المميز في مجالك؛ وحقق مع ننسمع نتائج فوق مستوى
                التوقعات
              </p>
              <a href="https://nnsm3.com/register/pulsar">
                <button className="btn-fix-bottom1">تسجيل البولسار</button>
              </a>
            </div>
            <div className="register_inner1">
              <h5>علامة تجارية / شركة ؟</h5>
              <img
                src="https://news.fanzat.com/wp-content/themes/nnsm3-news/images/info-3.png"
                alt=""
              />
              <p>
                اختر المؤثر المناسب لمجالك، وتتبع حملاتك الناجحة على منصة واحدة
                (ننسمع)
              </p>
              <a href="https://nnsm3.com/register/orbit">
                <button className="btn-fix-bottom1">تسجيل الأفلاك</button>
              </a>
            </div>

            <div className="register_inner1">
              <h5>هل أنت فان</h5>
              <img src={Group} alt="" />
              <p>Lore ipsum is the dummy text standard industry</p>
              <Link to={"/ar"}>
                <button className="btn-fix-bottom1">سجل كفان </button>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="tab_sliderSec1">
        <div className="container">
          <div className="tab_slideMain1">
            <h2>أحدث الأخبار</h2>
            <div className="button_flex1">
              {masterCategory && masterCategory.length > 0 ? (
                masterCategory?.map((item) => {
                  return (
                    <>
                      {/* <button
                        // style={{ background: "#fff" }}
                        onClick={() => {
                          // hndleFun(item.id);
                          getRelatedmasterCats(item.id);
                        }}
                      >
                        {item?.name?.arabic}
                      </button> */}
                      <button
                        key={item.id}
                        onClick={() => handleButtonClick(item.id)}
                        className={
                          activeButton === item.id ? "activeButton" : ""
                        }
                      >
                        {item?.name?.arabic}
                      </button>
                    </>
                  );
                })
              ) : (
                <p>لا توجد أقسام حاليا </p>
              )}
            </div>

            {/* <div className="slide_flex"> */}
            <Slider {...settings}>
              {Rmaster && Rmaster.length > 0 ? (
                Rmaster?.filter((item) => item?.status === "publish")
                  .slice(0, 15)
                  .map((item) => {
                    return (
                      <>
                        <div className="slide_item1">
                          <Link to={"/ar/single-blog-post/" + item._id}>
                            <p> {item?.category?.name?.arabic}</p>
                            <h4>
                              {" "}
                              {item?.title?.arabic
                                ?.split(" ")
                                .slice(0, 8)
                                .join(" ")}
                            </h4>
                            <img
                              src={
                                "https://fanzat-news.onrender.com" +
                                item?.featured_media?.source_url
                              }
                              className="product-img"
                              alt="image"
                            />
                          </Link>
                        </div>
                      </>
                    );
                  })
              ) : (
                <p>لا توجد أخبار حاليا </p>
              )}
            </Slider>
            {/* </div> */}
          </div>
        </div>
      </section>
      <section
        className="tab_sliderSec1"
        style={{
          padding: "50px 0px",
          background: "#F0F2F5",
          marginBottom: "0px",
        }}
      >
        <div className="container">
          <div className="tab_slideMain1">
            <h2>الأكثر شهرة</h2>
            {/* <div className="button_flex1">
              <button style={{ background: "#fff" }}>المؤثرون</button>
              <button style={{ background: "#fff" }}>العلامات التجارية</button>
              <button style={{ background: "#fff" }}>فانزات</button>
            </div> */}
            <div className="middle_mainSec1">
              {mpNews && mpNews.length > 0 ? (
                mpNews
                  .filter((item) => item?.status === "publish")
                  .slice(0, 1)
                  .map((item, index) => {
                    const postDate = new Date(item.date);
                    const formattedDate = `${postDate.toLocaleDateString()} ${postDate.toLocaleTimeString()}`;

                    return (
                      <>
                        <div className="middle_mainSec1L1">
                          <Link to={"/ar/single-blog-post/" + item._id}>
                            <div className="top_mainP1">
                              <div className="top_mainThumb1">
                                {/* <img src={item?.featured_media?.source_url} /> */}
                                <img
                                  src={
                                    "https://fanzat-news.onrender.com" +
                                    item?.featured_media?.source_url
                                  }
                                  className="product-img"
                                  alt="image"
                                />
                              </div>
                              <div className="top_mainP1D1">
                                <span className="top_mainP1D1Cat1">
                                  {" "}
                                  {item?.category?.name?.arabic}
                                </span>
                                <div className="post_title1Sec11">
                                  <h3 className="post_title1">
                                    {item?.title?.arabic
                                      ?.split(" ")
                                      .slice(0, 15)
                                      .join(" ")}
                                  </h3>
                                </div>
                                {/* <div className="main_postMeta1"> */}
                                <div className="main_postMeta1R1">
                                  <SharePost
                                    title={item?.title?.arabic}
                                    url={`https://fanzat-news-arabic.vercel.app/single-blog-post/${item?._id}`}
                                    // content={item?.content?.arabic}
                                  />
                                  {/* <SharePost
                                    url={`https://fanzat-news-arabic.vercel.app/single-blog-post/${params.id}`}
                                    title={item?.title?.arabic}
                                    imageUrl={
                                      "https://fanzat-news.onrender.com" +
                                      item?.featured_media?.source_url
                                    }
                                    description={item?.content?.arabic}
                                  /> */}
                                </div>
                                <div className="main_postMeta1L1">
                                  {/* <div>
                                    <img
                                      src={
                                        "https://fanzat-news.onrender.com/" +
                                        item?.user_ID?.images?.path
                                      }
                                      // src={data?.images?.path ? data?.image?.path : "../assets/img/profile-img.png"}
                                      className="product-img"
                                      alt="image"
                                    />
                                  </div>
                                  <div>
                                    <h6>{item?.user_ID?.user_nicename}</h6>

                                    <span>Jun 25 2022 </span>
                                  </div> */}
                                </div>
                                {/* </div> */}
                              </div>
                            </div>
                          </Link>
                        </div>
                      </>
                    );
                  })
              ) : (
                <p>لا توجد أخبار حاليا </p>
              )}

              <div className="middle_mainSec1R1">
                {mpNews1 && mpNews1.length > 0 ? (
                  mpNews1
                    .filter((item) => item?.status === "publish")
                    .slice(1, 3)
                    .map((item, index) => {
                      const postDate = new Date(item.date);
                      const formattedDate = `${postDate.toLocaleDateString()} ${postDate.toLocaleTimeString()}`;
                      return (
                        <>
                          <Link to={"/ar/single-blog-post/" + item._id}>
                            <div className="top_mainP1">
                              <div className="top_mainThumb1">
                                {/* <img src={item?.featured_media?.source_url} /> */}
                                <img
                                  src={
                                    "https://fanzat-news.onrender.com" +
                                    item?.featured_media?.source_url
                                  }
                                  className="product-img"
                                  alt="image"
                                />
                              </div>
                              <div className="top_mainP1D1">
                                <span className="top_mainP1D1Cat1">
                                  {" "}
                                  {item?.category?.name?.arabic}
                                </span>
                                <div className="post_title1Sec11">
                                  <h3 className="post_title1">
                                    {/* {item?.title?.rendered} */}
                                    {item?.title?.arabic
                                      ?.split(" ")
                                      .slice(0, 15)
                                      .join(" ")}
                                  </h3>
                                </div>
                              </div>
                            </div>
                          </Link>
                        </>
                      );
                    })
                ) : (
                  <p>لا توجد أخبار حاليا </p>
                )}
              </div>
            </div>
            {/* <div className="banner_flex" style={{ marginTop: "40px" }}>
              <div className="bannerM">
                <div className="slide_inner top_slideInner">
                  <img
                    src="https://new.axilthemes.com/demo/react/blogar/images/posts/thumbnail-05.webp"
                    alt=""
                  />
                  <div className="slide_descSec">
                    <span className="category_sec">Corporate News</span>
                    <div className="post_title1Sec11">
                      <h3 className="post_title1">
                        The world’s most powerful woman, Oprah Winfre.
                      </h3>
                    </div>
                    <div className="meta_flex">
                      <span>
                        <i class="far fa-calendar-alt"></i> 09 Nov. 2023
                      </span>{" "}
                      |{" "}
                      <span>
                        <i class="far fa-eye"></i> 167
                      </span>
                    </div>
                  </div>
                </div>
                <div className="slide_inner top_slideInner">
                  <img
                    src="https://new.axilthemes.com/demo/react/blogar/images/posts/post-tech-10.webp"
                    alt=""
                  />
                  <div className="slide_descSec">
                    <span className="category_sec">Corporate News</span>
                    <div className="post_title1Sec11">
                      <h3 className="post_title1">
                        The world’s most powerful woman, Oprah Winfre.
                      </h3>
                    </div>
                    <div className="meta_flex">
                      <span>
                        <i class="far fa-calendar-alt"></i> 09 Nov. 2023
                      </span>{" "}
                      |{" "}
                      <span>
                        <i class="far fa-eye"></i> 167
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bannerL">
                <div className="slide_inner">
                  <img
                    src="https://new.axilthemes.com/demo/react/blogar/images/posts/thumbnail-08.webp"
                    alt=""
                  />
                  <div className="slide_descSec">
                    <span className="category_sec">Celebrity News</span>
                    <div className="post_title1Sec11">
                      <h3 className="post_title1">
                        The world’s most powerful woman, Oprah Winfrey, has been
                        named the highest-paid celebrity in the world.
                      </h3>
                    </div>
                    <div className="meta_flex">
                      <span>
                        <i class="far fa-calendar-alt"></i> 09 Nov. 2023
                      </span>{" "}
                      |{" "}
                      <span>
                        <i class="far fa-eye"></i> 167
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      <section className="video_postSec1">
        <div className="container">
          <div className="video-sec-top-bar1">
            <div className="video-sec-btn1">
              <Link to={"/ar/all-video"}>
                <button>شاهد المزيد </button>
              </Link>
            </div>
            <div className="video-sec-heading1">
              <h2>فيديو مميز</h2>
            </div>
          </div>
          <div className="video_postFlex1">
            <div className="video_postR1">
              <div className="video_sMain1">
                <div className="video_thumb1">
                  {/* <img
                    src="https://new.axilthemes.com/demo/react/blogar-rtl/images/posts/thumbnail-08.webp"
                    alt=""
                  /> */}
                  <video className="video-1" controls>
                    <source src={video1} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  {/* <div className="video_over">
                    <i class="fas fa-play"></i>
                  </div> */}
                </div>
                <span className="video_cat1">SEO</span>
                <div className="post_title1Sec11">
                  <h3 className="post_title1">
                    The world’s most powerful woman, Oprah Winfrey.
                  </h3>
                </div>
                {/* <div className="main_postMeta1">
                  <div className="main_postMeta1R1">
                    <i class="fab fa-facebook-f"></i>
                    <i class="fab fa-twitter"></i>
                    <i class="fab fa-instagram"></i>
                    <i class="fas fa-link"></i>
                  </div>
                  <div className="main_postMeta1L1">
                    <h6>Sara Flower</h6>
                    <span>Jun 25 2022 . 5 min read</span>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="video_postL1">
              <div className="video_sFlex1">
                <div className="video_sMain1">
                  <div className="video_thumb1">
                    <video className="video2" controls>
                      <source src={video2} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                    {/* <div className="video_over">
                      <i class="fas fa-play"></i>
                    </div> */}
                  </div>
                  <span className="video_cat1">SEO</span>
                  <div className="post_title1Sec11">
                    <h3 className="post_title1">
                      The world’s most powerful woman, Oprah Winfrey.
                    </h3>
                  </div>
                </div>
                <div className="video_sMain1">
                  <div className="video_thumb1">
                    <video className="video2" controls>
                      <source src={video3} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                    {/* <div className="video_over">
                      <i class="fas fa-play"></i>
                    </div> */}
                  </div>
                  <span className="video_cat1">SEO</span>
                  <div className="post_title1Sec11">
                    <h3 className="post_title1">
                      The world’s most powerful woman, Oprah Winfrey.
                    </h3>
                  </div>
                </div>
                <div className="video_sMain1">
                  <div className="video_thumb1">
                    <video className="video2" controls>
                      <source src={video4} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                    {/* <div className="video_over">
                      <i class="fas fa-play"></i>
                    </div> */}
                  </div>
                  <span className="video_cat1">SEO</span>
                  <div className="post_title1Sec11">
                    <h3 className="post_title1">
                      The world’s most powerful woman, Oprah Winfrey.
                    </h3>
                  </div>
                </div>
                <div className="video_sMain1">
                  <div className="video_thumb1">
                    <video className="video2" controls>
                      <source src={video5} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                    {/* <div className="video_over">
                      <i class="fas fa-play"></i>
                    </div> */}
                  </div>
                  <span className="video_cat1">SEO</span>
                  <div className="post_title1Sec11">
                    <h3 className="post_title1">
                      The world’s most powerful woman, Oprah Winfrey.
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="trending_topicsSec1">
        <div className="container">
          {/* <div className="view_trending">
            <Link to="#">عرض جميع المواضيع</Link>
          </div> */}
          <div className="trending_topicsInner1">
            <div className="trending-top-section1">
              <div className="trending-top-btn1">
                <Link to={"/ar/most-used-tag"}>
                  <button>شاهد المزيد </button>
                </Link>
              </div>
              <div className="trending-top-heading1">
                <h2>المواضيع الرائجة</h2>
              </div>
            </div>
            <Slider {...settings}>
              {tag && tag.length > 0 ? (
                tag.slice(0, 20).map((item) => {
                  if (item?.tags?.arabic && item.tags.arabic.trim() !== "") {
                    return (
                      <>
                        {/* <a href={`/post-by-category/${item.id}`}> */}
                        <div className="trending_sec1">
                          <div className="trending_over1"></div>
                          {/* <img src={background} /> */}
                          <img
                            src={
                              "https://fanzat-news.onrender.com" +
                              item?.image?.url
                            }
                            alt="image"
                          />
                          <div className="trending_title1">
                            <h3>{item?.tags?.arabic}</h3>
                          </div>
                        </div>
                        {/* </a> */}
                        {/* <div className="mega-menu-cate1 mega-menu-cate2">
                                <h4>{item?.name?.arabic}</h4>
                                <ul>
                                  {item._id === parentId ? (
                                    subCategory.map((subcategoryItem) => (
                                      <li key={subcategoryItem._id}>
                                        {subcategoryItem?.name?.arabic}
                                      </li>
                                    ))
                                  ) : (
                                    <li>No Sub Category</li>
                                  )}
                                </ul>
                              </div> */}
                      </>
                    );
                  } else {
                    return null;
                  }
                })
              ) : (
                <p>لا توجد تاجات حاليا </p>
              )}
            </Slider>
          </div>
        </div>
      </section>
      <section className="ad_withTags1">
        <div className="container">
          <div className="ad_withTags1Sec1">
            <div className="ad_withR1">
              <img
                src="https://new.axilthemes.com/demo/react/blogar-rtl/images/add-banner/banner-01.webp"
                alt=""
              />
            </div>
            <div className="ad_withL1">
              <div className="ad_spans1">
                {/* {starTag.} */}

                {starTag && starTag.length > 0
                  ? starTag.slice(5, 11).map((item, i) => (
                      <div className="adspan_flex1">
                        <img
                          src={
                            "https://fanzat-news.onrender.com" +
                            item?.image?.url
                          }
                          alt="image"
                        />
                        <Link to={`/ar/posts-by-star-tags/${item.TermID}`}>
                          <h4>{item?.starTags?.arabic}</h4>
                        </Link>
                      </div>
                    ))
                  : null}
              </div>
              <div className="ad_withL1-startag-btn1">
                <Link to={"/ar/star-tags"}>
                  <button className="trending-top-btn1F1">شاهد المزيد </button>
                </Link>
              </div>
              {/* <h5
                style={{
                  textAlign: "center",
                  fontWeight: "600",
                  color: "#000",
                  marginTop: "15px",
                }}
              >
                Search
              </h5> */}
              <hr />
              {/* <div class="search_bar" style={{ marginTop: "15px" }}>
                <input type="search" placeholder="Search..." />
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <section className="posts_wSidebar1">
        <div className="container">
          <div className="posts_wFlex1">
            <div className="posts_wFlex1L1">
              {news && news.length > 0 ? (
                news
                  .filter((item) => item?.status === "publish")
                  .slice(0, 5)
                  ?.map((item, index) => {
                    const postDate = new Date(item.date);
                    const formattedDate = `${postDate.toLocaleDateString()} ${postDate.toLocaleTimeString()}`;
                    return (
                      <>
                        <Link to={"/ar/single-blog-post/" + item._id}>
                          <div className="main_postSec1">
                            <div className="main_postFlex1">
                              <div className="main_postContent1">
                                <a class="hover-flip-item1-wrapper1" href="/">
                                  <span class="hover-flip-item1">
                                    {item?.categories
                                      .filter(
                                        (category) => category.type === "1"
                                      )
                                      .map((category) => (
                                        <h6>{category.name?.arabic}</h6>
                                      ))}
                                  </span>
                                </a>
                                <div className="post_title1Sec11">
                                  <h3 className="post_title1">
                                    {item?.title?.arabic
                                      ?.split(" ")
                                      .slice(0, 12)
                                      .join(" ")}
                                  </h3>
                                </div>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: item?.content?.arabic
                                      ?.split(" ")
                                      .slice(0, 15)
                                      .join(" "),
                                  }}
                                ></p>
                                <div className="main_postMeta1">
                                  <div className="main_postMeta1R1">
                                    <SharePost
                                      title={item?.title?.arabic}
                                      url={`https://fanzat-news-arabic.vercel.app/single-blog-post/${item?._id}`}
                                      // content={item?.content?.arabic}
                                    />
                                    {/* <SharePost
                                      url={`https://fanzat-news-arabic.vercel.app/single-blog-post/${params.id}`}
                                      title={item?.title?.arabic}
                                      imageUrl={
                                        "https://fanzat-news.onrender.com" +
                                        item?.featured_media?.source_url
                                      }
                                      description={item?.content?.arabic}
                                    /> */}
                                    {/* <i class="fab fa-facebook-f"></i>
                                    <i class="fab fa-twitter"></i>
                                    <i class="fab fa-instagram"></i>
                                    <i class="fas fa-link"></i> */}
                                  </div>
                                  <div className="main_postMeta1L1">
                                    <span>{formattedDate} </span>
                                  </div>
                                </div>
                              </div>
                              <div className="main_postThumb1">
                                {/* <img src={item?.featured_media?.source_url} /> */}
                                <img
                                  src={
                                    "https://fanzat-news.onrender.com" +
                                    item?.featured_media?.source_url
                                  }
                                  className="product-img"
                                  alt="image"
                                />
                              </div>
                            </div>
                          </div>
                        </Link>
                      </>
                    );
                  })
              ) : (
                <p>لا توجد أخبار حاليا </p>
              )}

              <div className="view-all-post-btn11">
                <Link to={"/ar/posts"}>
                  <button className="all-post-btn1">شاهد المزيد </button>
                </Link>
              </div>
            </div>
            <div className="posts_wFlex1R1">
              <div className="popular_onSite1">
                <h5>عالي المشاهدة على فانزات</h5>
                <hr />
                <div className="popular_innerSec1">
                  {Anews && Anews.length > 0 ? (
                    Anews.filter((item) => item?.status === "publish")
                      .slice(4, 8)
                      .map((item, index) => {
                        const postDate = new Date(item.date);
                        const formattedDate = `${postDate.toLocaleDateString()} ${postDate.toLocaleTimeString()}`;
                        return (
                          <>
                            <Link to={"/ar/single-blog-post/" + item._id}>
                              <div className="pop_postFlex1">
                                <div className="pop_postFlex1L1">
                                  {/* <img src={item?.featured_media?.source_url} /> */}
                                  <img
                                    src={
                                      "https://fanzat-news.onrender.com" +
                                      item?.featured_media?.source_url
                                    }
                                    className="product-img"
                                    alt="image"
                                  />
                                </div>
                                <div className="pop_postFlex1R1">
                                  <div className="post_title1Sec11">
                                    <h3 className="post_title1">
                                      {item?.title?.arabic
                                        ?.split(" ")
                                        .slice(0, 10)
                                        .join(" ")}
                                    </h3>
                                  </div>
                                  <span>{formattedDate}</span>
                                </div>
                              </div>
                            </Link>
                          </>
                        );
                      })
                  ) : (
                    <p>لا توجد أخبار حاليا </p>
                  )}
                </div>
              </div>
              <div className="social_links1">
                <h5>ابقى على اتصال</h5>
                <hr />
                <i class="fab fa-linkedin-in"></i>
                <i class="fab fa-slack"></i>
                <i class="fab fa-twitter"></i>
                <i class="fab fa-instagram"></i>
                <i class="fab fa-facebook-f"></i>
              </div>
              {/* <div className="instagram_feed">
                <h5>Instagram</h5>
                <hr />
                <div className="instagram_flex">
                  <div className="instagram_inner">
                    <img
                      src="https://new.axilthemes.com/demo/react/blogar/images/small-images/instagram-md-03.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div> */}
              <div className="fanzat_stars1">
                <h5>نجوم فانزات</h5>
                <hr />
                <iframe src="https://nnsm3.com/" title="fanzat stars"></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="bottom_instagram">
        <div className="container">
          <div className="social_bottomBar">
            <div className="social_inner">
              <p>
                Twitter<i class="fab fa-twitter"></i>
              </p>
            </div>
          </div>
          <div className="bottom_instaBar">
            <h2>Instagram</h2>
            <div className="bottom_instaFlex">
              <div className="bottom_instaInner">
                <img
                  src="https://new.axilthemes.com/demo/react/blogar/images/small-images/instagram-md-06.webp"
                  alt=""
                />
                <div className="insta_over">
                  <i class="fab fa-instagram"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <NewsLetterAr />
      <FooterAr />
    </>
  );
};

export default HomeAr;
