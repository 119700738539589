import React from "react";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
} from "react-share";
// import { FacebookIcon, TwitterIcon, LinkedinIcon,WhatsappIcon } from "react-share";

function SharePost({ title, url }) {
  return (
    <div>
      <FacebookShareButton url={url} quote={title}>
        {/* <FacebookIcon size={28} round /> */}
        <i class="fab fa-facebook-f"></i>
      </FacebookShareButton>

      <TwitterShareButton url={url} title={title}>
        {/* <TwitterIcon size={28} round /> */}
        <i class="fab fa-twitter"></i>
      </TwitterShareButton>

      <LinkedinShareButton url={url} title={title}>
        {/* <LinkedinIcon size={28} round /> */}
        <i class="fas fa-link"></i>
      </LinkedinShareButton>
      <WhatsappShareButton url={url} title={title}>
        {/* <WhatsappIcon size={32} round /> */}
        <i class="fab fa-whatsapp"></i>
      </WhatsappShareButton>
    </div>
  );
}

export default SharePost;
