import React, { useState, useEffect } from "react";

import { ToastContainer, toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import DataService from "../services/data.service";
import NewsLetterAr from "../Common/NewsLetterAr";
import FooterAr from "../Common/FooterAr";
import { Link } from "react-router-dom";

import HeaderAr from "../Common/HeaderAr";

function PostsByStarAr() {
  const params = useParams();

  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(false);
  const [email, setEmail] = useState("");

  useEffect(() => {
    document.title = "Post By Star Tag";
    window.scrollTo(0, 0);
    getPostStartags();
  }, []);

  const getPostStartags = async () => {
    setLoading(true);
    await DataService.getPostStarIdAr(params.id).then(
      (data) => {
        setData(data?.data?.data);
        setLoading(false);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);
        setMessage(resMessage);
        // toast.error(resMessage, {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
      }
    );
  };

  return (
    <>
      <HeaderAr />
      <div className="section3-post-page1">
        {loading && (
          <div className="loader-spin">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        )}
        <div className="container">
          <div className="related-post-bottom1 post-by-starTag-sec1">
            <h2>جميع الأخبار </h2>

            <div className="error-msg-starpost1">
              <h2>{message}</h2>
            </div>

            <div className="related-post-Bbox1">
              {data && data.length > 0 ? (
                data
                  .filter(
                    (item) => item.status === "publish"
                    // item.status === "publish" &&
                    // item?._id !== data?._id
                  )
                  .map((item, index) => {
                    const postDate = new Date(item.date);
                    const formattedDate = `${postDate.toLocaleDateString()} ${postDate.toLocaleTimeString()}`;
                    return (
                      <>
                        <div className="related-post-Bcolumn1 post-star-tag-sec1">
                          {/* <a href={"/ar/single-blog-post/" + item._id}> */}
                          <Link to={"/ar/single-blog-post/" + item._id}>
                            <div className="Bcolumn-img">
                              <img
                                src={
                                  "https://fanzat-news.onrender.com" +
                                  item?.featured_media?.source_url
                                }
                                className="product-img"
                                alt="image"
                              />
                            </div>
                            <div className="Bcolumn-content1">
                              <h4>
                                {" "}
                                {item?.title?.arabic
                                  ?.split(" ")
                                  .slice(0, 8)
                                  .join(" ")}
                              </h4>

                              <p
                                dangerouslySetInnerHTML={{
                                  __html: item.content?.arabic
                                    ?.split(" ")
                                    .slice(0, 12)
                                    .join(" "),
                                }}
                              ></p>
                            </div>
                            {/* </a> */}
                          </Link>
                        </div>
                      </>
                    );
                  })
              ) : (
                <p>لا توجد أخبار حاليا </p>
              )}
            </div>
          </div>
        </div>
      </div>
      <NewsLetterAr />
      <FooterAr />
    </>
  );
}

export default PostsByStarAr;
