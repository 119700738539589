import axios from "axios";
import AuthService from "./auth.service";

const API_URL =
  process.env.NODE_ENV != "production"
    ? "https://fanzat-news.onrender.com/"
    : "https://fanzat-news.onrender.com/";

axios.interceptors.request.use(function (config) {
  const token = AuthService.getCurrentUserTokken();
  config.headers.Authorization = "Bearer " + token;

  return config;
});
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("user");
      // window.location.href ='/#/login'
      // Hace la solicitud de refresco de tokens
    }
    return Promise.reject(error);
  }
);

// %$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$  english $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$
const getAllPopularNews = () => {
  return axios.get(API_URL + "api/EnNews/getAll/popular?language=english");
};
const getCategoryBy = (id) => {
  return axios.get(API_URL + "api/categories/singleGet/" + id);
  // return axios.get(API_URL + "api/categories/getById/" + id);
};
const getAllPNews = (page) => {
  return axios.get(`${API_URL}api/EnNews/getAll?page=${page}&language=english`);
};

const getAllNews = () => {
  return axios.get(API_URL + "api/EnNews/getAll?language=english");
};
const getAllLatestPost = () => {
  return axios.get(API_URL + "api/EnNews/getLatestPost");
};
const getACategoryById = (id) => {
  return axios.get(
    API_URL + "api/superAdmin/get/related/category/" + id + "?language=english"
  );
};

const getRelatedPost = (id, page) => {
  return axios.get(
    `${API_URL}api/superAdmin/get/related/category/${id}?page=${page}&language=english`
  );
};
const getSingleBlog = (id) => {
  return axios.get(API_URL + "api/EnNews/getById/" + id);
};
const getSearchPost = (title) => {
  return axios.get(API_URL + "api/EnNews/title/search?title=" + title);
};
const AddComment = (data) => {
  return axios.post(API_URL + "api/comment/addComment", data);
};
const addEmail = (data) => {
  return axios.post(API_URL + "api/email/addEmail", data);
};
const addContact = (data) => {
  return axios.post(API_URL + "api/contact/addContact", data);
};
const getComments = (id) => {
  return axios.get(API_URL + "api/comment/commentsGetByPostId/" + id);
};

const getPrivacyPolicy = () => {
  return axios.get(API_URL + "api/privacy/get");
};

const getTermsUsed = () => {
  return axios.get(API_URL + "api/terms/get");
};

const getAboutUs = () => {
  return axios.get(API_URL + "api/aboutus/get");
};
const getContactUs = () => {
  return axios.get(API_URL + "api/contactus/get");
};

const getAllCategory = (type) => {
  return axios.get(API_URL + "api/categories/getAll?type=" + type);
};
const getRelatedmasterCat = (id) => {
  return axios.get(
    API_URL +
      "api/superAdmin/get/latest/master/category/" +
      id +
      "?language=english"
  );
};
const getAllTag = () => {
  return axios.get(API_URL + "api/tag/getAll?language=english");
};
const getAllTags = (page) => {
  return axios.get(`${API_URL}api/tag/getAll?page=${page}&language=english`);
};

const getAllStarTags = () => {
  return axios.get(API_URL + "api/starTag/getAll?language=english");
};
const getAllStarTag = (page) => {
  return axios.get(
    `${API_URL}api/starTag/getAll?page=${page}&language=english`
  );
};
const getAllMostTag = () => {
  return axios.get(API_URL + "api/EnNews/get/popular/tags");
};
const getPostStarId = (id) => {
  return axios.get(
    API_URL + "api/EnNews/get/post/byStarId/" + id + "?language=english"
  );
};

// $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ arabic $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$

const getAllPopularNewsAr = () => {
  return axios.get(API_URL + "api/EnNews/getAll/popular?language=arabic");
};
const getCategoryByAr = (id) => {
  return axios.get(API_URL + "api/categories/singleGet/" + id);
  // return axios.get(API_URL + "api/categories/getById/" + id);
};
const getAllPNewsAr = (page) => {
  return axios.get(`${API_URL}api/EnNews/getAll?page=${page}&language=arabic`);
};

const getAllNewsAr = () => {
  return axios.get(API_URL + "api/EnNews/getAll?language=arabic");
};
const getAllLatestPostAr = () => {
  return axios.get(API_URL + "api/EnNews/getLatestPost");
};
const getACategoryByIdAr = (id) => {
  return axios.get(
    API_URL + "api/superAdmin/get/related/category/" + id + "?language=arabic"
  );
};

const getRelatedPostAr = (id, page) => {
  return axios.get(
    `${API_URL}api/superAdmin/get/related/category/${id}?page=${page}&language=arabic`
  );
};
const getSingleBlogAr = (id) => {
  return axios.get(API_URL + "api/EnNews/getById/" + id);
};
const getSearchPostAr = (title) => {
  return axios.get(API_URL + "api/EnNews/title/search?title=" + title);
};
const AddCommentAr = (data) => {
  return axios.post(API_URL + "api/comment/addComment", data);
};
const addEmailAr = (data) => {
  return axios.post(API_URL + "api/email/addEmail", data);
};
const addContactAr = (data) => {
  return axios.post(API_URL + "api/contact/addContact", data);
};
const getCommentsAr = (id) => {
  return axios.get(API_URL + "api/comment/commentsGetByPostId/" + id);
};

const getPrivacyPolicyAr = () => {
  return axios.get(API_URL + "api/privacy/get");
};
const getTermsUsedAr = () => {
  return axios.get(API_URL + "api/terms/get");
};

const getAboutUsAr = () => {
  return axios.get(API_URL + "api/aboutus/get");
};
const getContactUsAr = () => {
  return axios.get(API_URL + "api/contactus/get");
};

const getAllCategoryAr = (type) => {
  return axios.get(API_URL + "api/categories/getAll?type=" + type);
};
const getRelatedmasterCatAr = (id) => {
  return axios.get(
    API_URL +
      "api/superAdmin/get/latest/master/category/" +
      id +
      "?language=arabic"
  );
};
const getAllTagAr = () => {
  return axios.get(API_URL + "api/tag/getAll?language=arabic");
};
const getAllTagsAr = (page) => {
  return axios.get(`${API_URL}api/tag/getAll?page=${page}&language=arabic`);
};

const getAllStarTagsAr = () => {
  return axios.get(API_URL + "api/starTag/getAll?language=arabic");
};
const getAllStarTagAr = (page) => {
  return axios.get(`${API_URL}api/starTag/getAll?page=${page}&language=arabic`);
};
const getAllMostTagAr = () => {
  return axios.get(API_URL + "api/EnNews/get/popular/tags");
};
const getPostStarIdAr = (id) => {
  return axios.get(
    API_URL + "api/EnNews/get/post/byStarId/" + id + "?language=arabic"
  );
};

// $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ french $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$

const getAllPopularNewsFr = () => {
  return axios.get(API_URL + "api/EnNews/getAll/popular?language=french");
};
const getCategoryByFr = (id) => {
  return axios.get(API_URL + "api/categories/singleGet/" + id);
  // return axios.get(API_URL + "api/categories/getById/" + id);
};
const getAllPNewsFr = (page) => {
  return axios.get(`${API_URL}api/EnNews/getAll?page=${page}&language=french`);
};

const getAllNewsFr = () => {
  return axios.get(API_URL + "api/EnNews/getAll?language=french");
};
const getAllLatestPostFr = () => {
  return axios.get(API_URL + "api/EnNews/getLatestPost");
};
const getACategoryByIdFr = (id) => {
  return axios.get(
    API_URL + "api/superAdmin/get/related/category/" + id + "?language=french"
  );
};

const getRelatedPostFr = (id, page) => {
  return axios.get(
    `${API_URL}api/superAdmin/get/related/category/${id}?page=${page}&language=french`
  );
};
const getSingleBlogFr = (id) => {
  return axios.get(API_URL + "api/EnNews/getById/" + id);
};
const getSearchPostFr = (title) => {
  return axios.get(API_URL + "api/EnNews/title/search?title=" + title);
};
const AddCommentFr = (data) => {
  return axios.post(API_URL + "api/comment/addComment", data);
};
const addEmailFr = (data) => {
  return axios.post(API_URL + "api/email/addEmail", data);
};
const addContactFr = (data) => {
  return axios.post(API_URL + "api/contact/addContact", data);
};
const getCommentsFr = (id) => {
  return axios.get(API_URL + "api/comment/commentsGetByPostId/" + id);
};

const getPrivacyPolicyFr = () => {
  return axios.get(API_URL + "api/privacy/get");
};
const getTermsUsedFr = () => {
  return axios.get(API_URL + "api/terms/get");
};

const getAboutUsFr = () => {
  return axios.get(API_URL + "api/aboutus/get");
};
const getContactUsFr = () => {
  return axios.get(API_URL + "api/contactus/get");
};

const getAllCategoryFr = (type) => {
  return axios.get(API_URL + "api/categories/getAll?type=" + type);
};
const getRelatedmasterCatFr = (id) => {
  return axios.get(
    API_URL +
      "api/superAdmin/get/latest/master/category/" +
      id +
      "?language=french"
  );
};
const getAllTagFr = () => {
  return axios.get(API_URL + "api/tag/getAll?language=french");
};
const getAllTagsFr = (page) => {
  return axios.get(`${API_URL}api/tag/getAll?page=${page}&language=french`);
};

const getAllStarTagsFr = () => {
  return axios.get(API_URL + "api/starTag/getAll?language=french");
};
const getAllStarTagFr = (page) => {
  return axios.get(`${API_URL}api/starTag/getAll?page=${page}&language=french`);
};
const getAllMostTagFr = () => {
  return axios.get(API_URL + "api/EnNews/get/popular/tags");
};
const getPostStarIdFr = (id) => {
  return axios.get(
    API_URL + "api/EnNews/get/post/byStarId/" + id + "?language=french"
  );
};

// $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ arabic $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$

const getAllPopularNewsEs = () => {
  return axios.get(API_URL + "api/EnNews/getAll/popular?language=espanol");
};
const getCategoryByEs = (id) => {
  return axios.get(API_URL + "api/categories/singleGet/" + id);
  // return axios.get(API_URL + "api/categories/getById/" + id);
};
const getAllPNewsEs = (page) => {
  return axios.get(`${API_URL}api/EnNews/getAll?page=${page}&language=espanol`);
};

const getAllNewsEs = () => {
  return axios.get(API_URL + "api/EnNews/getAll?language=espanol");
};
const getAllLatestPostEs = () => {
  return axios.get(API_URL + "api/EnNews/getLatestPost");
};
const getACategoryByIdEs = (id) => {
  return axios.get(
    API_URL + "api/superAdmin/get/related/category/" + id + "?language=espanol"
  );
};

const getRelatedPostEs = (id, page) => {
  return axios.get(
    `${API_URL}api/superAdmin/get/related/category/${id}?page=${page}&language=espanol`
  );
};
const getSingleBlogEs = (id) => {
  return axios.get(API_URL + "api/EnNews/getById/" + id);
};
const getSearchPostEs = (title) => {
  return axios.get(API_URL + "api/EnNews/title/search?title=" + title);
};
const AddCommentEs = (data) => {
  return axios.post(API_URL + "api/comment/addComment", data);
};
const addEmailEs = (data) => {
  return axios.post(API_URL + "api/email/addEmail", data);
};
const addContactEs = (data) => {
  return axios.post(API_URL + "api/contact/addContact", data);
};
const getCommentsEs = (id) => {
  return axios.get(API_URL + "api/comment/commentsGetByPostId/" + id);
};

const getPrivacyPolicyEs = () => {
  return axios.get(API_URL + "api/privacy/get");
};
const getTermsUsedEs = () => {
  return axios.get(API_URL + "api/terms/get");
};

const getAboutUsEs = () => {
  return axios.get(API_URL + "api/aboutus/get");
};
const getContactUsEs = () => {
  return axios.get(API_URL + "api/contactus/get");
};

const getAllCategoryEs = (type) => {
  return axios.get(API_URL + "api/categories/getAll?type=" + type);
};
const getRelatedmasterCatEs = (id) => {
  return axios.get(
    API_URL +
      "api/superAdmin/get/latest/master/category/" +
      id +
      "?language=espanol"
  );
};
const getAllTagEs = () => {
  return axios.get(API_URL + "api/tag/getAll?language=espanol");
};
const getAllTagsEs = (page) => {
  return axios.get(`${API_URL}api/tag/getAll?page=${page}&language=espanol`);
};

const getAllStarTagsEs = () => {
  return axios.get(API_URL + "api/starTag/getAll?language=espanol");
};
const getAllStarTagEs = (page) => {
  return axios.get(
    `${API_URL}api/starTag/getAll?page=${page}&language=espanol`
  );
};
const getAllMostTagEs = () => {
  return axios.get(API_URL + "api/EnNews/get/popular/tags");
};
const getPostStarIdEs = (id) => {
  return axios.get(
    API_URL + "api/EnNews/get/post/byStarId/" + id + "?language=espanol"
  );
};

const DataService = {
  getCategoryBy,
  getACategoryById,
  getAllTag,
  AddComment,
  getRelatedPost,
  getComments,
  getSingleBlog,
  getAllStarTags,
  getAllStarTag,
  getAllLatestPost,
  getAboutUs,
  getContactUs,

  getAllNews,
  getAllCategory,
  getAllPopularNews,
  getAllPNews,
  getRelatedmasterCat,
  getSearchPost,
  getAllMostTag,
  getAllTags,
  addEmail,
  addContact,
  getPostStarId,
  getPrivacyPolicy,
  getTermsUsed,

  // $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ arabic $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$

  getAllPopularNewsAr,
  getCategoryByAr,
  getAllPNewsAr,
  getAllNewsAr,
  getAllLatestPostAr,
  getACategoryByIdAr,
  getRelatedPostAr,
  getSingleBlogAr,
  getSearchPostAr,
  AddCommentAr,
  addEmailAr,
  addContactAr,
  getCommentsAr,
  getPrivacyPolicyAr,
  getTermsUsedAr,
  getAboutUsAr,
  getContactUsAr,
  getAllCategoryAr,
  getRelatedmasterCatAr,
  getAllTagAr,
  getAllTagsAr,
  getAllStarTagsAr,
  getAllStarTagAr,
  getAllMostTagAr,
  getPostStarIdAr,

  // $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ french $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$

  getAllPopularNewsFr,
  getCategoryByFr,
  getAllPNewsFr,
  getAllNewsFr,
  getAllLatestPostFr,
  getACategoryByIdFr,
  getRelatedPostFr,
  getSingleBlogFr,
  getSearchPostFr,
  AddCommentFr,
  addEmailFr,
  addContactFr,
  getCommentsFr,
  getPrivacyPolicyFr,
  getTermsUsedFr,
  getAboutUsFr,
  getContactUsFr,
  getAllCategoryFr,
  getRelatedmasterCatFr,
  getAllTagFr,
  getAllTagsFr,
  getAllStarTagsFr,
  getAllStarTagFr,
  getAllMostTagFr,
  getPostStarIdFr,

  // $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$ french $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$

  getAllPopularNewsEs,
  getCategoryByEs,
  getAllPNewsEs,
  getAllNewsEs,
  getAllLatestPostEs,
  getACategoryByIdEs,
  getRelatedPostEs,
  getSingleBlogEs,
  getSearchPostEs,
  AddCommentEs,
  addEmailEs,
  addContactEs,
  getCommentsEs,
  getPrivacyPolicyEs,
  getTermsUsedEs,
  getAboutUsEs,
  getContactUsEs,
  getAllCategoryEs,
  getRelatedmasterCatEs,
  getAllTagEs,
  getAllTagsEs,
  getAllStarTagsEs,
  getAllStarTagEs,
  getAllMostTagEs,
  getPostStarIdEs,
};
export default DataService;
