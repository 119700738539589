import React, { useEffect, useState } from "react";
import Logo from "../images/Logo1.png";
import Author from "../images/author.webp";
import "../CSS/HeaderAr.css";
import { useNavigate } from "react-router-dom";
import Icon from "../images/fanzat_icon.png";
// import logo from "../images/fanzat_icon.png";
import { Link } from "react-router-dom";
import DataService from "../services/data.service";
const HeaderAr = () => {
  const navigate = useNavigate();
  const today = new Date().toLocaleDateString("en-US", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  const [masterCategory, setMasterCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [parentId, setParentId] = useState([]);
  const [starTag, setStarTag] = useState("");
  const [search, setSearch] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);

  const myStateData = {
    key1: search,
  };

  const getAllCategory = async () => {
    await DataService.getAllCategory(1).then((data) => {
      setMasterCategory(data?.data?.categories);
    });
    await DataService.getAllCategory(2).then((data) => {
      setSubCategory(data?.data?.categories);
    });
  };
  const getAllStarTag = async () => {
    await DataService.getAllStarTags().then((data) => {
      setStarTag(data?.data?.data);
    });
  };
  // const getAllTags = async () => {
  //   await DataService.getAllTag().then((data) => {
  //     setStarTag(data?.data?.data);
  //   });
  // };

  useEffect(() => {
    document.title = "Category";
    window.scrollTo(0, 0);
    getAllStarTag();
    getAllCategory();
    // getAllTags();
    // getSearchPosts();
  }, []);

  const onChangeSearch = (event) => {
    const searchTerm = event.target.value;
    setSearch(searchTerm);
  };
  const handleChangeL = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    if (selectedValue === "En") {
      navigate("/");
    } else if (selectedValue === "Ar") {
      navigate("/ar");
    } else if (selectedValue === "Fr") {
      navigate("/fr");
    } else if (selectedValue === "Es") {
      navigate("/es");
    }
  };

  const handleSubCategoryClick = (id) => {
    setSelectedSubCategory(id);
  };

  return (
    <>
      <section className="main_topBar11">
        <div className="container-two1">
          <div className="topBar1">
            <div className="topBar1L1">
              <Link to={"/ar"}>
                <img src={Icon} alt="" />
              </Link>
              <i class="fab fa-linkedin-in"></i>
              <i class="fab fa-twitter"></i>
              <i class="fab fa-instagram"></i>
              <i class="fab fa-facebook-f"></i>
              <i class="fab fa-youtube"></i>
              <i class="fab fa-snapchat-ghost"></i>
            </div>
            <div className="topBar1M1">
              <img
                src={Logo}
                alt=""
                style={{ cursor: "pointer" }}
                onClick={() => navigate("/ar")}
              />
            </div>

            <div className="topBar1R1">
              <div className="innerLinks1">
                <ul>
                  {/* <Link to={"/contact-us"}> */}
                  <a href="https://nnsm3.com/company/contact">
                    <li>اتصل بنا</li>
                  </a>
                  {/* </Link> */}
                  <Link to={"/ar/about-us"}>
                    <li>عن فانزات </li>
                  </Link>
                  {/* <Link to={"/"}> */}
                  {/* <li>الإعلانات</li> */}
                  {/* </Link> */}
                </ul>
                {/* <button onClick={handleDirectionChange}>abc</button> */}
              </div>
              <div className="language_switcher1">
                {/* <div id="google_translate_element"></div> */}
                {/* <button className="language-btn">select language</button> */}
                {/* <form class="d-flex align-items-center ms-auto"> */}
                {/* <select
                  class="form-selec1t me-3 select-option11"
                  value={selectedStatus}
                  onChange={onChangeStatus}
                  id="selectOption"
                  onchange={goToLink}
                >
                  <option value="">Language</option>
                  <option value="https://fanzat-news-english.vercel.app/">
                    En
                  </option>
                  <option value="">Ar</option>
                  <option value="">Fr</option>
                  <option value="">Es</option>
                </select> */}
                <select
                  class="form-select me-3 select-lan-box1"
                  value={selectedOption}
                  onChange={handleChangeL}
                >
                  <option value="">اللغة </option>
                  <option value="En">En</option>
                  <option value="Ar">Ar</option>
                  <option value="Fr">Fr</option>
                  <option value="Es">Es</option>
                </select>
                {/* </form> */}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="main_header1Bar1">
        <div className="container-two1">
          <div className="main_header1">
            <div className="main_header1L1">
              <ul>
                <Link to={"/ar"}>
                  <li>الرئيسية</li>
                </Link>
                <li>
                  الأخبار
                  <div className="mega_subMenu1">
                    <ul>
                      <li>قياسي</li>
                      <li>فيديو</li>
                      <li>معرض الصور</li>
                      <li>صوت</li>
                      <li>اقتباس</li>
                    </ul>
                  </div>
                </li>
                <li className="show-mega-menu11">
                  الأقسام
                  {/* <div className="mega_megaMenu">
                    <div className="mega_megaL">
                      <ul>
                        <li>Influencers - Stars</li>
                        <li>Brands - Orbits</li>
                        <li>Fanzat - Galaxy</li>
                      </ul>
                    </div>
                    <div className="mega_megaR">
                      <div className="mega_postFlex">
                        <div className="mega_postInner">
                          <img
                            src="https://new.axilthemes.com/demo/react/blogar/images/posts/thumbnail-08.webp"
                            alt=""
                          />
                          <span className="mega_cat">Research</span>
                          <h4>
                            The world’s most powerful woman, Oprah Winfrey.
                          </h4>
                        </div>
                        <div className="mega_postInner">
                          <img
                            src="https://new.axilthemes.com/demo/react/blogar/images/posts/post-column-02.webp"
                            alt=""
                          />
                          <span className="mega_cat">Research</span>
                          <h4>
                            The world’s most powerful woman, Oprah Winfrey.
                          </h4>
                        </div>
                        <div className="mega_postInner">
                          <img
                            src="https://new.axilthemes.com/demo/react/blogar/images/posts/thumbnail-02.webp"
                            alt=""
                          />
                          <span className="mega_cat">Research</span>
                          <h4>
                            The world’s most powerful woman, Oprah Winfrey.
                          </h4>
                        </div>
                        <div className="mega_postInner">
                          <img
                            src="https://new.axilthemes.com/demo/react/blogar/images/posts/post-seo-sm-04.webp"
                            alt=""
                          />
                          <span className="mega_cat">Research</span>
                          <h4>
                            The world’s most powerful woman, Oprah Winfrey.
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* {  item.parent == 18 ? 
                          (<h6>{item.name}</h6>) :""
                       } */}
                  <div className="mega-menu-category11">
                    <div className="flex-mega-menu11">
                      {masterCategory && masterCategory.length > 0 ? (
                        masterCategory.map((item) => {
                          return (
                            <>
                              <div className="mega-menu-cate1 mega-menu-cate21">
                                <h4>{item?.name?.arabic}</h4>
                                <ul>
                                  {subCategory.map((subcategoryItem) => {
                                    if (subcategoryItem.parent == item.id) {
                                      return (
                                        <a
                                          key={subcategoryItem.id}
                                          href={`/ar/post-by-category/${subcategoryItem.id}`}
                                        >
                                          {/* <li> */}
                                          <li
                                            key={subcategoryItem.id}
                                            onClick={() =>
                                              handleSubCategoryClick(
                                                subcategoryItem.id
                                              )
                                            }
                                            className={
                                              selectedSubCategory ===
                                              subcategoryItem.id
                                                ? "active-color"
                                                : ""
                                            }
                                          >
                                            {subcategoryItem?.name?.arabic}
                                          </li>
                                        </a>
                                      );
                                    }
                                    return null;
                                  })}
                                </ul>
                              </div>
                            </>
                          );
                        })
                      ) : (
                        <p>لا توجد أقسام حاليا </p>
                      )}
                      {/* <div className="mega-menu-cate1 mega-menu-cate21">
                        <h4>Orbit</h4>
                        <ul>
                          {category && category.length > 0 ? (
                            category.map((item) => {
                              return (
                                <>
                                  {item.parent == 18 ? (
                                    <li>{item.name}</li>
                                  ) : (
                                    ""
                                  )}
                                </>
                              );
                            })
                          ) : (
                            <p>لا توجد أقسام حاليا </p>
                          )}
                        </ul>
                      </div>
                      <div className="mega-menu-cate1">
                        <h4>STARS</h4>
                        <ul>
                          {category && category.length > 0 ? (
                            category.map((item) => {
                              return (
                                <>
                                  {item.parent == 100 ? (
                                    <li>{item.name}</li>
                                  ) : (
                                    ""
                                  )}
                                </>
                              );
                            })
                          ) : (
                            <p>لا توجد أقسام حاليا </p>
                          )}
                        </ul>
                        <ul>
                          <Link to={`/star-page/`}>
                            <li>by Stars’</li>
                          </Link>
                          <li>Stars’ Campaigns</li>
                          <li>Stars’ Events</li>
                          <li>Stars’ Interviews</li>
                          <li>Stars’ News</li>
                        </ul>
                      </div> */}
                    </div>
                  </div>
                </li>
                <li className="show-mega-menu11">
                  نجوم
                  <div className="mega-menu-category11">
                    <div className="flex-mega-menu11">
                      {/* <div className="mega-menu-cate1 mega-menu-cate21">
                        <h4>Galaxy</h4>

                        <ul>
                          {category && category.length > 0 ? (
                            category.map((item) => {
                              return (
                                <>
                                  {item.parent == 1 ? <li>{item.name}</li> : ""}
                                </>
                              );
                            })
                          ) : (
                            <p>لا توجد أقسام حاليا </p>
                          )}
                        </ul>
                      </div>
                      <div className="mega-menu-cate1 mega-menu-cate21">
                        <h4>Orbit</h4>
                        <ul>
                          {category && category.length > 0 ? (
                            category.map((item) => {
                              return (
                                <>
                                  {item.parent == 18 ? (
                                    <li>{item.name}</li>
                                  ) : (
                                    ""
                                  )}
                                </>
                              );
                            })
                          ) : (
                            <p>لا توجد أقسام حاليا </p>
                          )}
                        </ul>
                      </div>
                      <div className="mega-menu-cate1">
                        <h4>STARS</h4>
                        <ul>
                          {category && category.length > 0 ? (
                            category.map((item) => {
                              return (
                                <>
                                  {item.parent == 100 ? (
                                    <li>{item.name}</li>
                                  ) : (
                                    ""
                                  )}
                                </>
                              );
                            })
                          ) : (
                            <p>لا توجد أقسام حاليا </p>
                          )}
                        </ul>
                      </div> */}
                      {starTag && starTag.length > 0 ? (
                        starTag?.slice(0, 15)?.map((item) => {
                          return (
                            <>
                              <div className="star-main-submenu1">
                                <div className="star-submenu-11">
                                  <img
                                    src={
                                      "https://fanzat-news.onrender.com" +
                                      item?.image?.url
                                    }
                                    className="product-img"
                                    alt="image"
                                  />
                                  {/* <Link
                                    to={`/posts-by-star-tags/${item.TermID}`}
                                  > */}
                                  <a
                                    href={
                                      "/ar/posts-by-star-tags/" + item.TermID
                                    }
                                  >
                                    <h4> {item?.starTags?.arabic}</h4>
                                  </a>
                                  {/* </Link> */}
                                </div>
                              </div>
                            </>
                          );
                        })
                      ) : (
                        <p>لا توجد اشارات لنجوم حاليا </p>
                      )}
                    </div>
                    <div className="show-more-star-tag1">
                      <Link to={"/star-tags"}>
                        شاهد المزيد <i class="fas fa-hand-point-right"></i>
                      </Link>
                    </div>
                  </div>
                </li>
                {/* <Link to={"/"}>
                  <li>تقارير</li>
                </Link>
                <Link to={"/"}>
                  <li>فعاليات</li>
                </Link> */}

                {/* <li>
                  Posts
                  <div className="mega_subMenu1" style={{ left: "-120px" }}>
                    <ul>
                      <li>Standard</li>
                      <li>Video</li>
                      <li>Gallery</li>
                      <li>Audio</li>
                      <li>Quote</li>
                    </ul>
                  </div>
                </li> */}
                {/* <li>Home</li> */}
              </ul>
            </div>
            <div className="main_header1R1">
              {search && (
                <Link
                  className="search_submit1"
                  to={`/ar/search-post?param1=${myStateData.key1}`}
                >
                  <div className="icon-search-box11">
                    ابحث <i className="fas fa-search"></i>
                  </div>
                </Link>
              )}
              <div className="search_bar1">
                <input
                  type="search"
                  placeholder="بحث..."
                  value={search}
                  onChange={onChangeSearch}
                  style={{ textAlign: "left" }}
                />
              </div>

              <div className="account_ico1">
                <i class="fas fa-bell"></i>
                <i class="fas fa-bookmark"></i>
                <img src={Author} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeaderAr;
