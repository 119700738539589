import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import SingleBlogPost from "./Pages/SingleBlogPost";
import Posts from "./Pages/Posts";
import ContactUs from "./Pages/ContactUs";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsOfUse from "./Pages/TermsOfUse";
import ErrorPage from "./Pages/ErrorPage";
import StarPage from "./Pages/StarPage";
import SiteMap from "./Pages/SiteMap";
import SearchPost from "./Pages/SearchPost";
import PostByCategory from "./Pages/PostByCategory";
import MostUsedTag from "./Pages/MostUsedTags";
import AllVideo from "./Pages/AllVideos";
import PostsByStar from "./Pages/PostByStar";
import AboutUs from "./Pages/AboutUs";
import RelatedPosts from "./Pages/RelatedPosts";
import FanzatNews from "./Pages/FanzatNews";
import HomeAr from "./Pages/HomeAr";
import SingleBlogPostAr from "./Pages/SingleBlogPostAr";
import PostsAr from "./Pages/PostAr";
import PostByCategoryAr from "./Pages/PostByCategoryAr";
import PostsByStarAr from "./Pages/PostsByStarAr";
import RelatedPostsAr from "./Pages/RelatedPostsAr";
import SearchPostAr from "./Pages/SearchPostAr";
import MostUsedTagAr from "./Pages/MostUsedTagAr";
import ContactUsAr from "./Pages/ContactUsAr";
import PrivacyPolicyAr from "./Pages/PrivacyPolicyAr";
import TermsOfUseAr from "./Pages/TermsOfUseAr";
import StarPageAr from "./Pages/StarPageAr";
import AboutUsAr from "./Pages/AboutUsAr";
import AllVideoAr from "./Pages/AllVideoAr";
import HomeFr from "./Pages/HomeFr";
import SingleBlogPostFr from "./Pages/SingleBlogPostFr";
import PostsFr from "./Pages/PostsFr";
import PostByCategoryFr from "./Pages/PostByCategoryFr";
import PostsByStarFr from "./Pages/PostsByStarFr";
import RelatedPostsFr from "./Pages/RelatedPostsFr";
import SearchPostFr from "./Pages/SearchPostFr";
import MostUsedTagFr from "./Pages/MostUsedTagFr";
import ContactUsFr from "./Pages/ContactUsFr";
import PrivacyPolicyFr from "./Pages/PrivacyPolicyFr";
import TermsOfUseFr from "./Pages/TermsOfUseFr";
import StarPageFr from "./Pages/StarPageFr";
import AboutUsFr from "./Pages/AboutUsFr";
import AllVideoFr from "./Pages/AllVideoFr";
import LanguageChanger from "./Common/LanguageChanger";
import HomeEs from "./Pages/HomeEs";
import SingleBlogPostEs from "./Pages/SingleBlogPostEs";
import PostsEs from "./Pages/PostsEs";
import PostByCategoryEs from "./Pages/PostByCategoryEs";
import PostsByStarEs from "./Pages/PostsByStarEs";
import RelatedPostsEs from "./Pages/RelatedPostsEs";
import SearchPostEs from "./Pages/SearchPostEs";
import MostUsedTagEs from "./Pages/MostUsedTagEs";
import ContactUsEs from "./Pages/ContactUsEs";
import PrivacyPolicyEs from "./Pages/PrivacyPolicyEs";
import TermsOfUseEs from "./Pages/TermsOfUseEs";
import StarPageEs from "./Pages/StarPageEs";
import AboutUsEs from "./Pages/AboutUsEs";
import AllVideoEs from "./Pages/AllVideoEs";

export default function App() {
  return (
    <BrowserRouter>
      <LanguageChanger />
      <Routes>
        <Route path="*" element={<ErrorPage />} />

        {/* ###################################### english ############################################# */}

        <Route path="/" element={<Home />} />
        <Route path="/single-blog-post/:id" element={<SingleBlogPost />} />
        <Route path="/posts" element={<Posts />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-use" element={<TermsOfUse />} />
        <Route path="/star-tags" element={<StarPage />} />
        <Route path="/site-map" element={<SiteMap />} />
        <Route path="/search-post" element={<SearchPost />} />
        <Route path="/most-used-tag" element={<MostUsedTag />} />
        <Route path="/all-video" element={<AllVideo />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/post-by-category/:id" element={<PostByCategory />} />
        <Route path="/posts-by-star-tags/:id" element={<PostsByStar />} />
        <Route path="/related-posts/:id" element={<RelatedPosts />} />
        <Route path="/fanzat-news" element={<FanzatNews />} />

        {/* ######################################### arabic ##############################################P */}

        <Route path="/ar" element={<HomeAr />} />
        <Route path="/ar/single-blog-post/:id" element={<SingleBlogPostAr />} />
        <Route path="/ar/posts" element={<PostsAr />} />
        <Route path="/ar/post-by-category/:id" element={<PostByCategoryAr />} />
        <Route path="/ar/posts-by-star-tags/:id" element={<PostsByStarAr />} />
        <Route path="/ar/related-posts/:id" element={<RelatedPostsAr />} />
        <Route path="/ar/search-post" element={<SearchPostAr />} />
        <Route path="/ar/most-used-tag" element={<MostUsedTagAr />} />
        <Route path="/ar/contact-us" element={<ContactUsAr />} />
        <Route path="/ar/privacy-policy" element={<PrivacyPolicyAr />} />
        <Route path="/ar/terms-of-use" element={<TermsOfUseAr />} />
        <Route path="/ar/star-tags" element={<StarPageAr />} />
        <Route path="/ar/site-map" element={<SiteMap />} />
        <Route path="/ar/about-us" element={<AboutUsAr />} />
        <Route path="/ar/all-video" element={<AllVideoAr />} />

        {/* ######################################### french ##############################################P */}

        <Route path="/fr" element={<HomeFr />} />
        <Route path="/fr/single-blog-post/:id" element={<SingleBlogPostFr />} />
        <Route path="/fr/posts" element={<PostsFr />} />
        <Route path="/fr/post-by-category/:id" element={<PostByCategoryFr />} />
        <Route path="/fr/posts-by-star-tags/:id" element={<PostsByStarFr />} />
        <Route path="/fr/related-posts/:id" element={<RelatedPostsFr />} />
        <Route path="/fr/search-post" element={<SearchPostFr />} />
        <Route path="/fr/most-used-tag" element={<MostUsedTagFr />} />
        <Route path="/fr/contact-us" element={<ContactUsFr />} />
        <Route path="/fr/privacy-policy" element={<PrivacyPolicyFr />} />
        <Route path="/fr/terms-of-use" element={<TermsOfUseFr />} />
        <Route path="/fr/star-tags" element={<StarPageFr />} />
        <Route path="/fr/about-us" element={<AboutUsFr />} />
        <Route path="/fr/all-video" element={<AllVideoFr />} />

        {/* ######################################### french ##############################################P */}

        <Route path="/es" element={<HomeEs />} />

        <Route path="/404-error" element={<ErrorPage />} />
        <Route path="/es/single-blog-post/:id" element={<SingleBlogPostEs />} />
        <Route path="/es/posts" element={<PostsEs />} />
        <Route path="/es/post-by-category/:id" element={<PostByCategoryEs />} />
        <Route path="/es/posts-by-star-tags/:id" element={<PostsByStarEs />} />
        <Route path="/es/related-posts/:id" element={<RelatedPostsEs />} />
        <Route path="/es/search-post" element={<SearchPostEs />} />
        <Route path="/es/most-used-tag" element={<MostUsedTagEs />} />
        <Route path="/es/contact-us" element={<ContactUsEs />} />
        <Route path="/es/privacy-policy" element={<PrivacyPolicyEs />} />
        <Route path="/es/terms-of-use" element={<TermsOfUseEs />} />
        <Route path="/es/star-tags" element={<StarPageEs />} />
        <Route path="/es/about-us" element={<AboutUsEs />} />
        <Route path="/es/all-video" element={<AllVideoEs />} />
      </Routes>
    </BrowserRouter>
  );
}
