import React from "react";
import "../CSS/Footer.css";
import Logo from "../images/fanzat_logo.png";
import { useNavigate } from "react-router-dom";
import ThreedLogo from "../images/fanzat_3dLogo.png";
import { Link } from "react-router-dom";
// import { Link } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();
  return (
    // <section className="footer_sec">
    //   <div className="container">
    //     <div className="footer_topBar">
    //       <div className="footer_topBarL">
    //         <h4>Follow Us</h4>
    //       </div>
    //       <div className="footer_topBarR">
    //         <i class="fab fa-linkedin-in"></i>
    //         <i class="fab fa-twitter"></i>
    //         <i class="fab fa-instagram"></i>
    //         <i class="fab fa-facebook-f"></i>
    //       </div>
    //     </div>
    //     <hr />
    //     <div className="footer_bottomBar">
    //       <div className="footer_bottomL">
    //         <img
    //           src={Logo}
    //           alt=""
    //           style={{ cursor: "pointer" }}
    //           onClick={() => navigate("/")}
    //         />
    //       </div>
    //       <div className="footer_bottomM">
    //         <ul>
    //           <li>Blogar Store</li>
    //           <li>Advertise with Us</li>
    //           <li>AdChoices</li>
    //           <li>Privacy Policy</li>
    //           <li>Contact Us</li>
    //         </ul>
    //       </div>
    //       <div className="footer_bottomR">
    //         <p>All Rights Reserved © 2023</p>
    //       </div>
    //     </div>
    //   </div>
    // </section>
    <section className="main_footer">
      <div className="container">
        <div className="footer_flex">
          <div className="footer_logoSec">
            <Link to={"/"}>
              <img src={ThreedLogo} alt="" />
            </Link>
            <div className="footer_social">
              <i class="fab fa-facebook-f"></i>
              <i class="fab fa-twitter"></i>
              <i class="fab fa-instagram"></i>
              <i class="fab fa-youtube"></i>
              <i class="fab fa-snapchat"></i>
              <i class="fab fa-linkedin-in"></i>
              <i class="fab fa-pinterest"></i>
            </div>
            <p>All Rights Reserved © 2023</p>
          </div>
          <div className="footer_advertise">
            <ul>
              {/* <li>
                <i class="fas fa-circle"></i>Advertise with Us
              </li> */}
              <Link to="/privacy-policy">
                <li>
                  <i class="fas fa-circle"></i>Privacy Policy
                </li>
              </Link>
              <Link to="/terms-of-use">
                <li>
                  <i class="fas fa-circle"></i>Terms of Use
                </li>
              </Link>
              {/* <Link to="/contact-us"> */}
              <a href="https://nnsm3.com/company/contact">
                <li>
                  <i class="fas fa-circle"></i>Contact Us
                </li>
              </a>
              {/* </Link> */}
            </ul>
          </div>
          <div className="footer_brandsOne">
            <h4>Fanzat help</h4>
            <h4>Fanzat news</h4>
            <h4>Fanzat blog</h4>
          </div>
          <div className="footer_brandsTwo">
            <h4>Fanny - AI</h4>
            <h4>Fanzat Bazar</h4>
            <h4>Fanzat Venue</h4>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
