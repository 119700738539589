import React, { useState, useEffect } from "react";
import DataService from "../services/data.service";
import "../CSS/ContactUs.css";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import contact from "../images/portrait-woman-customer-service-worker.jpg";
import { ToastContainer, toast } from "react-toastify";

function ContactUs() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState("");

  useEffect(() => {
    document.title = "Contact Us";
    window.scroll(0, 0);
    getData();
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      firstName.trim() === "" ||
      lastName.trim() === "" ||
      email.trim() === "" ||
      phone.trim() === ""
    ) {
      toast.error("All fields are required!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return; 
    }
    const data = {};
    data.firstName = firstName;
    data.lastName = lastName;
    data.email = email;
    data.phone = phone;
    data.description = description;

    DataService.addContact(data).then(
      () => {
        toast.success("Email submit successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setDescription("");
        setEmail("");
        setFirstName("");
        setLastName("");
        setPhone("");
      },

      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        toast.error(resMessage, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    );
  };

  const getData = async () => {
    await DataService.getContactUs().then((data) => {
      setData(data?.data?.data[0]);
    });
  };
  return (
    <>
      <Header />
      <div className="sec-banner-contact">
        <div className="main-tittle-banner">
          <h1>{data?.title?.english}</h1>
        </div>
      </div>
      <div className="top-sec-contact">
        <div className="container">
          <div className="main-column-contact">
            <div className="left-side-Ccontact">
              <div className="Lcontent-contact">

                <p
                  className="post-content-para"
                  dangerouslySetInnerHTML={{
                    __html: data?.description?.english,
                  }}
                ></p>
                <div className="social-icon-contact">
                  <h4>Stay In Touch</h4>
                  <ul className="conatct-main-icons">
                    <li>
                      <a href="#">
                        <i class="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        {" "}
                        <i class="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i class="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        {" "}
                        <i class="fas fa-link"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="right-side-Ccontact">
              <div className="Rcontent-conatct">
                <h2>Contac Us</h2>
                <form>
                  <div className="form-flex">
                    <div class="form-floating mb-3 form-contact">
                      <input
                        type="text"
                        class="form-control"
                        id="floatingInput"
                        placeholder="First Name"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                      <label for="floatingInput">First Name</label>
                    </div>
                    <div class="form-floating mb-3 form-contact">
                      <input
                        type="text"
                        class="form-control"
                        id="floatingText"
                        placeholder="Last Name"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                      <label for="floatingText">Last Name</label>
                    </div>
                  </div>
                  <div className="form-flex">
                    <div class="form-floating mb-3 form-contact">
                      <input
                        type="email"
                        class="form-control"
                        id="floatingInput"
                        placeholder="name@example.com"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <label for="floatingInput">Email address</label>
                    </div>
                    <div class="form-floating mb-3 form-contact">
                      <input
                        type="number"
                        class="form-control"
                        id="floatingInput"
                        placeholder="Phone Number"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                      <label for="floatingInput">Phone Number</label>
                    </div>
                  </div>

                  <div className="comment-box-form contact-Fcomment">
                    <div class="form-floating">
                      <textarea
                        class="form-control"
                        placeholder="Leave a comment here"
                        id="floatingTextarea"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      ></textarea>
                      <label for="floatingTextarea">Leave a Reply</label>
                    </div>
                  </div>
                  <div className="post-form-btn contact-Fbtn">
                    <button onClick={handleSubmit}>Leave a Comment</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default ContactUs;
