import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DataService from "../services/data.service";
import "../CSS/SearchPost.css";

import NewsLetterFr from "../Common/NewsLetterFr";
import FooterFr from "../Common/FooterFr";
import HeaderFr from "../Common/HeaderFr";

function SearchPostFr() {
  const location = useLocation();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const queryParams = new URLSearchParams(location.search);
  const param1 = queryParams.get("param1");
  const [loading, setLoading] = useState(false);

  const getSearchPosts = async () => {
    setLoading(true);
    await DataService.getSearchPostFr(param1).then((data) => {
      setSearch(data?.data?.data);
      setLoading(false);
    });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Search Post";
    getSearchPosts();
  }, [param1]);
  return (
    <>
      <HeaderFr />
      <div className="top-sec-search">
        <div className="container">
          {loading && (
            <div className="loader-spin">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          )}
          <h2>Chercher des nouvelles</h2>

          <div className="search-post-SPbox">
            {search && search.length > 0 ? (
              search
                .filter((item) => item.status === "publish")

                .map((item, index) => {
                  const postDate = new Date(item?.date);
                  const formattedDate = `${postDate.toLocaleDateString()} ${postDate.toLocaleTimeString()}`;
                  return (
                    <>
                      <div className="related-post-Bcolumn related-post-bcolumn3">
                        <a href={"/fr/single-blog-post/" + item._id}>
                          <div className="Bcolumn-img">
                            <img
                              src={
                                "https://fanzat-news.onrender.com" +
                                item?.featured_media?.source_url
                              }
                              className="product-img"
                              alt="image"
                            />
                          </div>
                          <div className="all-post-category1">
                            {item?.categories
                              .filter((category) => category.type === "1")
                              .map((category) => (
                                <h6>{category.name?.french}</h6>
                                // <h6 key={category._id}>{category.name?.arabic}</h6>
                              ))}
                          </div>
                          <div className="Bcolumn-content">
                            <h4>
                              {/* {item?.title?.rendered */}
                              {item?.title?.french
                                .split(" ")
                                .slice(0, 6)
                                .join(" ")}
                            </h4>
                            <p
                              className="para-arabic-comntent"
                              dangerouslySetInnerHTML={{
                                __html: item?.content?.french
                                  .split(" ")
                                  .slice(0, 10)
                                  .join(" "),
                              }}
                            ></p>
                            {/* <div className="Bcolumn-btn">
                              <button>Read More</button>
                            </div> */}
                          </div>
                        </a>
                      </div>
                    </>
                  );
                })
            ) : (
              <p>Aucune actualité trouvée</p>
            )}
          </div>
        </div>
      </div>
      <NewsLetterFr />
      <FooterFr />
    </>
  );
}

export default SearchPostFr;
