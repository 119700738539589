import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const LanguageChanger = ({ children }) => {
    const location = useLocation();

    useEffect(() => {
        // Determine if the language is Arabic based on the route
        const isArabic = location.pathname.startsWith('/ar');

        // Add or remove classes based on the language
        if (isArabic) {
            document.body.classList.add('arabic-font');
            document.body.classList.remove('english-font');
        } else {
            document.body.classList.add('english-font');
            document.body.classList.remove('arabic-font');
        }

        // Clean up effect
        return () => {
            document.body.classList.remove('arabic-font', 'english-font');
        };
    }, [location]);

    return <div>{children}</div>;
};

export default LanguageChanger;
