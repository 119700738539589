import React, { useState, useEffect } from "react";
import "../CSS/ContactUsAr.css";

import contact from "../images/portrait-woman-customer-service-worker.jpg";
import DataService from "../services/data.service";
import { ToastContainer, toast } from "react-toastify";
import FooterAr from "../Common/FooterAr";
import NewsLetterAr from "../Common/NewsLetterAr";
import HeaderAr from "../Common/HeaderAr";

function ContactUsAr() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Contact Us";
    getData();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      firstName.trim() === "" ||
      lastName.trim() === "" ||
      email.trim() === "" ||
      phone.trim() === ""
    ) {
      toast.error("All fields are required!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return; 
    }
    const data = {};
    data.firstName = firstName;
    data.lastName = lastName;
    data.email = email;
    data.phone = phone;
    data.description = description;

    DataService.addContact(data).then(
      () => {
        toast.success("Email submit successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setDescription("");
        setEmail("");
        setFirstName("");
        setLastName("");
        setPhone("");
      },

      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);
        toast.error(resMessage, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    );
  };
  const getData = async () => {
    await DataService.getContactUsAr().then((data) => {
      setData(data?.data?.data[0]);
    });
  };

  return (
    <>
      <HeaderAr />
      <div className="sec-banner-contact1">
        <div className="main-tittle-banner1">
          <h1>{data?.title?.arabic}</h1>
        </div>
      </div>
      <div className="top-sec-contact1">
        <div className="container">
          <div className="main-column-contact1">
            <div className="left-side-Ccontact1">
              <div className="Lcontent-contact1">
                <p
                  className="post-content-para1"
                  dangerouslySetInnerHTML={{
                    __html: data?.description?.arabic,
                  }}
                ></p>
                <div className="social-icon-contact1">
                  <h4>كن دائما متواصل </h4>
                  <ul className="conatct-main-icons1">
                    <li>
                      <a href="#">
                        <i class="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        {" "}
                        <i class="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i class="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        {" "}
                        <i class="fas fa-link"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="right-side-Ccontact1">
              <div className="Rcontent-conatct1">
                <h2>تواصل معنا </h2>
                <form>
                  <div className="form-flex1">
                    <div class=" mb-3 form-contact1">
                      <input
                        required
                        type="text"
                        class="form-control"
                        id="floatingInput"
                        placeholder="الأسم الأول"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                      {/* <label for="floatingInput">الأسم الأول </label> */}
                    </div>
                    <div class=" mb-3 form-contact1">
                      <input
                        required
                        type="text"
                        class="form-control"
                        id="floatingText"
                        placeholder="الاسم الثاني"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                      {/* <label for="floatingText">الاسم الثاني </label> */}
                    </div>
                  </div>
                  <div className="form-flex1">
                    <div class=" mb-3 form-contact1">
                      <input
                        required
                        type="email"
                        class="form-control"
                        id="floatingInput"
                        placeholder="أدخل بريدك الالكتروني "
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      {/* <label for="floatingInput">أدخل بريدك الالكتروني </label> */}
                    </div>
                    <div class=" mb-3 form-contact1">
                      <input
                        required
                        type="number"
                        class="form-control"
                        id="floatingInput"
                        placeholder="رقم الهاتف"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                      {/* <label for="floatingInput">رقم الهاتف </label> */}
                    </div>
                  </div>

                  <div className="comment-box-form1 contact-Fcomment1">
                    <div class="">
                      <textarea
                        class="form-control"
                        placeholder="اترك تعليقك"
                        id="floatingTextarea"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      ></textarea>
                      {/* <label for="floatingTextarea">اترك ردك </label> */}
                    </div>
                  </div>
                  <div className="post-form-btn1 contact-Fbtn1">
                    <button onClick={handleSubmit}>اترك تعليقك </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NewsLetterAr />
      <FooterAr />
    </>
  );
}

export default ContactUsAr;
