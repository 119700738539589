import React, { useState, useEffect } from "react";
import "../CSS/SingleBlogPostAr.css";
import Slider from "react-slick";
import hj from "../images/ikj.jpg";
import teams from "../images/img-teams.png";
import Info2 from "../images/info-2.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import DataService from "../services/data.service";
import moment from "moment";
import { Link } from "react-router-dom";
import SharePost from "../Common/SharePost";
import { Helmet } from "react-helmet";
import parse from "html-react-parser";
import NewsLetterAr from "../Common/NewsLetterAr";
import FooterAr from "../Common/FooterAr";
import HeaderAr from "../Common/HeaderAr";

function SingleBlogPostAr() {
  const params = useParams();

  const [data, setData] = useState();
  const [dataB, setDataB] = useState();
  const [loading, setLoading] = useState(false);
  const [postdate, setPostDate] = useState();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [reply, setReply] = useState("");
  const [Anews, setANews] = useState("");
  const [newsC, setNewsC] = useState("");
  const [relatedId, setRelatedId] = useState("");
  const [totalCount, setTotalCount] = useState("");
  const [count, setCount] = useState(1);
  const [mostPTag, setMostPTag] = useState("");
  const [tag, setTag] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [starData, setStarData] = useState("");
  const [sendEmail, setSendEmail] = useState("");
  const [image, setImage] = useState("");

  // const [category, setCategory] = useState([]);

  // const [subcategory, setBData] = useState([]);

  const [masterCategory, setMasterCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);

  const getAllCategory = async () => {
    await DataService.getAllCategory(1).then((data) => {
      setMasterCategory(data?.data?.categories);
    });
    await DataService.getAllCategory(2).then((data) => {
      setSubCategory(data?.data?.categories);
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getAllCategory();
  }, []);
  useEffect(() => {
    document.title = data?.title?.arabic ? data?.title?.arabic : "Single Blog";
  }, [data]);

  const singleblog = async () => {
    setLoading(true);
    await DataService.getSingleBlog(params.id).then((data) => {
      // if (data.data.data.status === 'pending') {
      //   window.location.href = '/404-error';
      //   return;
      // }
      setData(data.data.data);
      setImage(data?.data?.data?.featured_media?.source_url);
      setPostDate(data?.data?.data?.post_date);
      setCategoryId(data?.data?.data?.categories[1]?.id);
      setLoading(false);
      const categoryId = data?.data?.data?.categories[1]?.id;
      if (categoryId) {
        getRelatedPosts(categoryId);
      }
    });
  };
  useEffect(() => {
    singleblog();
    getAllPopularNewsNews();
    // getAllMostTags();
    getAllTags();
  }, []);

  const getAllPopularNewsNews = async () => {
    await DataService.getAllPopularNewsAr().then((data) => {
      setANews(data?.data?.data);
    });
  };
  const getRelatedPosts = async (id) => {
    await DataService.getACategoryByIdAr(id).then((data) => {
      setNewsC(data?.data?.data?.posts);
    });
  };

  // const getAllMostTags = async () => {
  //   await DataService.getAllMostTag().then((data) => {
  //     setMostPTag(data?.data?.data);
  //   });
  // };
  const getAllTags = async () => {
    await DataService.getAllTagAr().then((data) => {
      setTag(data?.data?.data?.tags);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {};
    data.comment_author = name;
    data.comment_author_email = email;
    data.comment_author_url = website;
    data.comment_content = reply;
    data.comment_post_ID = params.id;

    DataService.AddComment(data).then(
      () => {
        toast.success("Comment Posted successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      },

      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        toast.error(resMessage, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    );
  };
  const handleEmailSubmit = (e) => {
    e.preventDefault();
    if (!sendEmail.trim()) {
      return;
    }
    const data = {};
    data.email = sendEmail;

    DataService.addEmail(data).then(
      () => {
        toast.success("Email submit successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setSendEmail("");
      },

      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        toast.error(resMessage, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    );
  };

  // const allComments = async () => {
  //   await DataService.getComments(params.id).then((data) => {
  //     setDataB(data?.data);
  //     setLoading(false);
  //   });
  // };

  const allComments = async () => {
    await DataService.getComments(params.id).then((data) => {
      setDataB(data?.data.filter((item) => item.comment_status === "approved"));
      setTotalCount(data.data?.length);
      setLoading(false);
    });
  };
  useEffect(() => {
    allComments();
    // getAllCategory();
  }, []);

  const handleViewMore = () => {
    setCount((prevCount) => prevCount + 1);
  };
  const handleLessView = () => {
    setCount((prevCount) => prevCount - 1);
  };
  // const getAllCategory = async () => {
  //   await DataService.getAllCategory().then((data) => {
  //     console.log("hello");
  //     console.log(data.data);
  //     setCategory(data?.data?.data);
  //   });
  // };

  const formattedDate = postdate ? moment(postdate).format("MMM DD YYYY") : "";

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <Helmet>
        <meta name="description" content={data?.title?.arabic} />
        <meta property="og:description" content={data?.title?.arabic} />
        <meta
          property="og:image"
          content={
            "https://fanzat-news.onrender.com" +
            data?.featured_media?.source_url
          }
        />
        <meta property="og:title" content={data?.title?.arabic} />
        <meta
          property="og:url"
          content={`https://fanzat-news-arabic.vercel.app/single-blog-post/${params.id}`}
        />
      </Helmet>
      <HeaderAr />
      <section className="singlePost-topSec1">
        <ToastContainer></ToastContainer>

        <div className="container">
          {loading && (
            <div className="loader-spin">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          )}
          <div className="topsec-maincolumn1">
            <div className="main-column-L1">
              <div className="columnL-top-banner1">
                <div className="post-cat1">
                  {/* <a href="#">{" "} */}
                  {data?.categories
                    .filter((category) => category.type === "1")
                    .map((category) => (
                      <h6>{category.name?.arabic}</h6>
                    ))}
                  {/* </a> */}
                </div>
                <div className="post-main-heading1">
                  <h1>{data?.title?.arabic}</h1>
                </div>

                <div className="post-meta-wrap1">
                  <div className="post-author-avtar1">
                    {/* <img
                      src={
                        "https://fanzat-news.onrender.com/" +
                        data?.featured_media?.media_details?.sizes?.full?.source_url
                      }
                      // src={data?.images?.path ? data?.image?.path : "../assets/img/profile-img.png"}
                      className="product-img"
                      alt="image"
                    /> */}
                    <div className="post-author-content1">
                      {/* <h6>{data?.user_ID?.user_nicename}</h6> */}
                      <ul>
                        {/* <li>{formattedDate}</li> */}
                        {/* <li>300K Views</li> */}
                      </ul>
                    </div>
                  </div>

                  <div className="social-icon-post1">
                    <SharePost
                      title={data?.title?.arabic}
                      url={`https://fanzat-news-arabic.vercel.app/single-blog-post/${params.id}`}
                    />
                    {/* <SharePost
                      url={`https://fanzat-news-arabic.vercel.app/single-blog-post/${params.id}`}
                      title={data?.title?.arabic}
                      imageUrl={
                        "https://fanzat-news.onrender.com" +
                        data?.featured_media?.source_url
                      }
                      description={data?.content?.arabic}
                    /> */}

                    {/* <ul>
                      <li>
                        <i class="fab fa-facebook-f"></i>
                      </li>
                      <li>
                        <i class="fab fa-twitter"></i>
                      </li>
                      <li>
                        <i class="fab fa-instagram"></i>
                      </li>
                      <li>
                        <i class="fas fa-link"></i>
                      </li>
                    </ul> */}
                  </div>
                </div>
              </div>
              <div className="singlepost-details1">
                {/* <div className=" banner-image-slider">
                  <Slider {...settings}>
                    <div>
                      <div className="banner-sub-slider">
                        <div className="banner-sub-over"></div>
                        <img
                          src="https://news.fanzat.com/wp-content/uploads/2019/12/NNSM3.jpg"
                          alt=""
                        />
                      </div>
                    </div>
                  </Slider>
                </div> */}
                <div className="singlepost-content1">
                  <div className="single-post-image1">
                    <img
                      src={
                        "https://fanzat-news.onrender.com" +
                        data?.featured_media[0]?.source_url
                      }
                      className="product-img"
                      alt="image"
                    />
                  </div>
                  <div className="sub-featured-images11">
                      {data?.featured_media.length > 1 &&
                        data.featured_media.slice(1).map((item, index) => (
                          <div
                            className="sub-images-single-post11"
                            key={index + 1}
                          >
                            <img
                              src={
                                "https://fanzat-blogs-api.onrender.com" +
                                item.source_url
                              }
                              alt={"Image " + (index + 1)}
                            />
                          </div>
                        ))}
                    </div>


                  <p
                    className="post-content-para1"
                    dangerouslySetInnerHTML={{
                      __html: data?.content?.arabic
                        ? data?.content?.arabic
                        : "",
                    }}
                  ></p>

                  {/* Popular tags ################################################################ */}

                  {/* <div className="post-section4">
                    <div className="container">
                      <h2>Popular Tags</h2>
                      <div className="post-sec4-allcategory">
                        <div className="sec4-allCat-btn">
                          {tag && tag.length > 0 ? (
                            tag.map((item, index) => {
                              return (
                                <>
                                  <button>
                                    {item?.tags?.arabic}
                                  </button>
                                </>
                              );
                            })
                          ) : (
                            <p>لا يوجد وسوم نشطة حاليا </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div> */}

                  <div className="preview-reviews-heading1">
                    <h3>شاهد المراجعات </h3>
                  </div>
                  {/* {dataB && dataB.length > 0 ? (
                dataB
                  .filter((item) => item.comment_status === "approved")
                  .slice(0, 4)
                  .map((item, index) => {
                    <div className="post-Labout-author1">
                    <div className="Labout-details1">
                      <div className="Lauthor-img">
                  <img src={hj} alt="" />
                </div>
                      <div className="L-author-data1">
                        <h5>{item?.comment_author}</h5>

                        <p>{item?.comment_content}</p>
                        <div className="L-author-social-link">
                    <ul>
                      <li>
                        <a href="">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                      </div>
                    </div>
                  </div>
                  })
                  ) : (
                    <p>No comments Found</p>
                  )} */}
                  {dataB && dataB.length > 0 ? (
                    dataB.slice(0, count).map((item, index) => (
                      <div key={index} className="post-Labout-author1">
                        <div className="Labout-details1">
                          <div className="L-author-data1">
                            <h5>{item?.comment_author}</h5>
                            <p>{item?.comment_content}</p>
                            {/* <div className="L-author-social-link">
                            </div> */}
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>لا توجد تعليقات معتمدة متاحة.</p>
                  )}
                  <div className="comments-btn-main1">
                    {dataB?.length > 1 && count !== totalCount ? (
                      // {dataB?.length > 1 && dataB?.length < totalCount && (
                      <div className="comments-more1">
                        <button
                          className="main-btn1"
                          // disabled={dataB && dataB.length === count}
                          onClick={handleViewMore}
                        >
                          شاهد المزيد
                        </button>
                      </div>
                    ) : (
                      ""
                    )}

                    {count !== 1 ? (
                      <div className="comments-more1 less-more-btn1">
                        <button className="main-btn1" onClick={handleLessView}>
                          السابق
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="single-post-form1">
                    <div className="post-form-heading1">
                      <h4>انشر تعليقك </h4>
                    </div>
                    <form>
                      <div className="form-flex1">
                        <div class="form-floating mb-3">
                          <input
                            type="text"
                            class="form-control"
                            id="floatingInput"
                            placeholder="اسمك "
                            onChange={(e) => setName(e.target.value)}
                          />
                          <label for="floatingInput flotingInput-text">
                            اسمك
                          </label>
                        </div>
                        <div class="form-floating mb-3">
                          <input
                            type="email"
                            class="form-control"
                            id="floatingInput"
                            placeholder="name@example.com"
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          <label for="floatingInput flotingInput-text">
                            أدخل بريدك الالكتروني
                          </label>
                        </div>
                        <div class="form-floating mb-3">
                          <input
                            type="text"
                            class="form-control"
                            id="floatingText"
                            placeholder="Text"
                            onChange={(e) => setWebsite(e.target.value)}
                          />
                          <label for="floatingText">موقعك الاليكتروني </label>
                        </div>
                      </div>
                      <div className="comment-box-form1">
                        <div class="form-floating">
                          <textarea
                            class="form-control"
                            placeholder="اترك تعليقك  here"
                            id="floatingTextarea"
                            onChange={(e) => setReply(e.target.value)}
                          ></textarea>
                          <label for="floatingTextarea flotingInput-text">
                            اترك ردك
                          </label>
                        </div>
                      </div>
                      <div className="post-form-btn1">
                        <button onClick={handleSubmit}>اترك تعليقك </button>
                      </div>
                    </form>
                  </div>
                  {/* {data && data.length > 0
            ? data.map((item, i) => ( */}

                  {/* <div className="post-comment-area">
                    <div className="post-comment-column">
                      <div className="post-comment-img">
                        <img src={hj} alt="" />
                      </div>
                      <div className="post-comment-content">
                        <p className="comment-author-name">Lorem ipsum dolor</p>
                        <div className="post-comment-time">
                          <p className="comment-date-time">Lorem ipsum dolor</p>
                          <p className="comment-reply">
                            <i class="fas fa-circle"></i> Reply{" "}
                          </p>
                        </div>
                        <p className="post-comment-para">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim.
                        </p>
                      </div>
                    </div>
                  </div> */}

                  {/* <div className="post-comment-area1">
                    <div className="post-comment-column">
                      <div className="post-comment-img">
                        <img
                          src="https://news.fanzat.com/wp-content/uploads/2019/12/NNSM3.jpg"
                          alt=""
                        />
                      </div>
                      <div className="post-comment-content">
                        <p className="comment-author-name">Lorem ipsum dolor</p>
                        <div className="post-comment-time">
                          <p className="comment-date-time">Lorem ipsum dolor</p>
                          <p className="comment-reply">
                            <i class="fas fa-circle"></i> Reply{" "}
                          </p>
                        </div>
                        <p className="post-comment-para">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim.
                        </p>
                      </div>
                    </div>
                  </div> */}
                  {/* <div className="post-comment-area">
                    <div className="post-comment-column">
                      <div className="post-comment-img">
                        <img
                          src="https://news.fanzat.com/wp-content/uploads/2019/12/NNSM3.jpg"
                          alt=""
                        />
                      </div>
                      <div className="post-comment-content">
                        <p className="comment-author-name">Lorem ipsum dolor</p>
                        <div className="post-comment-time">
                          <p className="comment-date-time">Lorem ipsum dolor</p>
                          <p className="comment-reply">
                            {" "}
                            <i class="fas fa-circle"></i> Reply{" "}
                          </p>
                        </div>
                        <p className="post-comment-para">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim veniam, quis
                          nostrud exercitation ullamco laboris nisi ut aliquip
                          ex ea commodo consequat. Duis aute irure dolor in
                          reprehenderit in voluptate velit esse cillum dolore eu
                          fugiat nulla pariatur.
                        </p>
                      </div>
                    </div>
                  </div> */}
                  <div className="related-post-bottom1">
                    <div className="related-post-top-bar1">
                      <div className="related-post-top-heading1">
                        <h2>أخبار متشابهة </h2>
                      </div>

                      <div className="related-posts-btn1">
                        <Link to={"/ar/related-posts/" + categoryId}>
                          <button className="trending-top-btn1F1">
                            شاهد المزيد{" "}
                          </button>
                        </Link>
                      </div>
                    </div>
                    <div className="related-post-Bbox1">
                      {newsC && newsC.length > 0 ? (
                        newsC
                          .filter(
                            (item) =>
                              item.status === "publish" &&
                              item?._id !== data?._id
                          )
                          .slice(0, 2)
                          .map((item, index) => {
                            const postDate = new Date(item.date);
                            const formattedDate = `${postDate.toLocaleDateString()} ${postDate.toLocaleTimeString()}`;
                            return (
                              <>
                                <div className="related-post-Bcolumn1">
                                  <a href={"/ar/single-blog-post/" + item._id}>
                                    <div className="Bcolumn-img">
                                      <img
                                        src={
                                          "https://fanzat-news.onrender.com" +
                                          item?.featured_media?.source_url
                                        }
                                        className="product-img"
                                        alt="image"
                                      />
                                    </div>
                                    <div className="Bcolumn-content1">
                                      <h4>
                                        {" "}
                                        {item?.title?.arabic
                                          ?.split(" ")
                                          .slice(0, 8)
                                          .join(" ")}
                                      </h4>

                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: item.content?.arabic
                                            ?.split(" ")
                                            .slice(0, 12)
                                            .join(" "),
                                        }}
                                      ></p>
                                      {/* <div className="Bcolumn-btn">
                                        {" "}
                                        <button>Read More</button>{" "}
                                      </div> */}
                                    </div>
                                  </a>
                                </div>
                              </>
                            );
                          })
                      ) : (
                        <p>لا توجد أخبار حاليا </p>
                      )}

                      {/* <div className="related-post-Bcolumn1">
                        <div className="Bcolumn-img">
                          <img src="https://news.fanzat.com/wp-content/uploads/2019/12/NNSM3.jpg" />
                        </div>
                        <div className="Bcolumn-content1">
                          <h5>Lorem ipsum</h5>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor
                          </p>

                          <div className="Bcolumn-btn">
                            <button>Read More</button>
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="main-column-R1">
              <div className="sidebar-Rcolumn1">

              <div className="video-section-singlePost11">
                  {data?.video?.source_url && (
                    <iframe
                      width="560"
                      height="315"
                      src={`https://fanzat-blogs-api.onrender.com${data.video.source_url}`}
                      title="Embedded Video"
                      frameBorder="0"
                      allowFullScreen
                    ></iframe>
                  )}
                </div>

                {/* <div className="search-box1">
                  <h5>Search</h5>
                  <div
                    class="search_bar1 post-search-bar1"
                    style={{ marginTop: "15px" }}
                  >
                    <input type="search" placeholder="Search..." />
                  </div>
                </div> */}

                <div className="popular-post1">
                  <h5>الأخبار المتداولة </h5>
                  <div className="post-medium-blogs1">
                    {Anews && Anews.length > 0 ? (
                      Anews.filter((item) => item.status === "publish")
                        .slice(0, 4)
                        .map((item, index) => {
                          const postDate = new Date(item.date);
                          const formattedDate = `${postDate.toLocaleDateString()} ${postDate.toLocaleTimeString()}`;
                          return (
                            <>
                              <a href={"/ar/single-blog-post/" + item._id}>
                                <div className="pop_postFlex1">
                                  <div className="pop_postFlex1L1 post-popular-image1">
                                    <img
                                      src={
                                        "https://fanzat-news.onrender.com" +
                                        item?.featured_media?.source_url
                                      }
                                      className="product-img"
                                      alt="image"
                                    />
                                  </div>
                                  <div className="pop_postFlex1R1 popular-post1-heading1">
                                    <div className="post_title1Sec11">
                                      <h3 className="post_title1">
                                        {item?.title?.arabic
                                          ?.split(" ")
                                          .slice(0, 12)
                                          .join(" ")}
                                      </h3>
                                    </div>
                                    <span>{formattedDate}</span>
                                  </div>
                                </div>
                              </a>
                            </>
                          );
                        })
                    ) : (
                      <p>لا توجد أخبار حاليا </p>
                    )}
                    {/* <div className="post-medium-content">
                      <div className="post-medium-img">
                        <img src="https://news.fanzat.com/wp-content/uploads/2019/12/Screen-Shot-2019-12-10-at-10.46.15-AM.png" />
                      </div>
                      <div className="post-medium-para">
                        <h6>
                          Instagram overtakes YouTube as the top for influencer
                          content-T
                        </h6>
                        <div className="post-medium-meta">
                          <ul>
                            <li>THURSDAY/21</li>
                            <li>NOVEMBER – 2019</li>
                          </ul>
                        </div>
                      </div>
                    </div> */}
                    {/* <div className="post-medium-content">
                      <div className="post-medium-img">
                        <img src="https://news.fanzat.com/wp-content/uploads/2019/12/nnsm3-7.jpg" />
                      </div>
                      <div className="post-medium-para">
                        <h6>
                          Five trends shaping influencer marketing in 2019–T
                        </h6>
                        <div className="post-medium-meta">
                          <ul>
                            <li>THURSDAY/21</li>
                            <li>NOVEMBER – 2019</li>
                          </ul>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div className="post-newsletter1">
                  <div post-newsletter1-inner>
                    <h5>لا تفوت أي خبر </h5>
                    <p>سجل الان مجانا لتكن أول من يعرف اخر الاخبار</p>
                    <div className="search-box1 newsletter-search1">
                      <div
                        class="search_bar1 post-search-bar1"
                        style={{ marginTop: "15px" }}
                      >
                        <input
                          required
                          type="search"
                          value={sendEmail}
                          placeholder="أدخل بريدك الالكتروني "
                          onChange={(e) => setSendEmail(e.target.value)}
                        />
                        <div className="newsletter-search1-button1">
                          <button
                            className="main-btn1"
                            onClick={handleEmailSubmit}
                          >
                            اشترك{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div class="post-Rthumbnail">
                  <img
                    src="https://new.axilthemes.com/demo/react/blogar/images/post-single/ads-01.webp?imwidth=640"
                    className="post-Rthumbnail-img"
                  />
                </div> */}
                <div className="post-Rsocial-icon1">
                  <h5>كن دائما متواصل </h5>
                  <ul className="post-main-icons">
                    <li>
                      <a href="#">
                        <i class="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        {" "}
                        <i class="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i class="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        {" "}
                        <i class="fas fa-link"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                {/* <div className="post-Rcategory">
                  <h5>Category</h5>
                  <div className="post-Rsub-category">
                    {masterCategory && masterCategory.length > 0 ? (
                      masterCategory.map((item) => {
                        return (
                          <>
                            <button>{item?.name?.arabic}</button>

                            <div className="mega-menu-cate1 mega-menu-cate21">
                                <h4>{item?.name?.arabic}</h4>
                                <ul>
                                  {item._id === parentId ? (
                                    subCategory.map((subcategoryItem) => (
                                      <li key={subcategoryItem._id}>{subcategoryItem?.name?.arabic}</li>
                                    ))
                                  ) : (
                                    <li>No Sub Category</li>
                                  )}
                                </ul>
                              </div>
                          </>
                        );
                      })
                    ) : (
                      <p>لا توجد أقسام حاليا </p>
                    )}
                  </div>
                </div> */}
                <div className="info-box-registation1">
                  <div className="info-sub-boxR1">
                    <h4>هل أنت مؤثر مشهور؟</h4>
                    <div className="info-image-box1">
                      <img src={teams} />
                    </div>
                    <h5>ابدأ رحلتك في ننسمع وحقق أعلى مستويات الوصول</h5>
                    <div className="info-box-btn1">
                      <a href="https://nnsm3.com/register/star">
                        <button>سجل كنجم</button>
                      </a>
                    </div>
                  </div>
                  <div className="info-sub-boxR1">
                    <h4>هل لديك تأثيراً حقيقياً ومميزاً؟ </h4>
                    <div className="info-image-box1">
                      <img
                        src="https://news.fanzat.com/wp-content/themes/nnsm3-news/images/info-2.png"
                        alt="image"
                      />
                    </div>
                    <h5>
                      استخدام نفوذك المميز في مجالك؛ وحقق مع ننسمع نتائج فوق
                      مستوى التوقعات
                    </h5>
                    <div className="info-box-btn1">
                      <a href="https://nnsm3.com/register/pulsar">
                        <button>تسجيل البولسار </button>
                      </a>
                    </div>
                  </div>
                  <div className="info-sub-boxR1">
                    <h4>علامة تجارية / شركة ؟ </h4>
                    <div className="info-image-box1">
                      <img
                        src="https://news.fanzat.com/wp-content/themes/nnsm3-news/images/info-3.png"
                        alt="image"
                      />
                    </div>
                    <h5>
                      اختر المؤثر المناسب لمجالك، وتتبع حملاتك الناجحة على منصة
                      واحدة (ننسمع)
                    </h5>
                    <div className="info-box-btn1">
                      <a href="https://nnsm3.com/register/orbit">
                        <button>تسجيل الأفلاك </button>
                      </a>
                    </div>
                  </div>
                  <div className="info-sub-boxR1">
                    <h4>هل أنت فان </h4>
                    <div className="info-image-box1">
                      <img
                        src="https://news.fanzat.com/wp-content/themes/nnsm3-news/images/info-3.png"
                        alt="image"
                      />
                    </div>
                    <h5>Lore ipsum is the dummy text standard industry</h5>
                    <div className="info-box-btn1">
                      <a href="">
                        <button> سجل كفان </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <NewsLetterAr />
      <FooterAr />
    </>
  );
}

export default SingleBlogPostAr;
