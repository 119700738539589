import React, { useEffect, useState } from "react";
import "../CSS/StarPage.css";
import DataService from "../services/data.service";
import { ToastContainer, toast } from "react-toastify";

import { Link } from "react-router-dom";
import HeaderAr from "../Common/HeaderAr";
import NewsLetterAr from "../Common/NewsLetterAr";
import FooterAr from "../Common/FooterAr";

function StarPageAr() {
  const [starTag, setStarTag] = useState("");
  const [dataT, setDataT] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const postsPerPage = 20;
  const [TotalTagsCount, setTotalTagsCount] = useState("");
  // const [pagination, setPagination] = useState(true);

  // const getAllStarTag = async () => {
  //   await DataService.getAllStarTags().then((data) => {
  //     setStarTag(data?.data?.data);
  //   });
  // };
  // useEffect(() => {
  //   document.title = "Star Tags";
  //   getAllStarTag();
  // }, []);

  useEffect(() => {
    document.title = "Star Tags";
    const getAllStarTag = async () => {
      await DataService.getAllStarTagsAr().then((data) => {
        setStarTag(data?.data?.data);
        setTotalTagsCount(data?.data?.totalStarTags);
        window.scrollTo(0, 0);
      });
    };

    getAllStarTag();
    getAllStarTags(currentPage);
  }, []);

  const getAllStarTags = async (newPage) => {
    setLoading(true);
    await DataService.getAllStarTagAr(newPage).then((data) => {
      setDataT(data?.data?.data);
      setLoading(false);
    });
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    getAllStarTags(newPage);
  };

  const totalPages = Math.ceil(TotalTagsCount / postsPerPage);

  const visiblePageButtons = 5;

  const renderPageButtons = () => {
    const pageButtons = [];
    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 ||
        (i >= currentPage - Math.floor(visiblePageButtons / 2) &&
          i <= currentPage + Math.floor(visiblePageButtons / 2))
      ) {
        pageButtons.push(
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={currentPage === i ? "active-btn" : ""}
          >
            {i}
          </button>
        );
      }
    }
    return pageButtons;
  };
  return (
    <>
      <HeaderAr />
      <div className="top-sec-star1">
        {loading && (
          <div className="loader-spin">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        )}
        <div className="container">
          <div className="star-top-navbar1">
            <h2>جميع النجوم </h2>

            <div className="main-startag-page">
              {dataT && dataT.length > 0 ? (
                dataT?.map((item) => {
                  return (
                    <>
                      <div className="star-tag-page">
                        <div className="startag-img-page1">
                          <img
                            src={
                              "https://fanzat-news.onrender.com" +
                              item?.image?.url
                            }
                            className="product-img"
                            alt="image"
                          />
                        </div>
                        <Link to={`/ar/posts-by-star-tags/${item.TermID}`}>
                          <h4> {item?.starTags?.arabic}</h4>
                        </Link>
                      </div>
                    </>
                  );
                })
              ) : (
                <p>لا توجد اشارات لنجوم حاليا </p>
              )}
            </div>
            <div className="show-more-posts-btn1">
              {currentPage > 1 && (
                <button onClick={() => handlePageChange(currentPage - 1)}>
                  {"< السابق "}
                </button>
              )}
              {renderPageButtons()}
              {currentPage < totalPages && (
                <button onClick={() => handlePageChange(currentPage + 1)}>
                  {"التالي  >"}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <NewsLetterAr />
      <FooterAr />
    </>
  );
}

export default StarPageAr;
