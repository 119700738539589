import React, { useEffect, useState } from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import "../CSS/StarPage.css";
import DataService from "../services/data.service";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import NewsLetter from "../Common/NewsLetter";

function StarPage() {
  const [starTag, setStarTag] = useState("");
  const [dataT, setDataT] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const postsPerPage = 20;
  const [TotalTagsCount, setTotalTagsCount] = useState("");

  // const getAllStarTag = async () => {
  //   await DataService.getAllStarTags().then((data) => {
  //     setStarTag(data?.data?.data);
  //   });
  // };
  // useEffect(() => {
  //   document.title = "Star Tags";
  //   getAllStarTag();
  // }, []);

  useEffect(() => {
    document.title = "Star Tags";
    const getAllStarTag = async () => {
      await DataService.getAllStarTags().then((data) => {
        setStarTag(data?.data?.data);
        setTotalTagsCount(data?.data?.totalStarTags);
        console.log(data?.data.totalStarTags);
        window.scrollTo(0, 0);
      });
    };

    getAllStarTag();
    getAllStarTags(currentPage);
  }, []);

  const getAllStarTags = async (newPage) => {
    setLoading(true);
    await DataService.getAllStarTag(newPage).then((data) => {
      setDataT(data?.data?.data);
      setLoading(false);
    });
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    getAllStarTags(newPage);
  };

  const totalPages = Math.ceil(TotalTagsCount / postsPerPage);

  const visiblePageButtons = 5;

  const renderPageButtons = () => {
    const pageButtons = [];
    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 ||
        (i >= currentPage - Math.floor(visiblePageButtons / 2) &&
          i <= currentPage + Math.floor(visiblePageButtons / 2))
      ) {
        pageButtons.push(
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={currentPage === i ? "active-btn" : ""}
          >
            {i}
          </button>
        );
      }
    }
    return pageButtons;
  };

  return (
    <>
      <Header />

      <div className="top-sec-star">
        <div className="container">
          {loading && (
            <div className="loader-spin">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          )}
          <div className="star-top-navbar">
            <h2>All Star Tags</h2>

            <div className="main-startag-page">
              {dataT && dataT.length > 0 ? (
                dataT?.map((item) => {
                  return (
                    <>
                      <div className="star-tag-page">
                        <div className="startag-img-page">
                          <img
                            src={
                              "https://fanzat-news.onrender.com" +
                              item?.image?.url
                            }
                            className="product-img"
                            alt="image"
                          />
                        </div>
                        <Link to={`/posts-by-star-tags/${item.TermID}`}>
                          <h4> {item?.starTags?.english}</h4>
                        </Link>
                      </div>
                    </>
                  );
                })
              ) : (
                <p>No Star Tags Found</p>
              )}
            </div>
            <div className="show-more-posts-btn">
              {currentPage > 1 && (
                <button onClick={() => handlePageChange(currentPage - 1)}>
                  {"< Previous"}
                </button>
              )}
              {renderPageButtons()}
              {currentPage < totalPages && (
                <button onClick={() => handlePageChange(currentPage + 1)}>
                  {"Next >"}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <NewsLetter />
      <Footer />
    </>
  );
}

export default StarPage;
