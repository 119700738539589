import React, { useEffect, useState } from "react";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import "../CSS/StarPage.css";
import DataService from "../services/data.service";
import { ToastContainer, toast } from "react-toastify";
import NewsLetter from "../Common/NewsLetter";

function MostUsedTag() {
  const [tag, setTag] = useState("");
  const [dataT, setDataT] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const postsPerPage = 20;
  const [TotalTagsCount, setTotalTagsCount] = useState("");

  useEffect(() => {
    document.title = "All Tags";
    const getAllTags = async () => {
      await DataService.getAllTag().then((data) => {
        setTag(data?.data?.data?.tags);
        setTotalTagsCount(data?.data?.data?.totalItems);
        window.scrollTo(0, 0);
      });
    };

    getAllTags();
    getAllMTags(currentPage);
  }, []);

  const getAllMTags = async (newPage) => {
    setLoading(true);
    await DataService.getAllTags(newPage).then((data) => {
      setDataT(data?.data?.data?.tags);
      setLoading(false);
    });
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    getAllMTags(newPage);
  };

  const totalPages = Math.ceil(TotalTagsCount / postsPerPage);

  const visiblePageButtons = 5;

  const renderPageButtons = () => {
    const pageButtons = [];
    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 ||
        (i >= currentPage - Math.floor(visiblePageButtons / 2) &&
          i <= currentPage + Math.floor(visiblePageButtons / 2))
      ) {
        pageButtons.push(
          <button
            key={i}
            onClick={() => handlePageChange(i)}
            className={currentPage === i ? "active-btn" : ""}
          >
            {i}
          </button>
        );
      }
    }
    return pageButtons;
  };

  return (
    <>
      <Header />
      <div className="top-sec-star">
        <div className="container">
          <div className="star-top-navbar">
            {loading && (
              <div className="loader-spin">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            )}
            <h2> All Tags </h2>

            <div className="main-startag-page">
              {dataT && dataT.length > 0 ? (
                dataT?.map((item) => {
                  return (
                    <>
                      <div className="star-tag-page most-used-tag">
                        <div className="startag-img-page">
                          <img
                            src={
                              "https://fanzat-news.onrender.com" +
                              item?.image?.url
                            }
                            alt="image"
                          />
                        </div>
                        <h4> {item?.tags?.english}</h4>
                      </div>
                    </>
                  );
                })
              ) : (
                <p>Not Tags Found</p>
              )}
            </div>
            <div className="show-more-posts-btn">
              {currentPage > 1 && (
                <button onClick={() => handlePageChange(currentPage - 1)}>
                  {"< previous "}
                </button>
              )}
              {renderPageButtons()}
              {currentPage < totalPages && (
                <button onClick={() => handlePageChange(currentPage + 1)}>
                  {"Next  >"}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <NewsLetter />
      <Footer />
    </>
  );
}

export default MostUsedTag;
