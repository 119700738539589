import React, { useState, useEffect } from "react";
import "../CSS/AboutUs.css";
import Header from "../Common/Header";
import Footer from "../Common/Footer";
import { ToastContainer, toast } from "react-toastify";
import DataService from "../services/data.service";
import NewsLetter from "../Common/NewsLetter";

function AboutUs() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "About Us";
    getData();
  }, []);

  const getData = async () => {
    await DataService.getAboutUs().then((data) => {
      setData(data?.data?.data[0]);
    });
  };

  return (
    <>
      <Header />
      <div className="sec-banner-about">
        <div className="main-tittle-banner">
          <h1>{data?.title?.english}</h1>
        </div>
      </div>
      <div className="top-sec-about">
        <div className="container">
          <div className="main-column-about">
            <div className="left-side-Cabout">
              <div className="Lcontent-about">
                <p
                  className="post-content-para"
                  dangerouslySetInnerHTML={{
                    __html: data?.description?.english,
                  }}
                ></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NewsLetter />
      <Footer />
    </>
  );
}

export default AboutUs;
