import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DataService from "../services/data.service";
import "../CSS/SearchPost.css";
import { Link } from "react-router-dom";
import NewsLetterEs from "../Common/NewsLetterEs";
import FooterEs from "../Common/FooterEs";
import HeaderEs from "../Common/HeaderEs";

function SearchPostEs() {
  const location = useLocation();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const queryParams = new URLSearchParams(location.search);
  const param1 = queryParams.get("param1");
  const [loading, setLoading] = useState(false);

  const getSearchPosts = async () => {
    setLoading(true);
    await DataService.getSearchPostEs(param1).then((data) => {
      setSearch(data?.data?.data);
      setLoading(false);
    });
  };
  useEffect(() => {
    document.title = "Search Post";
    window.scroll(0, 0);
    getSearchPosts();
  }, [param1]);

  return (
    <>
      <HeaderEs />
      <div className="top-sec-search">
        <div className="container">
          {loading && (
            <div className="loader-spin">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          )}
          <h2>Buscar noticias</h2>

          <div className="search-post-SPbox">
            {search && search.length > 0 ? (
              search
                .filter((item) => item.status === "publish")

                .map((item, index) => {
                  const postDate = new Date(item?.date);
                  const formattedDate = `${postDate.toLocaleDateString()} ${postDate.toLocaleTimeString()}`;
                  return (
                    <>
                      <div className="related-post-Bcolumn related-post-bcolumn3">
                        <Link to={"/es/single-blog-post/" + item._id}>
                          <div className="Bcolumn-img">
                            <img
                              src={
                                "https://fanzat-news.onrender.com" +
                                item?.featured_media?.source_url
                              }
                              className="product-img"
                              alt="image"
                            />
                          </div>
                          <div className="all-post-category1">
                            {item?.categories
                              .filter((category) => category.type === "1")
                              .map((category) => (
                                <h6>{category.name?.espanol}</h6>
                                // <h6 key={category._id}>{category.name?.arabic}</h6>
                              ))}
                          </div>
                          <div className="Bcolumn-content">
                            <h4>
                              {/* {item?.title?.rendered */}
                              {item?.title?.espanol
                                .split(" ")
                                .slice(0, 6)
                                .join(" ")}
                            </h4>
                            <p
                              className="para-arabic-comntent"
                              dangerouslySetInnerHTML={{
                                __html: item?.content?.espanol
                                  .split(" ")
                                  .slice(0, 10)
                                  .join(" "),
                              }}
                            ></p>
                            {/* <div className="Bcolumn-btn">
                              <button>Read More</button>
                          </div> */}
                          </div>
                        </Link>
                      </div>
                    </>
                  );
                })
            ) : (
              <p>No se encontraron noticias</p>
            )}
          </div>
        </div>
      </div>
      <NewsLetterEs />
      <FooterEs />
    </>
  );
}

export default SearchPostEs;
