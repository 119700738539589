import React, { useEffect, useState } from "react";
import "../CSS/ContactUs.css";

import contact from "../images/portrait-woman-customer-service-worker.jpg";
import { ToastContainer, toast } from "react-toastify";
import DataService from "../services/data.service";
import NewsLetterEs from "../Common/NewsLetterEs";
import FooterEs from "../Common/FooterEs";
import HeaderEs from "../Common/HeaderEs";

function ContactUsEs() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      firstName.trim() === "" ||
      lastName.trim() === "" ||
      email.trim() === "" ||
      phone.trim() === ""
    ) {
      toast.error("All fields are required!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    const data = {};
    data.firstName = firstName;
    data.lastName = lastName;
    data.email = email;
    data.phone = phone;
    data.description = description;

    DataService.addContactEs(data).then(
      () => {
        toast.success("Email submit successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setDescription("");
        setEmail("");
        setFirstName("");
        setLastName("");
        setPhone("");
      },

      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        toast.error(resMessage, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    );
  };
  useEffect(() => {
    document.title = "Contact Us";
    window.scrollTo(0, 0);
    getData();
  }, []);
  const getData = async () => {
    await DataService.getContactUsEs().then((data) => {
      setData(data?.data?.data[0]);
    });
  };
  return (
    <>
      <HeaderEs />
      <div className="sec-banner-contact">
        <div className="main-tittle-banner">
          <h1>{data?.title?.espanol}</h1>
        </div>
      </div>
      <div className="top-sec-contact">
        <div className="container">
          <div className="main-column-contact">
            <div className="left-side-Ccontact">
              <div className="Lcontent-contact">
                <p
                  className="post-content-para"
                  dangerouslySetInnerHTML={{
                    __html: data?.description?.espanol,
                  }}
                ></p>

                <div className="social-icon-contact">
                  <h4>Mantente en contacto</h4>
                  <ul className="conatct-main-icons">
                    <li>
                      <a href="#">
                        <i class="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        {" "}
                        <i class="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i class="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        {" "}
                        <i class="fas fa-link"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="right-side-Ccontact">
              <div className="Rcontent-conatct">
                <h2>Contáctanos</h2>
                <form>
                  <div className="form-flex">
                    <div class="form-floating mb-3 form-contact">
                      <input
                        type="text"
                        class="form-control"
                        id="floatingInput"
                        placeholder="Nombre"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                      <label for="floatingInput">Nombre</label>
                    </div>
                    <div class="form-floating mb-3 form-contact">
                      <input
                        type="text"
                        class="form-control"
                        id="floatingText"
                        placeholder="Apellido"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                      <label for="floatingText">Apellido</label>
                    </div>
                  </div>
                  <div className="form-flex">
                    <div class="form-floating mb-3 form-contact">
                      <input
                        type="email"
                        class="form-control"
                        id="floatingInput"
                        placeholder="name@example.com"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <label for="floatingInput">
                        Dirección de correo electrónico
                      </label>
                    </div>
                    <div class="form-floating mb-3 form-contact">
                      <input
                        type="number"
                        class="form-control"
                        id="floatingInput"
                        placeholder="Número de teléfono"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                      <label for="floatingInput">Número de teléfono</label>
                    </div>
                  </div>

                  <div className="comment-box-form contact-Fcomment">
                    <div class="form-floating">
                      <textarea
                        class="form-control"
                        placeholder="Dejar una respuesta"
                        id="floatingTextarea"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      ></textarea>
                      <label for="floatingTextarea">Dejar un comentario</label>
                    </div>
                  </div>
                  <div className="post-form-btn contact-Fbtn">
                    <button onClick={handleSubmit}>Dejar una respuesta</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NewsLetterEs />
      <FooterEs />
    </>
  );
}

export default ContactUsEs;
