import React, { useState, useEffect } from "react";
import "../CSS/SingleBlogPost.css";
import Slider from "react-slick";
import hj from "../images/ikj.jpg";
import teams from "../images/img-teams.png";
import Info2 from "../images/info-2.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useParams } from "react-router-dom";
import DataService from "../services/data.service";
import moment from "moment";
import SharePost from "../Common/SharePost";
import { Link } from "react-router-dom";
import NewsLetterEs from "../Common/NewsLetterEs";
import FooterEs from "../Common/FooterEs";
import HeaderEs from "../Common/HeaderEs";

function SingleBlogPostEs() {
  const params = useParams();
  const [count, setCount] = useState(1);
  const [data, setData] = useState();
  const [dataB, setDataB] = useState();
  const [loading, setLoading] = useState(false);
  const [postdate, setPostDate] = useState();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [reply, setReply] = useState("");
  const [Anews, setANews] = useState("");
  const [newsC, setNewsC] = useState("");
  const [relatedId, setRelatedId] = useState("");
  const [totalCount, setTotalCount] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [sendEmail, setSendEmail] = useState("");

  // const [category, setCategory] = useState([]);

  // const [subcategory, setBData] = useState([]);

  const [masterCategory, setMasterCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);

  const handleViewMore = () => {
    setCount((prevCount) => prevCount + 1);
  };
  const handleLessView = () => {
    setCount((prevCount) => prevCount - 1);
  };

  const getAllCategory = async () => {
    await DataService.getAllCategory(1).then((data) => {
      setMasterCategory(data?.data?.categories);
    });
    await DataService.getAllCategory(2).then((data) => {
      setSubCategory(data?.data?.categories);
    });
  };

  useEffect(() => {
    document.title = "Single Post";
    window.scrollTo(0, 0);
    getAllCategory();
  }, []);
  const singleblog = async () => {
    await DataService.getSingleBlogEs(params.id).then((data) => {
      setData(data.data.data);
      setPostDate(data?.data?.data?.post_date);
      setCategoryId(data?.data?.data?.categories[1]?.id);
      const categoryId = data?.data?.data?.categories[1]?.id;
      if (categoryId) {
        getRelatedPosts(categoryId);
      }
    });
  };
  useEffect(() => {
    singleblog();
    getAllPopularNewsNews();
  }, []);
  const getAllPopularNewsNews = async () => {
    await DataService.getAllPopularNewsEs().then((data) => {
      setANews(data?.data?.data);
    });
  };

  const getRelatedPosts = async (categoryId) => {
    await DataService.getACategoryByIdEs(categoryId).then((data) => {
      setNewsC(data?.data?.data);
    });
  };

  const allComments = async () => {
    await DataService.getCommentsEs(params.id).then((data) => {
      setDataB(data?.data.filter((item) => item.comment_status === "approved"));
      setTotalCount(data.data?.length);
      setLoading(false);
    });
  };
  useEffect(() => {
    allComments();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = {};
    data.comment_author = name;
    data.comment_author_email = email;
    data.comment_author_url = website;
    data.comment_content = reply;
    data.comment_post_ID = params.id;

    DataService.AddCommentEs(data).then(
      () => {
        toast.success("Comment Posted successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      },

      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);
        toast.error(resMessage, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    );
  };

  const formattedDate = postdate ? moment(postdate).format("MMM DD YYYY") : "";

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    if (!sendEmail.trim()) {
      return;
    }
    const data = {};
    data.email = sendEmail;

    DataService.addEmailEs(data).then(
      () => {
        toast.success("Email submit successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setSendEmail("");
      },

      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        toast.error(resMessage, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    );
  };

  return (
    <>
      <HeaderEs />
      <section className="singlePost-topSec">
        <ToastContainer></ToastContainer>

        <div className="container">
          <div className="topsec-maincolumn">
            <div className="main-column-L">
              <div className="columnL-top-banner">
                <div className="post-cat">
                  {data?.categories
                    .filter((category) => category.type === "1")
                    .map((category) => (
                      <h6>{category.name?.espanol}</h6>
                      // <h6 key={category._id}>{category.name?.arabic}</h6>
                    ))}
                </div>
                <div className="post-main-heading">
                  <h1>{data?.title?.espanol}</h1>
                </div>

                <div className="post-meta-wrap">
                  {/* <div className="post-author-avtar">
                    <img
                      src="https://new.axilthemes.com/demo/react/blogar/images/posts/author/author-b5.webp?imwidth=64"
                      alt="Fatima Jane"
                    />
                    <div className="post-author-content">
                      <h6>Fatima Jane</h6>
                      <ul>
                        <li>{formattedDate}</li>
                      </ul>
                    </div>
                  </div> */}

                  <div className="social-icon-post">
                    <SharePost
                      title={data?.title?.english}
                      url={`https://fanzat-news-arabic.vercel.app/single-blog-post/${params.id}`}
                    />
                    {/* <ul>
                      <li>
                        <i class="fab fa-facebook-f"></i>
                      </li>
                      <li>
                        <i class="fab fa-twitter"></i>
                      </li>
                      <li>
                        <i class="fab fa-instagram"></i>
                      </li>
                      <li>
                        <i class="fas fa-link"></i>
                      </li>
                    </ul> */}
                  </div>
                </div>
              </div>
              <div className="singlepost-details">
                {/* <div className=" banner-image-slider">
                  <Slider {...settings}>
                    <div>
                      <div className="banner-sub-slider">
                        <div className="banner-sub-over"></div>
                        <img
                          src="https://news.fanzat.com/wp-content/uploads/2019/12/NNSM3.jpg"
                          alt=""
                        />
                      </div>
                    </div>
                    <div>
                      <div className="banner-sub-slider">
                        <div className="banner-sub-over"></div>
                        <img
                          src="https://news.fanzat.com/wp-content/uploads/2019/12/NNSM3.jpg"
                          alt=""
                        />
                      </div>
                    </div>
                    <div>
                      <div className="banner-sub-slider">
                        <div className="banner-sub-over"></div>
                        <img
                          src="https://news.fanzat.com/wp-content/uploads/2019/12/NNSM3.jpg"
                          alt=""
                        />
                      </div>
                    </div>
                  </Slider>
                </div> */}
                <div className="singlepost-content">
                  <div className="single-post-image">
                    <img
                      src={
                        "https://fanzat-news.onrender.com" +
                        data?.featured_media[0]?.source_url
                      }
                      className="product-img"
                      alt="image"
                    />
                  </div>

                  <div className="sub-featured-images">
                    {data?.featured_media.length > 1 &&
                      data.featured_media.slice(1).map((item, index) => (
                        <div className="sub-images-single-post" key={index + 1}>
                          <img
                            src={
                              "https://fanzat-blogs-api.onrender.com" +
                              item.source_url
                            }
                            alt={"Image " + (index + 1)}
                          />
                        </div>
                      ))}
                  </div>
                  <p
                    className="post-content-para"
                    dangerouslySetInnerHTML={{
                      __html: data?.content?.espanol
                        ? data?.content?.espanol
                        : "",
                    }}
                  ></p>

                  <div className="preview-reviews-heading">
                    <h3>Avance de revisiones</h3>
                  </div>

                  {dataB && dataB.length > 0 ? (
                    dataB.slice(0, count).map((item, index) => (
                      <div key={index} className="post-Labout-author">
                        <div className="Labout-details">
                          <div className="L-author-data">
                            <h5>{item?.comment_author}</h5>
                            <p>{item?.comment_content}</p>
                            {/* <div className="L-author-social-link">
                            </div> */}
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No hay comentarios aprobados disponibles.</p>
                  )}

                  <div className="comments-btn-main">
                    {dataB?.length > 1 && count !== totalCount ? (
                      // {dataB?.length > 1 && dataB?.length < totalCount && (
                      <div className="comments-more">
                        <button
                          className="main-btn"
                          // disabled={dataB && dataB.length === count}
                          onClick={handleViewMore}
                        >
                          Ver más
                        </button>
                      </div>
                    ) : (
                      ""
                    )}

                    {count !== 1 ? (
                      <div className="comments-more less-more-btn">
                        <button className="main-btn" onClick={handleLessView}>
                          Preview
                        </button>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="single-post-form">
                    <div className="post-form-heading">
                      <h4>Publicar un comentario</h4>
                    </div>
                    <form>
                      <div className="form-flex">
                        <div class="form-floating mb-3">
                          <input
                            type="text"
                            class="form-control"
                            id="floatingInput"
                            placeholder="Your Name"
                            onChange={(e) => setName(e.target.value)}
                          />
                          <label for="floatingInput">Tu nombre</label>
                        </div>
                        <div class="form-floating mb-3">
                          <input
                            type="email"
                            class="form-control"
                            id="floatingInput"
                            placeholder="name@example.com"
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          <label for="floatingInput">
                            Dirección de correo electrónico
                          </label>
                        </div>
                        <div class="form-floating mb-3">
                          <input
                            type="text"
                            class="form-control"
                            id="floatingText"
                            placeholder="Text"
                            onChange={(e) => setWebsite(e.target.value)}
                          />
                          <label for="floatingText">Tu sitio web</label>
                        </div>
                      </div>
                      <div className="comment-box-form">
                        <div class="form-floating">
                          <textarea
                            class="form-control"
                            placeholder="Dejar una respuesta here"
                            id="floatingTextarea"
                            onChange={(e) => setReply(e.target.value)}
                          ></textarea>
                          <label for="floatingTextarea">
                            Dejar una respuesta
                          </label>
                        </div>
                      </div>
                      <div className="post-form-btn">
                        <button onClick={handleSubmit}>
                          Dejar un comentario
                        </button>
                      </div>
                    </form>
                  </div>
                  {/* <div className="post-comment-area">
                    <div className="post-comment-column">
                      <div className="post-comment-img">
                        <img src={hj} alt="" />
                      </div>
                      <div className="post-comment-content">
                        <p className="comment-author-name">Lorem ipsum dolor</p>
                        <div className="post-comment-time">
                          <p className="comment-date-time">Lorem ipsum dolor</p>
                          <p className="comment-reply">
                            <i class="fas fa-circle"></i> Reply{" "}
                          </p>
                        </div>
                        <p className="post-comment-para">
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua. Ut enim ad minim.
                        </p>
                      </div>
                    </div>
                  </div> */}

                  <div className="related-post-bottom">
                    <div className="related-post-top-bar">
                      <div className="related-post-top-heading">
                        <h3>Publicación relacionada</h3>
                      </div>
                      {newsC && newsC.length > 0 && (
                        <div className="related-posts-btn">
                          <Link to={"/es/related-posts/" + categoryId}>
                            <button className="trending-top-btnF">
                              Ver más
                            </button>
                          </Link>
                        </div>
                      )}
                    </div>
                    <div className="related-post-Bbox">
                      {newsC && newsC.length > 0 ? (
                        newsC
                          .filter(
                            (item) =>
                              item.status === "publish" &&
                              item?._id !== data?._id
                          )
                          .slice(0, 2)
                          .map((item, index) => {
                            const postDate = new Date(item.date);
                            const formattedDate = `${postDate.toLocaleDateString()} ${postDate.toLocaleTimeString()}`;
                            return (
                              <>
                                <div className="related-post-Bcolumn">
                                  <a href={"/es/single-blog-post/" + item._id}>
                                    <div className="Bcolumn-img">
                                      <img
                                        src={
                                          "https://fanzat-news.onrender.com" +
                                          item?.featured_media?.source_url
                                        }
                                        className="product-img"
                                        alt="image"
                                      />
                                    </div>
                                    <div className="Bcolumn-content">
                                      <h4>
                                        {" "}
                                        {item?.title?.espanol
                                          .split(" ")
                                          .slice(0, 10)
                                          .join(" ")}
                                      </h4>
                                      <p
                                        dangerouslySetInnerHTML={{
                                          __html: item.content?.espanol
                                            .split(" ")
                                            .slice(0, 16)
                                            .join(" "),
                                        }}
                                      ></p>
                                      {/* <div className="Bcolumn-btn">
                                      <a href={"/single-blog-post/" + item._id}>
                                        {" "}
                                        <button>Read More</button>{" "}
                                      </a>
                                    </div> */}
                                    </div>
                                  </a>
                                </div>
                              </>
                            );
                          })
                      ) : (
                        <p>No se encontraron noticias</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="main-column-R">
              <div className="sidebar-Rcolumn">
                {/* <div className="search-box">
                  <h5>Buscar</h5>
                  <div
                    class="search_bar post-search-bar"
                    style={{ marginTop: "15px" }}
                  >
                    <input type="search" placeholder="Search..." />
                  </div>
                </div> */}
                <div className="video-section-singlePost">
                  {data?.video?.source_url && (
                    <iframe
                      width="560"
                      height="315"
                      src={`https://fanzat-blogs-api.onrender.com${data.video.source_url}`}
                      title="Embedded Video"
                      frameBorder="0"
                      allowFullScreen
                    ></iframe>
                  )}
                </div>
                <div className="popular-post">
                  <h5>Noticias populares</h5>
                  <div className="post-medium-blogs">
                    {Anews && Anews.length > 0 ? (
                      Anews.filter((item) => item.status === "publish")
                        .slice(0, 4)
                        .map((item, index) => {
                          const postDate = new Date(item.date);
                          const formattedDate = `${postDate.toLocaleDateString()} ${postDate.toLocaleTimeString()}`;
                          return (
                            <>
                              <a href={"/es/single-blog-post/" + item._id}>
                                <div className="post-medium-content">
                                  <div className="post-medium-img">
                                    <img
                                      src={
                                        "https://fanzat-news.onrender.com" +
                                        item?.featured_media?.source_url
                                      }
                                      className="product-img"
                                      alt="image"
                                    />
                                  </div>
                                  <div className="post-medium-para">
                                    <h3 className="post_title">
                                      {item?.title?.espanol
                                        .split(" ")
                                        .slice(0, 12)
                                        .join(" ")}
                                    </h3>
                                    <div className="post-medium-meta">
                                      <span>{formattedDate}</span>
                                    </div>
                                  </div>
                                </div>
                              </a>
                            </>
                          );
                        })
                    ) : (
                      <p>No se encontraron noticias</p>
                    )}
                  </div>
                </div>
                <div className="post-newsletter">
                  <div post-newsletter-inner>
                    <h5>¡Nunca te pierdas una noticia!</h5>
                    <p>
                      "Regístrese gratis y sea el primero en ser <br />
                      notificado sobre actualizaciones."
                    </p>
                    <div className="search-box newsletter-search">
                      <div
                        class="search_bar post-search-bar"
                        style={{ marginTop: "15px" }}
                      >
                        <input
                          type="search"
                          placeholder="Ingresa tu correo electrónico"
                          value={sendEmail}
                          onChange={(e) => setSendEmail(e.target.value)}
                        />
                        <div className="newsletter-search-button">
                          <button
                            className="main-btn"
                            onClick={handleEmailSubmit}
                          >
                            Suscribirse
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div class="post-Rthumbnail">
                  <img
                    src="https://new.axilthemes.com/demo/react/blogar/images/post-single/ads-01.webp?imwidth=640"
                    className="post-Rthumbnail-img"
                  />
                </div> */}
                <div className="post-Rsocial-icon">
                  <h5>Mantente en contacto</h5>
                  <ul className="post-main-icons">
                    <li>
                      <a href="#">
                        <i class="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        {" "}
                        <i class="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i class="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        {" "}
                        <i class="fas fa-link"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                {/* <div className="post-Rcategory">
                  <h5>categoría</h5>
                  <div className="post-Rsub-category">
                    <button> </button>
                    <button>Life Style</button>
                    <button>Web design</button>
                    <button>Development</button>
                    <button>lorem</button>
                    <button>lorem</button>
                  </div>
                </div> */}
                <div className="info-box-registation">
                  <div className="info-sub-boxR">
                    <h4>
                      INFLUENCER ? <br />
                      FAMOSO
                    </h4>
                    <div className="info-image-box">
                      <img src={teams} />
                    </div>
                    <h5>
                      Inicia tu viaje con Fanzat y alcanza los niveles más altos
                      de alcance
                    </h5>
                    <div className="info-box-btn">
                      <a href="https://nnsm3.com/register/star">
                        <button> Regístrate como Estrella</button>
                      </a>
                    </div>
                  </div>

                  <div className="info-sub-boxR">
                    <h4>TENER UNA INFLUENCIA REAL Y ÚNICA</h4>
                    <div className="info-image-box">
                      <img
                        src="https://news.fanzat.com/wp-content/themes/nnsm3-news/images/info-2.png"
                        alt="image"
                      />
                    </div>
                    <h5>
                      Use your unique influence in your field to achieve amazing
                      results with NNSM3
                    </h5>
                    <div className="info-box-btn">
                      <a href="https://nnsm3.com/register/pulsar">
                        <button> Regístrate como Pulsar</button>
                      </a>
                    </div>
                  </div>
                  <div className="info-sub-boxR">
                    <h4>
                      MARCA ¿O
                      <br /> EMPRESA?{" "}
                    </h4>
                    <div className="info-image-box">
                      <img
                        src="https://news.fanzat.com/wp-content/themes/nnsm3-news/images/info-3.png"
                        alt="image"
                      />
                    </div>
                    <h5>
                      Choose your influencer & track your successful campaign on
                      one platform with NNSM3
                    </h5>
                    <div className="info-box-btn">
                      <a href="https://nnsm3.com/register/orbit">
                        <button> Regístrate como Órbita</button>
                      </a>
                    </div>
                  </div>
                  <div className="info-sub-boxR">
                    <h4>
                      ¿ERES UN <br />
                      FAN?
                    </h4>
                    <div className="info-image-box">
                      <img
                        src="https://news.fanzat.com/wp-content/themes/nnsm3-news/images/info-3.png"
                        alt="image"
                      />
                    </div>
                    <h5>Lore ipsum is the dummy text standard industry</h5>
                    <div className="info-box-btn">
                      <a href="">
                        <button> Regístrate como fan </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <NewsLetterEs />
      <FooterEs />
    </>
  );
}

export default SingleBlogPostEs;
