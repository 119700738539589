import React, { useEffect, useState } from "react";
import "../CSS/TermsOfUse.css";
import DataService from "../services/data.service";
import NewsLetterEs from "../Common/NewsLetterEs";
import FooterEs from "../Common/FooterEs";
import HeaderEs from "../Common/HeaderEs";

function TermsOfUseEs() {
  const [data, setData] = useState("");

  useEffect(() => {
    document.title = "Terms of Use";
    window.scroll(0, 0);
    getData();
  },[]);
  const getData = async () => {
    await DataService.getTermsUsedEs().then((data) => {
      setData(data?.data?.data[0]);
    });
  };
  return (
    <>
      <HeaderEs />
      <div className="banner-sec-Terms">
        <div className="banner-T-tittle">
          <h1>{data?.title?.espanol}</h1>
        </div>
      </div>
      <div className="top-sec-privacy">
        <div className="container">
          <div className="main-content-privacy">
            <p
              className="post-content-para"
              dangerouslySetInnerHTML={{
                __html: data?.description?.espanol,
              }}
            ></p>
          </div>
        </div>
      </div>
      <NewsLetterEs />
      <FooterEs />
    </>
  );
}

export default TermsOfUseEs;
