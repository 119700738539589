import React, { useState, useEffect } from "react";
import "../CSS/ContactUs.css";

import contact from "../images/portrait-woman-customer-service-worker.jpg";
import DataService from "../services/data.service";
import { ToastContainer, toast } from "react-toastify";
import NewsLetterFr from "../Common/NewsLetterFr";
import FooterFr from "../Common/FooterFr";
import HeaderFr from "../Common/HeaderFr";

function ContactUsFr() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Contact Us";
    getData();
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      firstName.trim() === "" ||
      lastName.trim() === "" ||
      email.trim() === "" ||
      phone.trim() === ""
    ) {
      toast.error("All fields are required!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return; 
    }
    const data = {};
    data.firstName = firstName;
    data.lastName = lastName;
    data.email = email;
    data.phone = phone;
    data.description = description;

    DataService.addContact(data).then(
      () => {
        toast.success("Email submit successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setDescription("");
        setEmail("");
        setFirstName("");
        setLastName("");
        setPhone("");
      },

      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);
        toast.error(resMessage, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    );
  };
  const getData = async () => {
    await DataService.getContactUsFr().then((data) => {
      setData(data?.data?.data[0]);
    });
  };
  return (
    <>
      <HeaderFr />
      <div className="sec-banner-contact">
        <div className="main-tittle-banner">
          <h1>{data?.title?.french}</h1>
        </div>
      </div>
      <div className="top-sec-contact">
        <div className="container">
          <div className="main-column-contact">
            <div className="left-side-Ccontact">
              <div className="Lcontent-contact">
                <p
                  className="post-content-para"
                  dangerouslySetInnerHTML={{
                    __html: data?.description?.french,
                  }}
                ></p>
                <div className="social-icon-contact">
                  <h4>Restez en contact</h4>
                  <ul className="conatct-main-icons">
                    <li>
                      <a href="#">
                        <i class="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        {" "}
                        <i class="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i class="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        {" "}
                        <i class="fas fa-link"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="right-side-Ccontact">
              <div className="Rcontent-conatct">
                <h2>Contactez-nous</h2>
                <form>
                  <div className="form-flex">
                    <div class="form-floating mb-3 form-contact">
                      <input
                        type="text"
                        class="form-control"
                        id="floatingInput"
                        placeholder="Prénom"
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                      <label for="floatingInput">Prénom</label>
                    </div>
                    <div class="form-floating mb-3 form-contact">
                      <input
                        type="text"
                        class="form-control"
                        id="floatingText"
                        placeholder="Nom de famille"
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                      <label for="floatingText">Nom de famille</label>
                    </div>
                  </div>
                  <div className="form-flex">
                    <div class="form-floating mb-3 form-contact">
                      <input
                        type="email"
                        class="form-control"
                        id="floatingInput"
                        placeholder="name@example.com"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <label for="floatingInput">Adresse e-mail</label>
                    </div>
                    <div class="form-floating mb-3 form-contact">
                      <input
                        type="number"
                        class="form-control"
                        id="floatingInput"
                        placeholder="Numéro de téléphone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                      <label for="floatingInput">Numéro de téléphone</label>
                    </div>
                  </div>

                  <div className="comment-box-form contact-Fcomment">
                    <div class="form-floating">
                      <textarea
                        class="form-control"
                        placeholder="Laisser un commentaire here"
                        id="floatingTextarea"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      ></textarea>
                      <label for="floatingTextarea">Laisser une réponse</label>
                    </div>
                  </div>
                  <div className="post-form-btn contact-Fbtn">
                    <button onClick={handleSubmit}>
                      Laisser un commentaire
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NewsLetterFr />
      <FooterFr />
    </>
  );
}

export default ContactUsFr;
