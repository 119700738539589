import React, { useState, useEffect } from "react";
import "../CSS/Home.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import DataService from "../services/data.service";
import Group from "../images/group-cheerl.png";
import SharePost from "../Common/SharePost";
import background from "../images/backgroung-img.jpeg";
import NewsLetter from "../Common/NewsLetter";
import video1 from "../Videos/VID-20220709-WA0002.mp4";
import video2 from "../Videos/VID-20220709-WA0046.mp4";
import video3 from "../Videos/VID-20220711-WA0002.mp4";
import video4 from "../Videos/VID-20220711-WA0003.mp4";
import video5 from "../Videos/VID-20220711-WA0004.mp4";
import HeaderEs from "../Common/HeaderEs";
import FooterEs from "../Common/FooterEs";
import NewsLetterEs from "../Common/NewsLetterEs";

const HomeEs = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 3,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [news, setNews] = useState("");
  const [newsR, setNewsR] = useState("");
  const [newsL1, setNewsL1] = useState("");
  const [Anews, setANews] = useState("");
  const [mpNews, setMPNews] = useState("");
  const [mpNews1, setMPNews1] = useState("");
  const [starTag, setStarTag] = useState("");
  const [latestPost, setLatestPost] = useState("");
  // const [category, setCategory] = useState([]);
  const [masterCategory, setMasterCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [Rmaster, setRmaster] = useState([]);
  const [parentId, setParentId] = useState([]);
  const [mastid, setmastID] = useState("");
  const [activeButton, setActiveButton] = useState(null);
  const [mostPTag, setMostPTag] = useState("");
  const [allTag, setAllTag] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const getAllCategory = async () => {
    await DataService.getAllCategory(1).then((data) => {
      // console.log(data?.data?.categories)
      setMasterCategory(data?.data?.categories);
      console.log(data?.data?.categories);
    });
    await DataService.getAllCategory(2).then((data) => {
      setSubCategory(data?.data?.categories);
    });
  };
  useEffect(() => {
    document.title = "News";
    window.scrollTo(0, 0);
    getAllCategory();
  }, []);

  const getAllNews = async () => {
    setLoading(true);
    await DataService.getAllNewsEs().then((data) => {
      setNews(data?.data?.data?.posts);
      setNewsR(data?.data?.data?.posts);
      setLoading(false);
    });
  };

  const getAllPopularNewsNews = async () => {
    await DataService.getAllPopularNewsEs().then((data) => {
      setANews(data?.data?.data);
      setMPNews(data?.data?.data);
      setMPNews1(data?.data?.data);
    });
  };
  const getAllLatestPosts = async () => {
    await DataService.getAllLatestPostEs().then((data) => {
      setLatestPost(data?.data?.data);
    });
  };

  const getAllStarTag = async () => {
    await DataService.getAllStarTagsEs().then(
      (data) => {
        setStarTag(data?.data?.data);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setMessage(resMessage);
        // toast.error(resMessage, {
        //   position: toast.POSITION.TOP_RIGHT,
        // });
      }
    );
  };
  useEffect(() => {
    if (masterCategory && masterCategory.length > 0) {
      getRelatedmasterCats(masterCategory[0]?.id);
    }
  }, [masterCategory]);
  const getRelatedmasterCats = async (itemId) => {
    await DataService.getRelatedmasterCatEs(itemId).then((data) => {
      setRmaster(data?.data?.data);
    });
  };
  useEffect(() => {
    getAllNews();
    getAllStarTag();
    // getRelatedmasterCats();Most Popular
  }, []);

  useEffect(() => {
    getAllNews();
    getAllPopularNewsNews();
    getAllStarTag();
    getAllLatestPosts();
    // getAllCategory();
    getAllMostTags();
    getAllTags();
  }, []);

  const handleButtonClick = (id) => {
    setActiveButton(id);
    getRelatedmasterCats(id); // Assuming this function handles some logic when a button is clicked
  };
  const getAllMostTags = async () => {
    await DataService.getAllMostTagEs().then((data) => {
      setMostPTag(data?.data?.data);
    });
  };

  const getAllTags = async () => {
    await DataService.getAllTagEs().then((data) => {
      setAllTag(data?.data?.data?.tags);
    });
  };

  useEffect(() => {
    if (masterCategory.length > 0) {
      setActiveButton(masterCategory[0].id);
    }
  }, [masterCategory]);

  return (
    <>
      <HeaderEs />
      <section className="top_sec">
        <div className="container">
          {loading && (
            <div className="loader-spin">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          )}
          <div className="top_flex">
            <div className="top_flexR">
              {news && news.length > 0 ? (
                news
                  .filter((item) => item.status === "publish")
                  .slice(0, 1)
                  .map((item, index) => {
                    const postDate = new Date(item.date);
                    const formattedDate = `${postDate.toLocaleDateString()} ${postDate.toLocaleTimeString()}`;
                    return (
                      <>
                        <Link to={"/es/single-blog-post/" + item._id}>
                          <div className="top_mainP">
                            <div className="top_mainThumb">
                              <img
                                src={
                                  "https://fanzat-news.onrender.com" +
                                  item?.featured_media?.source_url
                                }
                                className="product-img"
                                alt="image"
                              />
                            </div>
                            <div className="top_mainPD">
                              <span className="top_mainPDCat">
                                {" "}
                                {item?.category?.name?.espanol}
                              </span>
                              <div className="post_titleSec">
                                <h3 className="post_title">
                                  {item?.title?.espanol}
                                </h3>
                              </div>
                              {/* <div className="main_postMeta"> */}
                              <div className="main_postMetaR">
                                <SharePost
                                  title={item?.title?.english}
                                  url={`https://fanzat-news-arabic.vercel.app/single-blog-post/${item._id}`}
                                />
                              </div>
                              <div className="main_postMetaL">
                                {/* <div>
                                  <img
                                    src={
                                      "https://fanzat-news.onrender.com/" +
                                      item?.user_ID?.images[0]?.path
                                    }
                                    // src={data?.images?.path ? data?.image?.path : "../assets/img/profile-img.png"}
                                    className="product-img"
                                    alt="image"
                                  />
                                </div> */}
                                <div>
                                  {/* <h6>{item?.user_ID?.user_nicename}</h6> */}

                                  {/* <span>{formattedDate}</span> */}
                                </div>
                              </div>
                              {/* </div> */}
                            </div>
                          </div>
                        </Link>
                      </>
                    );
                  })
              ) : (
                <p>No se encontraron noticias</p>
              )}
            </div>
            <div className="top_flexL">
              {/* {news && news.length > 0 ? (
                news.map((item, index)=>{
                  return(
                  <>
                  <div className="top_mainPost">
                  <div className="top_mainPostL">
                    <span className="top_mainPostCat">Research</span>
                    <div className="post_titleSec">
                      <h3 className="post_title">
                     {item?.post_date}
                        The world’s most powerful woman, Oprah Winfrey Lorem
                        Ipsum.
                      </h3>
                    </div>
                  </div>
                  <div className="top_mainPostR">
                    <img
                      src="https://new.axilthemes.com/demo/react/blogar-rtl/images/posts/post-column-02.webp"
                      alt=""
                    />
                  </div>
                </div>
                  </>
                  );
                })
              ): (
                <p>No se encontraron noticias</p>
              )} */}
              {newsR && newsR.length > 0 ? (
                newsR
                  .filter((item) => item.status === "publish")
                  .slice(0, 5)
                  .map((item, index) => {
                    const postDate = new Date(item.date);
                    const formattedDate = `${postDate.toLocaleDateString()} ${postDate.toLocaleTimeString()}`;
                    return (
                      <>
                        <Link to={"/es/single-blog-post/" + item._id}>
                          <div key={index} className="top_mainPost">
                            <div className="top_mainPostR">
                              <img
                                src={
                                  "https://fanzat-news.onrender.com" +
                                  item?.featured_media?.source_url
                                }
                                className="product-img"
                                alt="image"
                              />
                            </div>
                            <div className="top_mainPostL">
                              <span className="top_mainPostCat">
                                {item?.category?.name?.espanol}
                              </span>
                              <div className="post_titleSec">
                                <h3 className="post_title">
                                  {item?.title?.espanol}
                                </h3>
                                <p>{formattedDate}</p>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </>
                    );
                  })
              ) : (
                <p>No se encontraron noticias</p>
              )}

              {/* <div className="top_mainPost">
                <div className="top_mainPostL">
                  <span className="top_mainPostCat">Research</span>
                  <div className="post_titleSec">
                    <h3 className="post_title">
                      The world’s most powerful woman, Oprah Winfrey Lorem
                      Ipsum.
                    </h3>
                  </div>
                </div>
                <div className="top_mainPostR">
                  <img
                    src="https://new.axilthemes.com/demo/react/blogar-rtl/images/posts/thumbnail-02.webp"
                    alt=""
                  />
                </div>
              </div>
              <div className="top_mainPost">
                <div className="top_mainPostL">
                  <span className="top_mainPostCat">Research</span>
                  <div className="post_titleSec">
                    <h3 className="post_title">
                      The world’s most powerful woman, Oprah Winfrey Lorem
                      Ipsum.
                    </h3>
                  </div>
                </div>
                <div className="top_mainPostR">
                  <img
                    src="https://new.axilthemes.com/demo/react/blogar-rtl/images/posts/post-column-02.webp"
                    alt=""
                  />
                </div>
              </div>
              <div className="top_mainPost">
                <div className="top_mainPostL">
                  <span className="top_mainPostCat">Research</span>
                  <div className="post_titleSec">
                    <h3 className="post_title">
                      The world’s most powerful woman, Oprah Winfrey Lorem
                      Ipsum.
                    </h3>
                  </div>
                </div>
                <div className="top_mainPostR">
                  <img
                    src="https://new.axilthemes.com/demo/react/blogar-rtl/images/posts/post-seo-sm-04.webp"
                    alt=""
                  />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
      {/* <section className="banner_sec">
        <div className="container">
          <div className="banner_flex">
            <div className="bannerL">
              <div className="slide_inner">
                <img
                  src="https://new.axilthemes.com/demo/react/blogar/images/posts/thumbnail-08.webp"
                  alt=""
                />
                <div className="slide_descSec">
                  <span className="category_sec">Celebrity News</span>
                  <div className="post_titleSec">
                    <h3 className="post_title">
                      The world’s most powerful woman, Oprah Winfrey, has been
                      named the highest-paid celebrity in the world.
                    </h3>
                  </div>
                  <div className="meta_flex">
                    <span>
                      <i class="far fa-calendar-alt"></i> 09 Nov. 2023
                    </span>{" "}
                    |{" "}
                    <span>
                      <i class="far fa-eye"></i> 167
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="bannerM">
              <div className="slide_inner top_slideInner">
                <img
                  src="https://new.axilthemes.com/demo/react/blogar/images/posts/thumbnail-05.webp"
                  alt=""
                />
                <div className="slide_descSec">
                  <span className="category_sec">Corporate News</span>
                  <div className="post_titleSec">
                    <h3 className="post_title">
                      The world’s most powerful woman, Oprah Winfre.
                    </h3>
                  </div>
                  <div className="meta_flex">
                    <span>
                      <i class="far fa-calendar-alt"></i> 09 Nov. 2023
                    </span>{" "}
                    |{" "}
                    <span>
                      <i class="far fa-eye"></i> 167
                    </span>
                  </div>
                </div>
              </div>
              <div className="slide_inner top_slideInner">
                <img
                  src="https://new.axilthemes.com/demo/react/blogar/images/posts/post-tech-10.webp"
                  alt=""
                />
                <div className="slide_descSec">
                  <span className="category_sec">Corporate News</span>
                  <div className="post_titleSec">
                    <h3 className="post_title">
                      The world’s most powerful woman, Oprah Winfre.
                    </h3>
                  </div>
                  <div className="meta_flex">
                    <span>
                      <i class="far fa-calendar-alt"></i> 09 Nov. 2023
                    </span>{" "}
                    |{" "}
                    <span>
                      <i class="far fa-eye"></i> 167
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <section className="ad_sec">
        <div className="container">
          <div className="ad_mainSec">
            <img
              src="https://new.axilthemes.com/demo/react/blogar/images/add-banner/banner-03.webp"
              alt=""
            />
          </div>
        </div>
      </section>
      <section className="register_sec">
        <div className="container">
          <div className="register_flex">
            <div className="register_inner">
              <h5>
                INFLUENCER ?<br />
                FAMOSO
              </h5>
              <img
                src="https://news.fanzat.com/wp-content/themes/nnsm3-news/images/img-teams.png"
                alt=""
              />
              <p>
                Inicia tu viaje con Fanzat y alcanza los niveles más altos de
                alcance
              </p>
              <a href="https://nnsm3.com/register/star">
                <button className="btn-fix-bottom">
                  Regístrate como Estrella
                </button>
              </a>
            </div>
            <div className="register_inner">
              <h5>TENER UNA INFLUENCIA REAL Y ÚNICA</h5>
              <img
                src="https://news.fanzat.com/wp-content/themes/nnsm3-news/images/info-2.png"
                alt=""
              />
              <p>
                Use your unique influence in your field to achieve amazing
                results with NNSM3
              </p>
              <a href="https://nnsm3.com/register/pulsar">
                <button className="btn-fix-bottom">
                  Regístrate como Pulsar
                </button>
              </a>
            </div>
            <div className="register_inner">
              <h5>
                MARCA ¿O
                <br />
                EMPRESA?
              </h5>
              <img
                src="https://news.fanzat.com/wp-content/themes/nnsm3-news/images/info-3.png"
                alt=""
              />
              <p>
                Choose your influencer & track your successful campaign on one
                platform with NNSM3
              </p>
              <a href="https://nnsm3.com/register/orbit">
                <button className="btn-fix-bottom">
                  Regístrate como Órbita
                </button>
              </a>
            </div>

            <div className="register_inner">
              <h5>
                ¿ERES UN <br /> FAN?
              </h5>
              <img src={Group} alt="" />
              <p>Lore ipsum is the dummy text standard industry</p>
              <a href="">
                <button className="btn-fix-bottom">Regístrate como fan</button>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="tab_sliderSec">
        <div className="container">
          <div className="tab_slideMain">
            <h2>Últimas noticias</h2>
            <div className="button_flex">
              {masterCategory && masterCategory.length > 0 ? (
                masterCategory.map((item) => {
                  return (
                    <>
                      {/* <button
                        style={{ background: "#fff" }}
                        onClick={() => {
                          // hndleFun(item._id);
                          getRelatedmasterCats(item.id);
                        }}
                      >
                        <p>{item._id}</p>
                        {item?.name?.espanol}
                      </button> */}
                      <button
                        key={item.id}
                        onClick={() => handleButtonClick(item.id)}
                        className={
                          activeButton === item.id ? "activeButton" : ""
                        }
                      >
                        {item?.name?.espanol}
                      </button>
                    </>
                  );
                })
              ) : (
                <p>No se encontró ninguna categoría</p>
              )}
            </div>

            <div className="slide_flex">
              {/* <Slider {...settings}> */}
              {Rmaster && Rmaster.length > 0 ? (
                Rmaster.filter((item) => item.status === "publish")
                  .slice(0, 3)
                  .map((item, index) => {
                    const postDate = new Date(item.date);
                    const formattedDate = `${postDate.toLocaleDateString()} ${postDate.toLocaleTimeString()}`;
                    return (
                      <>
                        <div className="slide_item">
                          <Link to={"/es/single-blog-post/" + item._id}>
                            {/* <p> {item?.category[0]?.name?.espanol}</p> */}
                            <p> {item?.category?.name?.espanol}</p>

                            <h4>{item?.title?.espanol}</h4>
                            <img
                              src={
                                "https://fanzat-news.onrender.com" +
                                item?.featured_media?.source_url
                              }
                              className="product-img"
                              alt="image"
                            />
                          </Link>
                        </div>
                      </>
                    );
                  })
              ) : (
                <p>No se encontró ninguna categoría</p>
              )}
              {/* </Slider> */}

              {/* <div className="slide_item">
                <p>Corporate News</p>
                <h4>Air Pods Pro with Wireless Charging Case.</h4>
                <img
                  src="https://new.axilthemes.com/demo/react/blogar/images/posts/post-grid-01.webp"
                  alt=""
                  style={{ height: "250px", objectFit: "cover" }}
                />
                <img
                  src="https://new.axilthemes.com/demo/react/blogar/images/icons/shape-01.webp"
                  alt=""
                  className="slide_layer"
                />
              </div>
              <div className="slide_item">
                <p>Corporate News</p>
                <h4>Air Pods Pro with Wireless Charging Case.</h4>
                <img
                  src="https://new.axilthemes.com/demo/react/blogar/images/posts/thumbnail-06.webp"
                  alt=""
                  style={{ height: "250px", objectFit: "cover" }}
                />
                <img
                  src="https://new.axilthemes.com/demo/react/blogar/images/icons/shape-01.webp"
                  alt=""
                  className="slide_layer"
                />
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <section
        className="tab_sliderSec"
        style={{
          padding: "50px 0px",
          background: "#F0F2F5",
          marginBottom: "0px",
        }}
      >
        <div className="container">
          <div className="tab_slideMain">
            <h2>Más popular</h2>
            {/* <div className="button_flex">
              <button style={{ background: "#fff" }}>Influencers</button>
              <button style={{ background: "#fff" }}>Marcas</button>
              <button style={{ background: "#fff" }}>Fanzat</button>
            </div> */}
            <div className="middle_mainSec">
              {mpNews && mpNews.length > 0 ? (
                mpNews
                  .filter((item) => item.status === "publish")
                  .slice(0, 1)
                  .map((item, index) => {
                    const postDate = new Date(item.date);
                    const formattedDate = `${postDate.toLocaleDateString()} ${postDate.toLocaleTimeString()}`;
                    return (
                      <>
                        <div className="middle_mainSecL">
                          <div className="top_mainP">
                            <Link to={"/es/single-blog-post/" + item._id}>
                              <div className="top_mainThumb">
                                <img
                                  src={
                                    "https://fanzat-news.onrender.com" +
                                    item?.featured_media?.source_url
                                  }
                                  className="product-img"
                                  alt="image"
                                />
                              </div>
                              <div className="top_mainPD">
                                <span className="top_mainPDCat">
                                  {" "}
                                  {item?.category?.name?.espanol}
                                </span>
                                <div className="post_titleSec">
                                  <h3 className="post_title">
                                    {item?.title?.espanol}
                                  </h3>
                                </div>
                                {/* <div className="main_postMeta"> */}
                                <div className="main_postMetaR">
                                  <SharePost
                                    title={item?.title?.english}
                                    url={`https://fanzat-news-arabic.vercel.app/single-blog-post/${item._id}`}
                                  />
                                </div>
                                {/* <div className="main_postMetaL">
                                  <div>
                                    <img
                                      src={
                                        "https://fanzat-news.onrender.com/" +
                                        item?.user_ID?.images[0]?.path
                                      }
                                      // src={data?.images?.path ? data?.image?.path : "../assets/img/profile-img.png"}
                                      className="product-img"
                                      alt="image"
                                    />
                                  </div>
                                  <div>
                                    <h6>{item?.user_ID?.user_nicename}</h6>

                                    <span>Jun 25 2022 </span>
                                  </div>
                                </div> */}
                                {/* </div> */}
                              </div>
                            </Link>
                          </div>
                        </div>
                      </>
                    );
                  })
              ) : (
                <p>No se encontraron noticias</p>
              )}

              <div className="middle_mainSecR">
                {mpNews1 && mpNews1.length > 0 ? (
                  mpNews1
                    .filter((item) => item.status === "publish")
                    .slice(0, 2)
                    .map((item, index) => {
                      const postDate = new Date(item.date);
                      const formattedDate = `${postDate.toLocaleDateString()} ${postDate.toLocaleTimeString()}`;
                      return (
                        <>
                          <div className="top_mainP">
                            <Link to={"/es/single-blog-post/" + item._id}>
                              <div className="top_mainThumb">
                                <img
                                  src={
                                    "https://fanzat-news.onrender.com" +
                                    item?.featured_media?.source_url
                                  }
                                  className="product-img"
                                  alt="image"
                                />
                              </div>
                              <div className="top_mainPD">
                                <span className="top_mainPDCat">
                                  {" "}
                                  {/* {item?.category[0]?.name?.espanol} */}
                                  {item?.category?.name?.espanol}
                                </span>
                                <div className="post_titleSec">
                                  <h3 className="post_title">
                                    {item?.title?.espanol}
                                  </h3>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </>
                      );
                    })
                ) : (
                  <p>No se encontraron noticias</p>
                )}
              </div>
            </div>
            {/* <div className="banner_flex" style={{ marginTop: "40px" }}>
              <div className="bannerM">
                <div className="slide_inner top_slideInner">
                  <img
                    src="https://new.axilthemes.com/demo/react/blogar/images/posts/thumbnail-05.webp"
                    alt=""
                  />
                  <div className="slide_descSec">
                    <span className="category_sec">Corporate News</span>
                    <div className="post_titleSec">
                      <h3 className="post_title">
                        The world’s most powerful woman, Oprah Winfre.
                      </h3>
                    </div>
                    <div className="meta_flex">
                      <span>
                        <i class="far fa-calendar-alt"></i> 09 Nov. 2023
                      </span>{" "}
                      |{" "}
                      <span>
                        <i class="far fa-eye"></i> 167
                      </span>
                    </div>
                  </div>
                </div>
                <div className="slide_inner top_slideInner">
                  <img
                    src="https://new.axilthemes.com/demo/react/blogar/images/posts/post-tech-10.webp"
                    alt=""
                  />
                  <div className="slide_descSec">
                    <span className="category_sec">Corporate News</span>
                    <div className="post_titleSec">
                      <h3 className="post_title">
                        The world’s most powerful woman, Oprah Winfre.
                      </h3>
                    </div>
                    <div className="meta_flex">
                      <span>
                        <i class="far fa-calendar-alt"></i> 09 Nov. 2023
                      </span>{" "}
                      |{" "}
                      <span>
                        <i class="far fa-eye"></i> 167
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bannerL">
                <div className="slide_inner">
                  <img
                    src="https://new.axilthemes.com/demo/react/blogar/images/posts/thumbnail-08.webp"
                    alt=""
                  />
                  <div className="slide_descSec">
                    <span className="category_sec">Celebrity News</span>
                    <div className="post_titleSec">
                      <h3 className="post_title">
                        The world’s most powerful woman, Oprah Winfrey, has been
                        named the highest-paid celebrity in the world.
                      </h3>
                    </div>
                    <div className="meta_flex">
                      <span>
                        <i class="far fa-calendar-alt"></i> 09 Nov. 2023
                      </span>{" "}
                      |{" "}
                      <span>
                        <i class="far fa-eye"></i> 167
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      <section className="video_postSec">
        <div className="container">
          <div className="video-sec-top-bar">
            <div className="video-sec-heading">
              <h2>Video destacado</h2>
            </div>
            <div className="video-sec-btn">
              <Link to={"/es/all-video"}>
                <button>Ver más</button>
              </Link>
            </div>
          </div>
          <div className="video_postFlex">
            <div className="video_postR">
              <div className="video_sMain">
                <div className="video_thumb">
                  <video className="video-1" controls>
                    <source src={video1} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  {/* <div className="video_over">
                    <i class="fas fa-play"></i>
                  </div> */}
                </div>
                <span className="video_cat">SEO</span>
                <div className="post_titleSec">
                  <h3 className="post_title">
                    The world’s most powerful woman, Oprah Winfrey.
                  </h3>
                </div>
                {/* <div className="main_postMeta">
                  <div className="main_postMetaR">
                    <i class="fab fa-facebook-f"></i>
                    <i class="fab fa-twitter"></i>
                    <i class="fab fa-instagram"></i>
                    <i class="fas fa-link"></i>
                  </div>
                  <div className="main_postMetaL">
                    <h6>Sara Flower</h6>
                    <span>Jun 25 2022 . 5 min read</span>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="video_postL">
              <div className="video_sFlex">
                <div className="video_sMain">
                  <div className="video_thumb">
                    <video className="video2" controls>
                      <source src={video2} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                    {/* <div className="video_over">
                      <i class="fas fa-play"></i>
                    </div> */}
                  </div>
                  <span className="video_cat">SEO</span>
                  <div className="post_titleSec">
                    <h3 className="post_title">
                      The world’s most powerful woman, Oprah Winfrey.
                    </h3>
                  </div>
                </div>
                <div className="video_sMain">
                  <div className="video_thumb">
                    <video className="video2" controls>
                      <source src={video3} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                    {/* <div className="video_over">
                      <i class="fas fa-play"></i>
                    </div> */}
                  </div>
                  <span className="video_cat">SEO</span>
                  <div className="post_titleSec">
                    <h3 className="post_title">
                      The world’s most powerful woman, Oprah Winfrey.
                    </h3>
                  </div>
                </div>
                <div className="video_sMain">
                  <div className="video_thumb">
                    <video className="video2" controls>
                      <source src={video4} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                    {/* <div className="video_over">
                      <i class="fas fa-play"></i>
                    </div> */}
                  </div>
                  <span className="video_cat">SEO</span>
                  <div className="post_titleSec">
                    <h3 className="post_title">
                      The world’s most powerful woman, Oprah Winfrey.
                    </h3>
                  </div>
                </div>
                <div className="video_sMain">
                  <div className="video_thumb">
                    <video className="video2" controls>
                      <source src={video5} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                    {/* <div className="video_over">
                      <i class="fas fa-play"></i>
                    </div> */}
                  </div>
                  <span className="video_cat">SEO</span>
                  <div className="post_titleSec">
                    <h3 className="post_title">
                      The world’s most powerful woman, Oprah Winfrey.
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="trending_topicsSec">
        <div className="container">
          {/* <div className="view_trending">
            <Link to="#">Ver todos los temass</Link>
          </div> */}
          <div className="trending_topicsInner">
            <div className="trending-top-section">
              <div className="trending-top-heading">
                <h2>Temas de tendencia</h2>
              </div>

              {allTag && allTag.length > 0 && (
                <div className="trending-top-btn">
                  <Link to={"/es/most-used-tag"}>
                    <button>Ver más</button>
                  </Link>
                </div>
              )}
            </div>
            <Slider {...settings}>
              {allTag && allTag.length > 0 ? (
                allTag.slice(0, 20).map((item) => {
                  return (
                    <>
                      <div className="trending_sec">
                        <div className="trending_over"></div>
                        {/* <img
                            src={
                              "https://fanzat-news.onrender.com" +
                              item?.image?.url
                            }
                            alt="image"
                          /> */}
                        <img src={background} />
                        <div className="trending_title">
                          <h3>{item?.tags?.espanol}</h3>
                        </div>
                      </div>
                    </>
                  );
                })
              ) : (
                <p>No se encontraron etiquetas</p>
              )}
            </Slider>
          </div>
        </div>
      </section>
      <section className="ad_withTags">
        <div className="container">
          <div className="ad_withTagsSec">
            <div className="ad_withR">
              <img
                src="https://new.axilthemes.com/demo/react/blogar-rtl/images/add-banner/banner-01.webp"
                alt=""
              />
            </div>
            <div className="ad_withL">
              <div className="ad_spans">
                {/* {starTag.} */}

                <h4>{message}</h4>

                {starTag && starTag.length > 0
                  ? starTag.slice(0, 6).map((item, i) => (
                      <div className="adspan_flex">
                        <img
                          src={
                            "https://fanzat-news.onrender.com" +
                            item?.image?.url
                          }
                          // src={data?.images?.path ? data?.image?.path : "../assets/img/profile-img.png"}
                          className="product-img"
                          alt="image"
                        />
                        <h4>{item?.starTags?.espanol}</h4>
                      </div>
                    ))
                  : null}
              </div>

              {starTag && starTag.length > 0 && (
                <div className="ad_withL-startag-btn">
                  <Link to={"/es/star-tags"}>
                    <button className="trending-top-btnF">Ver más</button>
                  </Link>
                </div>
              )}
              {/* <h5
                style={{
                  textAlign: "center",
                  fontWeight: "600",
                  color: "#000",
                  marginTop: "15px",
                }}
              >
                Search
              </h5> */}
              <hr />
              {/* <div class="search_bar" style={{ marginTop: "15px" }}>
                <input type="search" placeholder="Search..." />
              </div> */}
            </div>
          </div>
        </div>
      </section>
      <section className="posts_wSidebar">
        <div className="container">
          <div className="posts_wFlex">
            <div className="posts_wFlexL">
              {news && news.length > 0 ? (
                news
                  .filter((item) => item.status === "publish")
                  .slice(0, 5)
                  .map((item, index) => {
                    const postDate = new Date(item.date);
                    const formattedDate = `${postDate.toLocaleDateString()} ${postDate.toLocaleTimeString()}`;
                    return (
                      <>
                        <Link to={"/es/single-blog-post/" + item._id}>
                          <div className="main_postSec">
                            <div className="main_postFlex">
                              <div className="main_postContent">
                                <a class="hover-flip-item-wrapper" href="/">
                                  <span class="hover-flip-item">
                                    {/* <span data-text="SEO"> {item?.category[0]?.name?.espanol}</span> */}
                                    {/* {item?.category[0]?.name?.espanol} */}
                                    {item?.categories
                                      .filter(
                                        (category) => category.type === "1"
                                      )
                                      .map((category) => (
                                        <h6>{category.name?.english}</h6>
                                        // <h6 key={category._id}>{category.name?.arabic}</h6>
                                      ))}
                                  </span>
                                </a>
                                <div className="post_titleSec">
                                  <h3 className="post_title">
                                    {item?.title?.espanol
                                      .split(" ")
                                      .slice(0, 12)
                                      .join(" ")}
                                  </h3>
                                </div>
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: item.content?.espanol
                                      .split(" ")
                                      .slice(0, 12)
                                      .join(" "),
                                  }}
                                ></p>
                                <div className="main_postMeta">
                                  <div className="main_postMetaR">
                                    <SharePost
                                      title={item?.title?.english}
                                      url={`https://fanzat-news-arabic.vercel.app/single-blog-post/${item._id}`}
                                    />
                                  </div>
                                  {/* <div className="main_postMetaL">
                                    <h6>{item?.user_ID?.user_nicename}</h6> */}
                                  <span>{formattedDate} </span>
                                  {/* </div> */}
                                </div>
                              </div>
                              <div className="main_postThumb">
                                {/* <img
                      src="https://new.axilthemes.com/demo/react/blogar/images/posts/thumbnail-08.webp"
                      alt=""
                    /> */}
                                <img
                                  src={
                                    "https://fanzat-news.onrender.com" +
                                    item?.featured_media?.source_url
                                  }
                                  className="product-img"
                                  alt="image"
                                />
                              </div>
                            </div>
                          </div>
                        </Link>
                      </>
                    );
                  })
              ) : (
                <p>No se encontraron noticias</p>
              )}
              <div className="view-all-post-btn">
                <Link to={"/es/posts"}>
                  <button className="all-post-btn">Ver más</button>
                </Link>
              </div>
            </div>
            <div className="posts_wFlexR">
              <div className="popular_onSite">
                <h5>Popular en Blogar</h5>
                <hr />
                <div className="popular_innerSec">
                  {Anews && Anews.length > 0 ? (
                    Anews.filter((item) => item.status === "publish")
                      .slice(0, 4)
                      .map((item, index) => {
                        const postDate = new Date(item.date);
                        const formattedDate = `${postDate.toLocaleDateString()} ${postDate.toLocaleTimeString()}`;
                        return (
                          <>
                            <Link to={"/es/single-blog-post/" + item._id}>
                              <div className="pop_postFlex">
                                <div className="pop_postFlexL">
                                  <img
                                    src={
                                      "https://fanzat-news.onrender.com" +
                                      item?.featured_media?.source_url
                                    }
                                    className="product-img"
                                    alt="image"
                                  />
                                </div>
                                <div className="pop_postFlexR">
                                  <div className="post_titleSec">
                                    <h3 className="post_title">
                                      {item?.title?.espanol}
                                    </h3>
                                  </div>
                                  <span>{formattedDate}</span>
                                </div>
                              </div>
                            </Link>
                          </>
                        );
                      })
                  ) : (
                    <p>No se encontraron noticias</p>
                  )}
                  {/* <div className="pop_postFlex">
                    <div className="pop_postFlexL">
                      <img
                        src="https://new.axilthemes.com/demo/react/blogar/images/posts/post-column-02.webp"
                        alt=""
                      />
                    </div>
                    <div className="pop_postFlexR">
                      <div className="post_titleSec">
                        <h3 className="post_title">
                          The world’s most powerful woman.
                        </h3>
                      </div>
                      <span>Jun 30 2022 . 200K Views</span>
                    </div>
                  </div>
                  <div className="pop_postFlex">
                    <div className="pop_postFlexL">
                      <img
                        src="https://new.axilthemes.com/demo/react/blogar/images/posts/thumbnail-08.webp"
                        alt=""
                      />
                    </div>
                    <div className="pop_postFlexR">
                      <div className="post_titleSec">
                        <h3 className="post_title">
                          The world’s most powerful woman.
                        </h3>
                      </div>
                      <span>Jun 30 2022 . 200K Views</span>
                    </div>
                  </div>
                  <div className="pop_postFlex">
                    <div className="pop_postFlexL">
                      <img
                        src="https://new.axilthemes.com/demo/react/blogar/images/posts/post-seo-sm-04.webp"
                        alt=""
                      />
                    </div>
                    <div className="pop_postFlexR">
                      <div className="post_titleSec">
                        <h3 className="post_title">
                          The world’s most powerful woman.
                        </h3>
                      </div>
                      <span>Jun 30 2022 . 200K Views</span>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className="social_links">
                <h5>Mantente en contacto</h5>
                <hr />
                <i class="fab fa-linkedin-in"></i>
                <i class="fab fa-slack"></i>
                <i class="fab fa-twitter"></i>
                <i class="fab fa-instagram"></i>
                <i class="fab fa-facebook-f"></i>
              </div>
              {/* <div className="instagram_feed">
                <h5>Instagram</h5>
                <hr />
                <div className="instagram_flex">
                  <div className="instagram_inner">
                    <img
                      src="https://new.axilthemes.com/demo/react/blogar/images/small-images/instagram-md-03.webp"
                      alt=""
                    />
                  </div>
                  <div className="instagram_inner">
                    <img
                      src="https://new.axilthemes.com/demo/react/blogar/images/small-images/instagram-md-02.webp"
                      alt=""
                    />
                  </div>
                  <div className="instagram_inner">
                    <img
                      src="https://new.axilthemes.com/demo/react/blogar/images/small-images/instagram-md-01.webp"
                      alt=""
                    />
                  </div>

                  <div className="instagram_inner">
                    <img
                      src="https://new.axilthemes.com/demo/react/blogar/images/small-images/instagram-md-06.webp"
                      alt=""
                    />
                  </div>
                  <div className="instagram_inner">
                    <img
                      src="https://new.axilthemes.com/demo/react/blogar/images/small-images/instagram-md-05.webp"
                      alt=""
                    />
                  </div>

                  <div className="instagram_inner">
                    <img
                      src="https://new.axilthemes.com/demo/react/blogar/images/small-images/instagram-md-04.webp"
                      alt=""
                    />
                  </div>
                </div>
              </div> */}
              <div className="fanzat_stars">
                <h5>Estrellas Fanzat</h5>
                <hr />
                <iframe src="https://nnsm3.com/" title="fanzat stars"></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section className="bottom_instagram">
        <div className="container">
          <div className="social_bottomBar">
            <div className="social_inner">
              <p>
                Discord<i class="fab fa-discord"></i>
              </p>
            </div>
            <div className="social_inner">
              <p>
                Pinterest <i class="fab fa-pinterest"></i>
              </p>
            </div>
            <div className="social_inner">
              <p>
                Youtube<i class="fab fa-youtube"></i>
              </p>
            </div>
            <div className="social_inner">
              <p>
                Instagram<i class="fab fa-instagram"></i>
              </p>
            </div>
            <div className="social_inner">
              <p>
                Facebook<i class="fab fa-facebook-f"></i>
              </p>
            </div>
            <div className="social_inner">
              <p>
                Twitter<i class="fab fa-twitter"></i>
              </p>
            </div>
          </div>
          <div className="bottom_instaBar">
            <h2>Instagram</h2>
            <div className="bottom_instaFlex">
              <div className="bottom_instaInner">
                <img
                  src="https://new.axilthemes.com/demo/react/blogar/images/small-images/instagram-md-06.webp"
                  alt=""
                />
                <div className="insta_over">
                  <i class="fab fa-instagram"></i>
                </div>
              </div>
              <div className="bottom_instaInner">
                <img
                  src="https://new.axilthemes.com/demo/react/blogar/images/small-images/instagram-md-05.webp"
                  alt=""
                />
                <div className="insta_over">
                  <i class="fab fa-instagram"></i>
                </div>
              </div>
              <div className="bottom_instaInner">
                <img
                  src="https://new.axilthemes.com/demo/react/blogar/images/small-images/instagram-md-04.webp"
                  alt=""
                />
                <div className="insta_over">
                  <i class="fab fa-instagram"></i>
                </div>
              </div>
              <div className="bottom_instaInner">
                <img
                  src="https://new.axilthemes.com/demo/react/blogar/images/small-images/instagram-md-03.webp"
                  alt=""
                />
                <div className="insta_over">
                  <i class="fab fa-instagram"></i>
                </div>
              </div>
              <div className="bottom_instaInner">
                <img
                  src="https://new.axilthemes.com/demo/react/blogar/images/small-images/instagram-md-02.webp"
                  alt=""
                />
                <div className="insta_over">
                  <i class="fab fa-instagram"></i>
                </div>
              </div>
              <div className="bottom_instaInner">
                <img
                  src="https://new.axilthemes.com/demo/react/blogar/images/small-images/instagram-md-01.webp"
                  alt=""
                />
                <div className="insta_over">
                  <i class="fab fa-instagram"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <NewsLetterEs />
      <FooterEs />
    </>
  );
};

export default HomeEs;
